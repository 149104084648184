import request from '@/utils/request'

export default {
  submitOrder (hotelId, data) {
    return request({
      url: `/hotels/${hotelId}/orders`,
      method: 'post',
      data: data
    })
  },
  couponPreview (hotelId, data) {
    return request({
      url: `/hotels/${hotelId}/coupon/preview`,
      method: 'post',
      data: data
    })
  },
  getCompletedOrder (hotelId, orderSerial) {
    return request({
      url: `/hotels/${hotelId}/orders/${orderSerial}`,
      method: 'get'
    })
  },
  checkQRcodePaymentStatus (id) {
    return request({
      url: `https://api.owlting.com/payment/intella/order/${id}/check`,
      method: 'get'
    })
  },
  cancelOrder ({ hotelId, orderSerial, cancelToken, bankCode, bankSubcode, bankAccount, bankName }) {
    return request({
      url: `/hotels/${hotelId}/${orderSerial}/cancel/submit?hash=${cancelToken}`,
      method: 'post',
      data: {
        bank_code: bankCode,
        bank_subcode: bankSubcode,
        bank_account: bankAccount,
        bank_name: bankName
      }
    })
  },
  fetchBankList () {
    return request({
      url: 'bankcode/list/query',
      method: 'get'
    })
  },
  fetchSubBankList (bankCode) {
    return request({
      url: `bankcode/${bankCode}/list/query`,
      method: 'get'
    })
  },
  updateOrderCheckAccount ({ hotelId, orderSerial, atmPaidAccount, atmPaidDate, atmPaidTotal, captcha, key }) {
    return request({
      url: `hotels/${hotelId}/orders/${orderSerial}/atm/check_account`,
      method: 'put',
      data: {
        atm_paid_account: atmPaidAccount,
        atm_paid_date: atmPaidDate,
        atm_paid_total: atmPaidTotal,
        captcha: captcha,
        key: key
      }
    })
  },
  getCaptcha () {
    return request({
      url: 'captcha/api/default',
      method: 'get'
    })
  }
}
