import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  // timeout: 50000,
  crossdomain: true
})

// request interceptors
service.interceptors.request.use(config => {
  // if (localStorage.owlHotel && JSON.parse(localStorage.owlHotel).user.accessToken.length > 0) {
  //   config.headers['Authorization'] = 'Bearer ' + JSON.parse(localStorage.owlHotel).user.accessToken
  // }
  config.params = {
    lang: store.state.selectedLanguage,
    ...config.params
  }
  return config
}, error => {
  Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(
  response => {
    // if (response.headers['content-type'].indexOf('application/xml') > -1) {
    //   return response.data
    // }
    // if (!response.data.code) {
    //   return response.data
    // }
    // if (response.data.code !== 200) {
    //   store.commit('NEW_ERROR', {
    //     code: response.data.code,
    //     response: response
    //   })
    //   console.error(`🚨 [API] ${response.config.url.replace(response.config.baseURL, '')}`, response.data)
    // } else {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.debug(`👌 [API] ${response.config.url.replace(response.config.baseURL, '')}`, response.data)
    //   }
    // }
    return response
  },
  error => {
    // store.commit('NEW_ERROR', error)
    console.error('🚨 [API ERR] ' + error)
    return Promise.reject(error)
  })

export default service
