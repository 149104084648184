var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toggle" }, [
    _c("input", { staticClass: "toggle__input", attrs: { type: "checkbox" } }),
    _vm.inactiveLabel
      ? _c("span", { staticClass: "text-sm mr-4" }, [
          _c("p", [_vm._v(_vm._s(_vm.inactiveLabel))])
        ])
      : _vm._e(),
    _c("span", {
      staticClass: "toggle__core",
      class: { "toggle__core--checked": _vm.isChecked },
      on: { click: _vm.toggle }
    }),
    _vm.activeLabel
      ? _c("span", { staticClass: "text-sm ml-4" }, [
          _c("p", [_vm._v(_vm._s(_vm.activeLabel))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }