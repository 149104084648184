<template>
  <main class="hotel">
    <hotelSubNav
      v-if="!isLoadingHotel"
      ref="hotelSubNav"
    />
    <section class="hotel-rooms container flex">
      <div class="hotel__body__rooms flex__main-panel lg:pr-12">
        <hotelContent @showCustomerSelector="handleShowCustomerSelector"></hotelContent>
      </div>
      <div class="hotel__body__total flex__aside-panel lg:pl-12">
        <orderTotalAside></orderTotalAside>
      </div>
    </section>
  </main>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import hotelSubNav from '@/views/Hotel/components/hotelSubNav'
import orderTotalAside from '@/components/orderTotalAside'
import hotelContent from '@/views/Hotel/components/hotelContent'
import SmoothScroll from 'smooth-scroll'

export default {
  name: 'Hotel',
  metaInfo () {
    return {
      title: this.$t('page.bookRoom'),
      meta: [
        {
          'property': 'og:title',
          'content': this.$t('page.bookRoom'),
          'vmid': 'og:title'
        },
        {
          'property': 'og:site_name',
          'content': this.$t('page.bookRoom'),
          'vmid': 'og:site_name'
        }
      ]
    }
  },
  components: {
    hotelSubNav,
    orderTotalAside,
    hotelContent
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'windowWidth'
    ]),
    isLoadingHotel () {
      return this.$store.state.hotel.isLoadingHotel
    }
  },
  methods: {
    ...mapMutations([
      'SET_STEP',
      'SET_IS_FETCHED_ADDON'
    ]),
    handleShowCustomerSelector () {
      if (this.windowWidth < 768) {
        this.scrollToTop()
        setTimeout(() => {
          this.$refs.hotelSubNav.handleCustomerSelectorOpen()
        }, 1000)
      } else {
        this.$refs.hotelSubNav.handleCustomerSelectorOpen()
      }
    },
    scrollToTop () {
      const scroll = new SmoothScroll()
      const nav = document.querySelector('nav')
      scroll.animateScroll(nav)
    }
  },
  created () {
    // this['SET_STEP'](1)
  },
  activated () {
    this['SET_STEP'](1)
    this['SET_IS_FETCHED_ADDON'](false) // 進入第一頁重置get addon flag
  }
}
</script>

<style lang="scss">
@import '@/stylesheets/bs_style.scss';
</style>

<style lang="scss" scoped>
.hotel-rooms.container {
  position: relative;

  &.show {
    z-index: 11;

    .hotel__body__total {
      z-index: 1;
    }
  }

  .hotel__body__rooms {
    z-index: 2;
  }

  .hotel__body__total {
    z-index: 2;
  }
}
</style>
