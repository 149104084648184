var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extra-rectption-form" }, [
    _c("div", { staticClass: "w-1/4 mr-auto flex items-center" }, [
      _c("img", {
        staticClass: "mr-4",
        attrs: {
          src: "https://static.owlting.com/booking/icon/on_feature_100.svg"
        }
      }),
      _c("p", [_vm._v(_vm._s("" + _vm.$t("extraReception.room") + _vm.index))])
    ]),
    _c(
      "div",
      { staticClass: "sm:w-1/3 sm:ml-12 mt-16" },
      [
        _c("label", { staticClass: "pl-4" }, [
          _vm._v(_vm._s(_vm.$t("extraReception.adult")))
        ]),
        _c("multiSelect", {
          staticClass: "mt-8 reception-select",
          attrs: {
            value: _vm.mapAdultsOption,
            "track-by": "value",
            options: _vm.adultsOption,
            "show-labels": false,
            "close-on-select": true,
            "allow-empty": false,
            placeholder: _vm.$t("extraReception.selectorPlaceholder")
          },
          on: { select: _vm.handleSelectAdults },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(ref) {
                var option = ref.option
                return [_vm._v("\n        " + _vm._s(option.name) + "\n      ")]
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "sm:w-1/3 sm:ml-12 mt-16" },
      [
        _c("label", { staticClass: "pl-4" }, [
          _vm._v(_vm._s(_vm.$t("extraReception.child")))
        ]),
        _c("multiSelect", {
          staticClass: "mt-8 reception-select",
          attrs: {
            value: _vm.mapChildrenOption,
            "track-by": "value",
            options: _vm.childrenOption,
            "show-labels": false,
            "close-on-select": true,
            "allow-empty": false,
            placeholder: _vm.$t("extraReception.selectorPlaceholder")
          },
          on: { select: _vm.handleSelectChildren },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(ref) {
                var option = ref.option
                return [_vm._v("\n        " + _vm._s(option.name) + "\n      ")]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }