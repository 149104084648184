import store from '@/store'
import _ from 'lodash'
import numeral from 'numeral'

export const convert = function (price, options = {}) {
  const defaultConfig = {
    inputCurrency: store.getters['hotel/hotel'].currency,
    isBonus: false,
    outputCurrency: store.getters.selectedCurrency
  }
  const config = {
    ...defaultConfig,
    ...options
  }
  const { inputCurrency, isBonus, outputCurrency } = config
  let conversionBonus = 0
  if (isBonus) {
    conversionBonus = Number(process.env.VUE_APP_CURRENCY_CONVERSION_BONUS)
  }

  const conversionTable = store.getters.currencyExist

  if (!_.isEmpty(conversionTable) && inputCurrency) {
    const selected = _.find(conversionTable, currency => {
      return currency.code === inputCurrency
    })
    const target = _.find(conversionTable, currency => {
      return currency.code === outputCurrency
    })

    const selectedRate = selected.rate
    const targetRate = target.buy_rate
    //! conversionTable 的 isInteger 不準所以不參考
    const integerCurrency = ['TWD', 'JPY']
    const isInteger = integerCurrency.includes(target.code)

    if (inputCurrency !== outputCurrency) {
      const result = price * selectedRate / targetRate * (1 + conversionBonus)
      if (isInteger) {
        return numeral(Math.round(result)).format('0,0')
      } else {
        return numeral(result).format('0,0[.]00')
      }
    } else {
      if (isInteger) {
        return numeral(Math.round(price)).format('0,0')
      } else {
        return numeral((price * 100) / 100).format('0,0[.]00')
      }
      // return numeral((price * 100) / 100).format('0,0[.]00')
    }
  } else {
    return numeral(price).format('0,0[.]00')
  }
}
