<template>
  <div class="text-right text-orange-dark text-sm">
    <p v-if="hasCtaRestricted || hasCtdRestricted" class="mt-4">
      {{ $t('planNotApplicable', { item: getCtaCtdDescription }) }}
    </p>
    <p v-if="hasMinLosRestricted" class="mt-4">
      {{ $t('needMinStayNumber', { minlos: getMinlos() }) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    restrictedReasons: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    hasMinLosRestricted: function () {
      return this.restrictedReasons.some(item => item.type === 'min_los')
    },
    hasCtaRestricted: function () {
      return this.restrictedReasons.some(item => item.type === 'cta')
    },
    hasCtdRestricted: function () {
      return this.restrictedReasons.some(item => item.type === 'ctd')
    },
    getCtaCtdDescription: function ({ hasCtaRestricted, hasCtdRestricted }) {
      if (hasCtaRestricted && hasCtdRestricted) {
        return this.$t('arrivalAndDeparture')
      }
      if (hasCtaRestricted) {
        return this.$t('arrival')
      }
      if (hasCtdRestricted) {
        return this.$t('departure')
      }
      return ''
    }
  },
  methods: {
    getMinlos: function () {
      return this.restrictedReasons.find(item => item.type === 'min_los')?.value
    }
  }
}
</script>
