<template>
  <div class='order-plan px-16 pt-24 pb-16 font-normal border-grey-lighter relative'>
    <div class="order-plan__row mb-8 items-center">
      <h5 class='text-grey-darkest font-medium'>
        <span v-if="plan.isBuilding" class="os-tag os-tag-outline os-tag-orange rounded-full text-sm">{{ $t('allBuilding') }}</span>
        {{ plan.roomName | contentParser }}
      </h5>
      <i class="owl-status-error remove-plan" @click="removePlan"></i>
    </div>
    <div class="order-plan__row">
      <label class='text-grey-dark'>{{ plan.planTitle | contentParser }}</label>
      <span>x {{ $t('orderUnit', { stock: plan.qty, unit: plan.unit }) }}</span>
    </div>
    <div class="order-plan__row">
      <label>{{ selectedCurrency }} {{ plan.avgPriceWithoutFee | currency }}</label>
      <span>x {{ this['search/nights'] }} {{ $tc('night', this['search/nights']) }}</span>
    </div>
    <div v-if="extraReceptionTotalCount > 0" class="order-plan__row">
      <label>{{ selectedCurrency }} {{ extraReceptionTotalPrice | currency }}</label>
      <span>{{ `+ ${extraReceptionTotalCount}${$t('person')}` }}</span>
    </div>
    <div class="order-plan__row">
      <label class='text-grey-dark'>{{ $t('total') }}</label>
      <span>
        {{ selectedCurrency }}
        <big class='text-blue font-bold'>{{ total | currency }}</big>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'order-plan',
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'selectedCurrency',
      'search/dateRange',
      'search/nights',
      'order/plansTotal',
      'order/extraReceptionPriceConfig'
    ]),
    total: function ({ extraReceptionTotalPrice }) {
      return this.plan.avgPriceWithoutFee * this.plan.qty * this['search/nights'] + extraReceptionTotalPrice
    },
    extraReceptionTotalAdults: function () {
      return this.plan?.extraReception.reduce((pv, cv) => pv + cv.adults, 0) || 0
    },
    extraReceptionTotalChildren: function () {
      return this.plan?.extraReception.reduce((pv, cv) => pv + cv.children, 0) || 0
    },
    extraReceptionTotalCount: function ({ extraReceptionTotalAdults, extraReceptionTotalChildren }) {
      return extraReceptionTotalAdults + extraReceptionTotalChildren
    },
    extraReceptionTotalPrice: function ({ extraReceptionTotalAdults, extraReceptionTotalChildren }) {
      return (extraReceptionTotalAdults * this['order/extraReceptionPriceConfig'].adults + extraReceptionTotalChildren * this['order/extraReceptionPriceConfig'].children) * this['search/nights']
    }
  },
  methods: {
    ...mapMutations([
      'order/REMOVE_BUILDING',
      'order/REMOVE_PLAN'
    ]),
    removePlan: function () {
      if (this.plan.isBuilding) {
        this['order/REMOVE_BUILDING'](this.plan.uid)
      } else {
        this['order/REMOVE_PLAN'](this.plan.uid)
      }
    }
  }
}
</script>

<style lang='sass' scoped>
.remove-plan
  @apply text-grey-light cursor-pointer
//   top: 8px
//   right: 8px

.order-plan
  &:not(:last-child)
    @apply border-b-1
  &__row
    @apply text-sm flex justify-between items-start mb-8
    label
      @apply mr-8 leading-tight
    span:not(.os-tag)
      @apply flex-no-shrink leading-tight text-grey-darkest

</style>
