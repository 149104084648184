<template>
  <div class="imagesSpliter" @mouseenter="isMask = true" @mouseleave="isMask = false">
    <div class="imagesSpliter__wrap">
      <div class="imagesSpliter__left imageClickable" @click="clickImage(0)">
        <div class="img" :class="{imgMask: isMask}" :style="`background-image: url('${photos['0']}')`"></div>
      </div>
      <div class="imagesSpliter__right">
        <div class="imagesSpliter__right__top imageClickable" @click="clickImage(1)">
          <div class="img" :class="{imgMask: isMask}" :style="`background-image: url('${photos['1']}')`"></div>
        </div>
        <div class="imagesSpliter__right__bottom">
          <div class="imagesSpliter__right__bottom__left imageClickable" @click="clickImage(2)">
            <div class="img" :class="{imgMask: isMask}" :style="`background-image: url('${photos['2']}')`"></div>
          </div>
          <div class="imagesSpliter__right__bottom__right imageClickable" @click="clickImage(3)">
            <div class="img" :class="{imgMask: isMask}" :style="`background-image: url('${photos['3']}')`"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'imagesSpliter',
  props: {
    photos: {
      type: Array,
      default: () => {
        return [
        ]
      }
    }
  },
  data () {
    return {
      isMask: false
    }
  },
  methods: {
    clickImage: function (index) {
      const info = {
        index: index,
        x: event.target.parentNode.offsetLeft,
        y: event.target.parentNode.offsetTop,
        w: event.target.clientWidth
      }
      this.$emit('clickImage', info)
    }
  }
}
</script>

<style lang="sass" scoped>
.imagesSpliter
  &__wrap
    @apply flex w-full bg-grey-lightest
  &__left
    @apply flex
    margin-right: 2px
    @apply w-1/2
    padding-top: 60vw
    @screen md
      @apply w-2/3
      padding-top: 30vw
  &__right
    @apply flex-auto flex flex-col
    margin-left: 2px
    &__top
      @apply w-full
      @apply flex
      margin-bottom: 2px
      height: 60%
    &__bottom
      @apply flex flex-row flex-1
      @apply w-full
      margin-top: 2px
      &__left
        @apply w-full
        @apply flex flex-1
        margin-right: 2px
      &__right
        @apply w-full
        @apply flex flex-1
        margin-left: 2px
.imageClickable
  @apply relative cursor-pointer overflow-hidden
  .img
    @apply absolute bg-cover bg-center
    transition: filter 0.5s ease, transform 0.3s ease-in
    top: 0
    left: 0
    width: 100%
    height: 100%
  &:hover
    .img
      transition: filter 0.5s ease, transform 0.3s ease-out
      transform: scale(1.05)
      filter: brightness(1)
  .imgMask
    @screen xl
      filter: brightness(.7)
</style>
