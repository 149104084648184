var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "imagesSpliter",
      on: {
        mouseenter: function($event) {
          _vm.isMask = true
        },
        mouseleave: function($event) {
          _vm.isMask = false
        }
      }
    },
    [
      _c("div", { staticClass: "imagesSpliter__wrap" }, [
        _c(
          "div",
          {
            staticClass: "imagesSpliter__left imageClickable",
            on: {
              click: function($event) {
                return _vm.clickImage(0)
              }
            }
          },
          [
            _c("div", {
              staticClass: "img",
              class: { imgMask: _vm.isMask },
              style: "background-image: url('" + _vm.photos["0"] + "')"
            })
          ]
        ),
        _c("div", { staticClass: "imagesSpliter__right" }, [
          _c(
            "div",
            {
              staticClass: "imagesSpliter__right__top imageClickable",
              on: {
                click: function($event) {
                  return _vm.clickImage(1)
                }
              }
            },
            [
              _c("div", {
                staticClass: "img",
                class: { imgMask: _vm.isMask },
                style: "background-image: url('" + _vm.photos["1"] + "')"
              })
            ]
          ),
          _c("div", { staticClass: "imagesSpliter__right__bottom" }, [
            _c(
              "div",
              {
                staticClass:
                  "imagesSpliter__right__bottom__left imageClickable",
                on: {
                  click: function($event) {
                    return _vm.clickImage(2)
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "img",
                  class: { imgMask: _vm.isMask },
                  style: "background-image: url('" + _vm.photos["2"] + "')"
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "imagesSpliter__right__bottom__right imageClickable",
                on: {
                  click: function($event) {
                    return _vm.clickImage(3)
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "img",
                  class: { imgMask: _vm.isMask },
                  style: "background-image: url('" + _vm.photos["3"] + "')"
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }