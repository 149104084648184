var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutsideHandler,
          expression: "clickOutsideHandler"
        }
      ],
      staticClass: "component-popper-menu"
    },
    [
      _c(
        "div",
        {
          staticClass: "label",
          class: { active: _vm.visibleValue },
          on: { click: _vm.clickHandler }
        },
        [_vm._t("label")],
        2
      ),
      _vm.visibleValue
        ? _c("div", { staticClass: "dropdown" }, [_vm._t("dropdown")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }