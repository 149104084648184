<template>
  <div class="components-room-date-selector">
    <popperMenu :visible.sync="visibleValue" :class="{ 'active': visibleValue }" :click-outside-close="!isMobile">
      <template slot="label">
        <template v-if="hasSlot('label')">
          <slot name="label"></slot>
        </template>
        <template v-else>
          <div class="date-range-selector">
            <div class="date-range-checkin" :class="{ 'active': visibleValue }">
              <div class="label"><i class="owl-format-left"></i> {{ $t('checkIn') }}</div>
              <div class="value">{{ dateRangeValueParseResult.start | shortDateFormat }}</div>
            </div>
            <div class="date-range-split">－</div>
            <div class="date-range-checkout" :class="{ 'active': visibleValue }">
              <div class="label"><i class="owl-format-right"></i> {{ $t('checkOut') }}</div>
              <div class="value">{{ dateRangeValueParseResult.end | shortDateFormat }}</div>
            </div>
            <template v-if="!classic">
              <div class="room-type-split"></div>
              <div class="room-type" :class="{ 'active': visibleValue }">
                <div class="label"><i class="owl-grid-three"></i> {{ $t('roomType') }}</div>
                <div class="value">
                  {{ selectedRooms }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template slot="dropdown">
        <div ref="dropdownMenu" class="dropdown-menu flex" @scroll="dropdownScrollHandler">
          <div class="dropdown-calender">
            <v-date-picker
              ref="datePicker"
              class="calender"
              v-model.lazy="dateRangeValue"
              mode="range"
              is-range
              :min-date="minDate||undefined"
              :max-date="maxDate||undefined"
              :min-page="minPageTemp||minPage"
              :max-page="maxPageTemp||undefined"
              :disabled-dates="disabledDatesTemp||disabledDates"
              @update:from-page="onDatePickerUpdate"
              @input="handleCustomerSelectorOpen"
              @drag="dayDrag">
            </v-date-picker>
            <div v-if="!classic" class="toolbar">
              <div>
                <b v-if="dateRangeText" v-html="dateRangeText"></b>
                <b v-else>{{ diffDays > 0 ? totalDays : $t('pleaseSelectDate') }}</b>
              </div>
              <div>
                <small>{{ calendarNote }}</small>
              </div>
              <div class="buttons row pt-2">
                <div class="close-button col">
                  <!-- <button class="btn btn-outline-grey w-80" @click="dateRoomPickerCloseHandler">
                    {{ $t('close') }}
                  </button> -->
                  <button
                    :disabled="!roomDatesSelectedClearVisible"
                    class="btn btn-link p-0 dropdown-room-clear"
                    @click="roomDatesSelectedClearHandler"
                  >
                    {{ $t('cleanAll') }}
                  </button>
                </div>
                <div class="submit-button col">
                  <button class="btn btn-primary w-80" @click="dateRoomPickerConfirmHandler" :disabled="disabledDateRoomPickerConfirm">
                   {{ $t('confirm') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!classic" class="dropdown-room">
            <div v-if="isMobile" class="split-bar"></div>
            <h5 class="pl-2 pb-2">
              <div>
                <b>{{ $t('roomTypeFilter') }}：</b>
              </div>
              <div class="pt-2">
                <template v-if="roomSelected.length">{{ $t('selected') }}</template>
                <template v-else>{{ $t('allRoomType') }}</template>
              </div>
            </h5>
            <div ref="dropdownRoomList" :class="['dropdown-room-list', {'disabled': isDragging}]" @scroll="dropdownRoomListScrollHandler">
              <template v-if="!hotelRoomsType.length">
                <div class="row middle h-90">
                  <div class="col-12 text-center">
                    <img :src="require('@/assets/img/hotel-img-nodata.svg')" alt="hotelNotFound">
                    <h5 class="py-1"><b>{{ $t('todayNoData') }}</b></h5>
                    <small>{{ $t('selectOtherDate') }}</small>
                  </div>
                </div>
              </template>
              <template v-else>
                <ul ref="dropdownRoomListUl">
                  <li v-for="(v, i) in hotelRoomsType"
                    :key="i"
                    :class="{
                      'active': isRoomActive(v.id),
                      'disabled': !enabledRoomIds.includes(+v.id)
                    }"
                    @click="dropdownRoomListClickHandler(v)">
                    <div class="flex middle">
                      <div class="dropdown-room-list-image">
                        <img :src="imageParse(v)">
                        <template v-if="isRoomActive(v.id)">
                          <i class="owl-status-check checked-icon"></i>
                        </template>
                      </div>
                      <div class="dropdown-room-list-label pl-3">
                        {{ v.name }}
                      </div>
                    </div>
                  </li>
                </ul>
                <i class="owl-caret-bottom dropdown-room-list-scroll-to"
                  :class="{'scroll-bottom': scrollBottom}"
                  @click="scrollToBottomClickHandler"></i>
              </template>
            </div>
          </div>
        </div>
        <div v-if="!classic" class="toolbar">
          <i class="owl-caret-bottom dropdown-room-list-scroll-to"
            :class="{'scroll-bottom': scrollBottom}"
            @click="scrollToBottomClickHandler"></i>
          <div class="buttons row pt-2">
            <div class="close-button col-auto">
              <button class="btn btn-outline-grey" @click="dateRoomPickerCloseHandler">
                {{ $t('close') }}
              </button>
            </div>
            <div class="col text-right">
              <button
                v-if="roomDatesSelectedClearVisible"
                class="btn btn-link dropdown-room-clear"
                @click.self="roomDatesSelectedClearHandler"
              >
                {{ $t('cleanAll') }}
              </button>
            </div>
            <div class="submit-button col-auto">
              <button class="btn btn-primary" @click="dateRoomPickerConfirmHandler" :disabled="disabledDateRoomPickerConfirm">
                {{ $t('confirm') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </popperMenu>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import dayjs from 'dayjs'
import owlsight from 'owlsight/src/tailwind.js'
import popperMenu from '@/components/popperMenu'
import roomDefaultCover from '@/assets/img/default-room.jpg'

export default {
  name: 'ComponentsRoomDateSelector',
  components: {
    popperMenu
  },
  filters: {
    shortDateFormat (value) {
      return dayjs(value).isValid() ? dayjs(value).format('MM/DD ddd') : ''
    }
  },
  props: {
    visible: Boolean,
    classic: {
      type: Boolean,
      default: false
    },
    dateRange: {
      type: Object,
      default () {
        return {}
      }
    },
    hotelRoomsType: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    // const todayByHotelTimeZone = this.$store.state.hotel.todayByHotelTimeZone
    return {
      minDate: null,
      maxDate: null,
      minPageTemp: null,
      maxPageTemp: null,
      disabledDatesTemp: null,
      disabledDates: [],
      enabledHotelRooms: [],
      dateRangeValue: {},
      backUp: {
        dateRangeValue: null
      },
      dateRangeValueClear: false,
      owlsight,
      isDragging: false,
      dateRangeTemp: null,
      calendarNote: '',
      scrollBottom: true,
      roomSelected: [],
      visibleValue: false,
      isMobile: false
    }
  },
  watch: {
    dateRange: {
      handler (value) {
        this.dateRangeValue = value
        this.backUp.dateRangeValue = value
      },
      immediate: true,
      deep: true
    },
    dateRangeValue: {
      handler (value) {
        this.dateRangeTemp = null
      },
      immediate: false,
      deep: true
    },
    hotelRoomsType: {
      handler () {
        this.$nextTick(() => {
          this.checkScrollBottom()
        })
      },
      immediate: true,
      deep: true
    },
    visible: {
      handler (value) {
        if (this.visibleValue !== value) {
          this.visibleValue = value
        }
        if (value) {
          this.$nextTick(() => {
            this.checkScrollBottom()
          })
        }
      },
      immediate: true,
      deep: true
    },
    visibleValue: {
      handler (value) {
        if (this.visible !== value) {
          this.$emit('update:visible', value)
        }
        if (value) {
          this.$nextTick(() => {
            this.checkScrollBottom()
          })
        } else {
          this.dateRoomPickerCloseHandler()
        }
        this.getRoomDisabledDates()
      },
      immediate: true,
      deep: true
    },
    roomSelected: {
      handler (value) {
        this.getRoomDisabledDates()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'hotel/isEnabledRoomCalendar',
      'hotel/hotelDisplaySetting'
    ]),
    dateRangeText () {
      let result = ''
      if (this.dateRangeTemp) {
        const start = this.$options.filters.shortDateFormat(this.dateRangeTemp.start)
        const end = `<span class="text-primary">${this.$t('noCheckOutDate')}</span>`
        result = `${start} ~ ${end}`
      }
      return result
    },
    dateRangeValueParseResult () {
      return this.dateRangeValueClear
        ? this.backUp.dateRangeValue
        : this.dateRangeValue
    },
    disabledDateRoomPickerConfirm () {
      return this.selectedRoom === null && !this.dateRangeValueClear
        ? false
        : this.dateRangeValue === null || !this.enabledRoomIds.includes(this.selectedRoom)
    },
    roomDatesSelectedClearVisible () {
      return !!this.roomSelected.length || !this.dateRangeValueClear || !!this.dateRangeTemp
    },
    enabledRoomIds () {
      return this.dateRangeValueClear
        ? this.hotelRoomsType.map(c => +c.id)
        : this.enabledHotelRooms.map(c => +c.id)
    },
    activeRoomId () {
      return this.roomSelected.length ? this.roomSelected.map(c => c.id) : null
    },
    selectedRooms () {
      return this.roomSelected.length ? this.roomSelected.map(c => c.name).join() : this.$t('allRoomType')
    },
    selectedRoom () {
      return (this.roomSelected.length && this.roomSelected[0].id) || null
    },
    totalDays () {
      const result = `${this.diffDays + 1} ${this.$tc('day', this.diffDays + 1)} ${this.diffDays} ${this.$tc('nights', this.diffDays)}`
      this.$emit('update:dates', result)
      return result
    },
    diffDays () {
      const date = this.dateRangeTemp || this.dateRangeValue
      return this.dateRangeValueClear
        ? -1
        : this.$dayjs(date.end).diff(this.$dayjs(date.start), 'day')
    },
    minPage () {
      return {
        year: this.$dayjs().year(),
        month: this.$dayjs().month() + 1
      }
    },
    todayByHotelTimeZone () {
      return this.$store.state.hotel.todayByHotelTimeZone
    }
  },
  mounted () {
    // 按照日曆設定追加日曆滿房提示
    if (this['hotel/isEnabledRoomCalendar']) {
      this.calendarNote = `* ${this.$t('fullyBookedDescription')}`
      // this.appendCalendarDescriptionStyle(this.calendarDescription)
    }
    const $this = this
    window.addEventListener('resize', function () {
      $this.onresize()
    })
    this.onresize()

    this.getRoomDisabledDates()
  },
  methods: {
    ...mapActions([
      'hotel/fetchRoomDisabledDates',
      'hotel/fetchRoomsByDate'
    ]),
    ...mapMutations([
      'search/SET_DATE'
    ]),
    $clone (value) {
      return JSON.parse(JSON.stringify(value))
    },
    async getRoomDisabledDates () {
      if (!this.fromPage) return
      this.updateEnabledHotelRooms(this.dateRangeValue)

      // fetchRoomDisabledDates
      const start = this.$dayjs(`${this.fromPage.year}-${this.fromPage.month}-01`).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      const end = this.$dayjs(start).add(3, 'month').endOf('month').format('YYYY-MM-DD')
      let result = null
      try {
        result = await this['hotel/fetchRoomDisabledDates']({
          isPreview: true,
          roomId: this.selectedRoom,
          start,
          end
        })
      } catch (ex) {
        //
      }
      const minDate = { end: this.$dayjs().add(-1, 'days').format('YYYY-MM-DD') }
      this.disabledDates = [minDate, ...(result || [])] || []

      if (!this.dateRangeValue) return
      // parseClosestDate
      const start1 = this.$dayjs(this.dateRangeValue.start).format('YYYY-MM-DD')
      const closestDate = this.parseClosestDate(start1)
      if (closestDate && closestDate.min) {
        const min = this.$dayjs(closestDate.min).add(1, 'days').format('YYYY-MM-DD')
        if (min > start1) {
          this.dateRangeValue.start = min
          this.dateRangeValue.end = this.$dayjs(min).add(1, 'days').format('YYYY-MM-DD')
          this['search/SET_DATE'](this.dateRangeValue)
        }
      }
    },
    isRoomActive (value) {
      return this.roomSelected.map(c => c.id).includes(value)
    },
    hasSlot (value) {
      return !!this.$slots[value]
    },
    onresize () {
      this.isMobile = document.body.clientWidth < 576
    },
    checkScrollBottom () {
      if (this.isMobile) {
        const target = this.$refs.dropdownMenu
        if (target) {
          this.scrollBottom = target.scrollHeight <= window.screen.height
        }
      } else {
        const target = this.$refs.dropdownRoomList
        const targetUl = this.$refs.dropdownRoomListUl
        if (target && targetUl) {
          this.scrollBottom = targetUl.clientHeight <= target.clientHeight
        }
      }
    },
    scrollToBottomClickHandler () {
      if (this.isMobile) {
        this.$refs.dropdownMenu.scrollTop = 10000
      } else {
        this.$refs.dropdownRoomList.scrollTop = 10000
      }
    },
    dropdownScrollHandler (e) {
      const h = e.target.scrollHeight - e.target.clientHeight
      const top = e.target.scrollTop
      this.scrollBottom = top > h * 0.8
    },
    dropdownRoomListScrollHandler (e) {
      const h = e.target.scrollHeight - e.target.clientHeight
      const top = e.target.scrollTop
      this.scrollBottom = top > h * 0.8
    },
    dropdownRoomListClickHandler (data) {
      if (!this.enabledRoomIds.includes(+data.id)) {
        return
      }

      const index = this.roomSelected.findIndex(c => c.id === data.id)
      if (index !== -1) {
        this.roomSelected.splice(index, 1)
      } else {
        this.roomSelected = [data]
        if (this.isMobile) {
          this.scrollToTopSmooth(this.$refs.dropdownMenu, 0)
        }
      }
      this.getRoomDisabledDates()
    },
    scrollToTopSmooth (el, value, dx = 5) {
      ;(function foo () {
        el.scrollTop -= dx
        if (el.scrollTop > 0) {
          setTimeout(() => foo())
        }
      })()
    },
    roomDatesSelectedClearHandler () {
      this.isDragging = false
      this.dateRangeValueClear = true
      this.dateRangeValue = null
      this.dateRangeTemp = null
      this.roomSelected = []
      this.disabledDatesTemp = null
      this.getRoomDisabledDates()
    },
    dateRoomPickerConfirmHandler () {
      if (this.disabledDateRoomPickerConfirm) return

      this.visibleValue = false
      const result = {
        roomId: this.selectedRoom,
        dates: this.dateRangeValueParseResult
      }
      this.backUp.dateRangeValue = this.dateRangeValueParseResult
      this.$emit('submit', result)
    },
    dateRoomPickerCloseHandler () {
      this.getRoomDisabledDates()
      this.dateRangeValue = this.backUp.dateRangeValue
      this.visibleValue = false
      this.disabledDatesReset()
    },
    imageParse (data) {
      return data.cover || roomDefaultCover
    },
    //
    onDatePickerUpdate ($event) {
      if (this['hotel/isEnabledRoomCalendar']) {
        this.fromPage = $event
        this.getRoomDisabledDates()
      } else {
        const minDate = { end: this.$dayjs().add(-1, 'days').format('YYYY-MM-DD') }
        this.disabledDates = [
          minDate
        ]
      }
    },
    async handleCustomerSelectorOpen (value) {
      const start = this.$dayjs(value.start).format('YYYY-MM-DD')
      const end = this.$dayjs(value.end).format('YYYY-MM-DD')
      if (start === end) {
        this.dateRangeValue = {
          start: value.start,
          end: this.$dayjs(start).add(1, 'days').format('YYYY-MM-DD')
        }
        value = this.dateRangeValue

        const elStart = document.querySelector(`.vc-day.id-${this.$dayjs(value.start).format('YYYY-MM-DD')} span`)
        if (elStart) elStart.click()
        const elEnd = document.querySelector(`.vc-day.id-${this.$dayjs(value.end).format('YYYY-MM-DD')} span`)
        if (elEnd) elEnd.click()
      }

      this.dateRangeValueClear = false
      this.updateEnabledHotelRooms(value)
      if (this['hotel/hotelDisplaySetting'].isForceSelectCustomerNumber) {
        setTimeout(() => {
          this.$refs.customerSelect.isOpen = true
          this.handleRemovePlaceHolder()
        }, 50)
      }

      this.disabledDatesReset()
      this.isDragging = false

      if (this.classic) {
        this.dateRoomPickerConfirmHandler()
      }
    },
    async updateEnabledHotelRooms (value) {
      if (!value) return
      try {
        const result = await this['hotel/fetchRoomsByDate'](value)
        if (result) {
          this.enabledHotelRooms = result
        }
      } catch (ex) {
        this.enabledHotelRooms = []
      }
    },
    disabledDatesReset () {
      this.minDate = null
      this.maxDate = null
      this.minPageTemp = null
      this.maxPageTemp = null
      this.disabledDatesTemp = null
    },
    dayDrag (event) {
      this.disabledDatesReset()
      if (!this.disabledDatesTemp) {
        this.disabledDatesTemp = this.$clone(this.disabledDates)
      }

      this.isDragging = !!event
      const start = this.$dayjs(event.start).format('YYYY-MM-DD')
      const end = this.$dayjs(event.end).format('YYYY-MM-DD')

      this.dateRangeTemp = { start, end }
      if (this.isTouchDevice && !!event) {
        if (start < end) {
          this.dateRange.start = start
          this.dateRange.end = end
          this.$refs.datePicker.hidePopover()
          this.isDragging = false
        }
      }

      const closestDate = this.parseClosestDate(start)
      if (closestDate) {
        if (closestDate.min) {
          this.minDate = closestDate.min
          const date = this.$dayjs(this.minDate)
          this.minPageTemp = {
            year: date.year(),
            month: date.month() + 1
          }
        }
        if (closestDate.max) {
          this.maxDate = closestDate.max
          const date = this.$dayjs(this.maxDate)
          this.maxPageTemp = {
            year: date.year(),
            month: date.month() + 1
          }
          const removeIndex = this.disabledDatesTemp.indexOf(this.maxDate)
          if (removeIndex !== -1) {
            this.disabledDatesTemp.splice(removeIndex, 1)
          }
        }
      }
    },
    parseClosestDate (value) {
      const dates = this.disabledDates.map(c => (typeof c === 'string' ? c : c.end || null)).filter(c => c)
      const dx = dates.map((date, index) => ({
        index,
        value: this.$dayjs(date).diff(this.$dayjs(value), 'days'),
        date
      }))
      const min = parseDx(dx)
      const max = parseDx(dx, true)

      return { min, max }

      function parseDx (dx, pn = false) {
        const dN = pn
          ? dx.filter(c => c.value > 0)
          : dx.filter(c => c.value <= 0)
        const dNValue = pn
          ? Math.min(...dN.map(c => c.value))
          : Math.max(...dN.map(c => c.value))

        const obj = dx.find(c => c.value === dNValue)
        return (obj && obj.date) || null
      }
    }
  }
}
</script>

<style lang="scss">
$color-primary: #078abc;

.text-primary {
  color: $color-primary;
}
</style>

<style lang="scss" scoped>
.components-room-date-selector {
  @import '@/stylesheets/bs_func.scss';
  @import '@/stylesheets/width.scss';
  @import '@/stylesheets/padding.scss';
  @import '@/stylesheets/middle.scss';
  @import '@/stylesheets/trans.scss';
  @import '@/stylesheets/ellipsis.scss';

  $color-primary: #078abc;
  $color-dark: #3c4145;
  $color-light: #f8fafc;
  $color-secondary: #a3abb2;

  .date-range-selector {
    @include row();
    border-radius: 8px;

    > div {
      @include col-auto();
      color: $color-dark;

      &.date-range-split {
        padding: 1.65em 1.2em 0;
      }

      &.room-type-split {
        padding: 1em;
      }

      &.room-type {
        @include col();
        display: inline-grid;

        > div {
          &.value {
            @include ellipsis();
          }
        }
      }

      i {
        color: $color-secondary;
      }

      > div {
        @include trans();

        &.label {
          margin-bottom: 0.5em;
        }

        &.value {
          cursor: pointer;
          font-weight: 700;
          padding-left: 0.25em;

          &:hover {
            color: $color-primary;
          }
        }
      }

      &.active {
        i {
          color: $color-dark;
        }
      }
    }
  }

  .input {
    color: $color-dark;
    background-color: $color-light;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: transparent;
    padding: 0.5rem 1rem;
  }

  .component-popper-menu.active {
    .date-range-selector,
    .dropdown {
      border-radius: 0;
    }

    // mask
    // &:after {
    //   content: "";
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100vh;
    //   background-color: rgba(0, 0, 0, 0.6);
    //   z-index: 1;
    // }
  }

  .component-popper-menu {
    .dropdown {
      .dropdown-menu {
        background-color: #fff;
        color: $color-dark;
        padding: 1em 0.5em;

        > div {
          padding: 0.5em;
        }

        .dropdown-calender {
          width: 300px;

          .calender {
            border: 0;
            width: 100%;
          }

          .c-pane-container {
            box-shadow: none !important;
            color: #393d46 !important;
          }

          .toolbar {
            display: block;
            padding-top: .5em;
            text-align: center;

            > div {
              @include middle();
              &:not(:last-child) {
                padding-bottom: 1em;
              }
            }
          }
        }

        .dropdown-room {
          position: relative;
          width: 350px;
          background-color: #fff;

          .split-bar {
            @include middle();
            position: relative;
            width: 100%;
            height: 20px;
            padding-bottom: 1em;

            &:after {
              content: "";
              width: 56px;
              height: 4px;
              background-color: #d9d9d9;
              border-radius: 4px;
            }
          }

          .dropdown-room-list {
            height: 368px;
            overflow-y: auto;

            &.disabled {
              opacity: 0.2;
              cursor: not-allowed;
              overflow: hidden;

              ul {
                pointer-events: none;
              }
            }

            .dropdown-room-list-image {
              @include middle();
              position: relative;

              .checked-icon {
                position: absolute;
                width: 1.5rem;
                height: 1.5rem;
                background-color: $color-primary;
                border-radius: 50%;
                color: #fff;
                border: 2px solid #fff;
                font-size: 80%;
                text-align: center;
                line-height: 1.3rem;
              }
            }

            .dropdown-room-list-label {
              @include ellipsis(2);
              font-size: 0.875em;
              font-weight: 500;
              line-height: 1.55em;
            }

            ul {
              list-style-type: none;
              padding: 2px 2px 2em;
              margin: 0;

              li {
                padding: 0.5em;
                border-radius: 0.5em;
                cursor: pointer;

                &:hover {
                  background-color: #f6f6f6;
                }

                &.active {
                  outline: 2px solid #3AB3E2;
                }

                &.disabled {
                  opacity: 0.2;
                  cursor: not-allowed;
                }
              }
            }
          }

          &:after {
            pointer-events: none;
            content: "";
            display: block;
            position: relative;
            left: 0;
            top: -100%;
            z-index: 1;
            width: 100%;
            height: 100%;
            box-shadow: inset 0 -2em 1em #fff;
          }

          img {
            object-fit: cover;
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
            border-radius: 0.5em;
          }
        }

        .dropdown-room-clear {
          white-space: nowrap;
          color: $color-primary;
          width: auto;
        }
      }

      .dropdown-room-list-scroll-to {
        @include trans();
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -0.75rem;
        margin-top: -2.5rem;
        z-index: 2;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        font-size: 1.5rem;

        &:before {
          margin: 0;
        }

        &.scroll-bottom {
          opacity: 0;
        }
      }

      > .toolbar {
        display: none;
        box-shadow: 0 0 10px #eee;
        padding: 0.5em 0.5em 1em;
        text-align: center;

        > div {
          @include middle();
          width: 100%;
          padding: 0 .5em;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .component-popper-menu {
      .date-range-selector {
        padding: 0.75em 1em 0.75em 0.75em;
        background-color: $color-light;

        &:before {
          content: "\E862";
          font-family: owl;
          line-height: 2em;
          font-size: 1.5em;
          padding-right: 0.25em;
          color: $color-secondary;
        }

        > div {
          > div {
            &.value {
              &:hover {
                color: $color-dark !important;
              }
            }
          }

          &.date-range-checkin,
          &.date-range-checkout {
            .label {
              visibility: hidden;
            }
          }

          &.date-range-split {
            padding: 1.65em 0.25em 0;
          }

          &.room-type-split {
            display: none;
          }

          &.room-type {
            position: absolute;
            top: 1.2em;
            left: 3em;
            font-size: 0.9em;

            .label {
              display: none;
            }

            .value {
              font-weight: 500;
            }
          }
        }
      }

      .dropdown {
        .dropdown-menu {
          position: fixed;
          top: 72px;
          left: 0;
          width: 100vw !important;
          height: 100vh;
          overflow-y: auto;
          flex-direction: column;

          > div {
            padding: 1em;
          }

          .dropdown-calender,
          .dropdown-room {
            width: 100vw !important;
            position: sticky;
            top: 0;
          }

          .dropdown-calender {
            > div {
              width: calc(100vw - 2em);
            }

            .toolbar {
              .buttons {
                display: none;
              }
            }
          }

          .dropdown-room {
            max-width: 100%;
            box-shadow: 0 0 10px #eee;
            padding-bottom: 120px;

            .dropdown-room-list {
              height: auto;

              .dropdown-room-list-scroll-to {
                display: none;
              }
            }
          }
        }

        > .toolbar {
          display: flex;
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: #fff;

          .dropdown-room-list-scroll-to {
            top: 50%;
          }
        }
      }
    }
  }
}
</style>
