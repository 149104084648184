import dayjs from 'dayjs'
// import USER from '@/api/user'
// import { event } from 'vue-analytics'
import { event } from 'vue-gtag'

// initial state
const state = {
  dateRange: {
    start: dayjs().toDate(),
    end: dayjs().add(1, 'day').toDate()
  },
  people: {
    adult: 1,
    child: 0,
    infant: 0
  },
  roomId: null
}

// getters
const getters = {
  dateRange: (state) => {
    return state.dateRange
  },
  people: (state) => {
    return state.people
  },
  nights: (state) => {
    const nights = dayjs(state.dateRange.end).diff(dayjs(state.dateRange.start), 'day')
    return nights
  },
  roomId: (state) => {
    return state.roomId
  }
}

// actions
const actions = {
  // fetchProfile ({ commit, state }) {
  //   return USER.getProfile().then(res => {
  //     commit('SET_PROFILE', res.data.data)
  //   })
  // }
}

// mutations
const mutations = {
  SET_DATE (state, date) {
    const {
      start = dayjs().toDate(),
      end = dayjs().add(1, 'day').toDate()
    } = date
    state.dateRange.start = start
    state.dateRange.end = end
    // gatg custom event
    event('user-search', {
      'event_category': 'search',
      'event_label': 'search-date',
      'value': `${dayjs(start).format('YYYY/MM/DD')} - ${dayjs(end).format('YYYY/MM/DD')}`
    })
    window.fbq('track', 'ViewContent', {
      content_type: 'searchParams',
      contents: [
        {
          start: dayjs(start).format('YYYY/MM/DD'),
          end: dayjs(end).format('YYYY/MM/DD')
        }
      ]
    })
  },
  SET_PEOPLE (state, people) {
    const {
      adult = 1,
      child = 0,
      infant = 0
    } = people
    state.people.adult = adult
    state.people.child = child
    state.people.infant = infant
    // gatg custom event
    event('user-search', {
      'event_category': 'search',
      'event_label': 'search-params',
      'value': `adult: ${adult} | child: ${child} | infant: ${infant}`
    })
    window.fbq('track', 'ViewContent', {
      content_type: 'searchParams',
      contents: [
        {
          adult: adult,
          child: child,
          infant: infant
        }
      ]
    })
  },
  SET_ROOMID (state, value) {
    state.roomId = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
