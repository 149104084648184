var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "hotel" },
    [
      !_vm.isLoadingHotel
        ? _c("hotelSubNav", { ref: "hotelSubNav" })
        : _vm._e(),
      _c("section", { staticClass: "hotel-rooms container flex" }, [
        _c(
          "div",
          { staticClass: "hotel__body__rooms flex__main-panel lg:pr-12" },
          [
            _c("hotelContent", {
              on: { showCustomerSelector: _vm.handleShowCustomerSelector }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "hotel__body__total flex__aside-panel lg:pl-12" },
          [_c("orderTotalAside")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }