import Vue from 'vue'
import Router from 'vue-router'
import Hotel from './views/Hotel/Index.vue'
import store from '@/store'
import { messages } from '@/i18n'
import dayjs from 'dayjs'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== from.name || !from) {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'Welcome',
      redirect: to => {
        window.location = 'https://www.owlting.com/owlnest/'
      }
    },
    {
      path: '/:hotelId',
      name: 'Hotel',
      component: Hotel,
      meta: {
        title: '訂房',
        titleI18n: 'bookRoom'
      }
    },
    {
      path: '/:hotelId/addon',
      name: 'Addon',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Addon" */ './views/Addon/Index.vue')
    },
    {
      path: '/:hotelId/payment',
      name: 'Payment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Payment" */ './views/Payment/Index.vue'),
      meta: {
        footerMargin: false
      }
    },
    {
      path: '/:hotelId/payment-callback',
      name: 'PaymentCallback',
      component: () => import(/* webpackChunkName: "PaymentCallback" */ './views/PaymentCallback/Index.vue'),
      meta: {
        cover: false
      }
    },
    {
      path: '/:hotelId/complete/:orderSerial',
      name: 'Complete',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Complete" */ './views/Complete/Index.vue'),
      meta: {
        cover: false
      }
    }
  ]
})

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  const today = dayjs(store.state.hotel.todayByHotelTimeZone).format('YYYY-MM-DD')
  const isBefore = dayjs(to.query.start).isBefore(today) || dayjs(to.query.end).isBefore(today)
  if (typeof to.params.hotelId !== 'undefined' && store.state.hotelId !== to.params.hotelId) {
    // test: 779564dc-b308-4605-955c-4b2efeab87e2
    store.commit('SET_HOTEL_ID', to.params.hotelId)
  }

  // set lang query
  const queryLang = to.query.lang
  const hotelLang = store.state.hotel.hotel.language
  const supportLangs = Object.keys(messages)
  const existLangs = queryLang || hotelLang

  const finalLang = supportLangs.includes(existLangs) ? existLangs : 'zh_TW'
  store.commit('SET_LANGUAGE', finalLang)

  const isValidQueryLang = supportLangs.includes(queryLang)
  if (!isValidQueryLang) {
    next({
      ...to,
      query: {
        ...to.query,
        lang: finalLang
      },
      replace: true
    })
  }
  //

  if (!hasQueryParams(to) && hasQueryParams(from) && !isBefore) {
    next({
      ...to,
      query: {
        ...from.query
      }
    })
  } else if (hasQueryParams(to) && !hasQueryParams(from) && isBefore) {
    next({
      ...to,
      query: {
        ...to.query,
        start: today,
        end: dayjs(today).add(1, 'day').format('YYYY-MM-DD')
      },
      replace: true
    })
  }

  // hotel alias
  const storeAlias = store.state.hotelAlias
  const hotelCustomDomain = store.state.hotelCustomDomain

  if (hotelCustomDomain && location.hostname !== hotelCustomDomain) {
    // const pathname = location.pathname.replace(to.params.hotelId, 'booking')
    let pathname = location.pathname.split('/')
    pathname.splice(0, 2)
    location.href = `${location.protocol}//${hotelCustomDomain}/booking/${pathname.join('/')}${location.search}`
    // location.href = `${location.protocol}//${hotelCustomDomain}${pathname}${location.search}`
    return
  }

  if (storeAlias && to.params.hotelId !== storeAlias) {
    next({
      ...to,
      params: {
        ...to.params,
        hotelId: storeAlias
      },
      replace: true
    })
  }

  next()
})

export default router
