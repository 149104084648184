import { snakeCase } from 'lodash'
import store from '@/store'

const isObject = (val) => {
  return val != null && typeof val === 'object' && Array.isArray(val) === false
}

export default function (i18nObj, defaultText = '') {
  if (isObject(i18nObj)) {
    return i18nObj[snakeCase(store.state.selectedLanguage)] || defaultText
  }
  return defaultText
}
