import request from '@/utils/request'

export default {
  fetchExperiences (hotelId, start, end, people, cancelToken) {
    return request({
      cancelToken: cancelToken.token,
      url: `/hotels/${hotelId}/experiences`,
      method: 'get',
      params: {
        during_date: `${start},${end}`,
        adult: people.adult,
        child: people.child,
        infant: people.infant
      }
    }).catch(err => {
      console.debug('CATCH', err)
    })
  }
}
