var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _vm.messageButtons.length
        ? _c("messageButton", {
            attrs: { data: _vm.messageButtons },
            scopedSlots: _vm._u(
              [
                {
                  key: "tooltip",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.$t("dragging_move")))]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1567921581
            )
          })
        : _vm._e(),
      _vm.isSuper
        ? _c("div", { staticClass: "bg-red text-white text-center p-8" }, [
            _vm._v("SUPER MODE")
          ])
        : _vm._e(),
      _c("hotelNav", { staticClass: "hotel-navbar" }),
      _vm.$route.meta.cover !== false
        ? _c(
            "div",
            {
              ref: "hotelCover",
              staticClass: "hotel-cover hotel__cover",
              class: {
                "z-index-up": _vm.isLoadingRooms,
                show: _vm.hotelCoverShow
              }
            },
            [
              _c("imagesSplitter", {
                attrs: { photos: _vm.coverImages },
                on: { clickImage: _vm.openPhotoSwipe }
              }),
              _c("photoSwipe", {
                ref: "photoSwipe",
                attrs: { photos: _vm.coverImages },
                on: { close: _vm.photoSwipeCloseHandler }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoadingHotel
        ? _c(
            "div",
            { staticClass: "hotel-main main" },
            [_c("keep-alive", [_c("router-view")], 1)],
            1
          )
        : _vm._e(),
      _vm.isInitDone
        ? _c(
            "footer",
            {
              staticClass: "hotel-footer bg-grey-lightest py-24",
              class: { "mobile-has-order": _vm.displayFooterMarginBottom }
            },
            [
              _c("div", { staticClass: "container text-grey-dark" }, [
                _c("div", { staticClass: "footer__hotel" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "footer__hotel-name flex flex-col sm:flex-row items-center justify-between border-b-1 border-grey-light pb-16"
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-grey-darker font-medium mb-8 sm:mb-0"
                        },
                        [_vm._v(_vm._s(_vm.hotelHotel.name))]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "footer__contacts flex flex-col py-24 text-sm"
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "footer__contacts__way mb-16 lg:mb-0",
                          attrs: {
                            href:
                              "http://maps.google.com/maps?q=" +
                              _vm.hotelHotel.address,
                            target: "_blank"
                          }
                        },
                        [
                          _c("i", { staticClass: "owl-location" }),
                          _c("span", [_vm._v(_vm._s(_vm.hotelHotel.address))])
                        ]
                      ),
                      _vm.hotelDisplaySetting.showPhone
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "footer__contacts__way mb-16 lg:mb-0",
                              attrs: {
                                href: "tel:" + _vm.hotelHotel.contact.phone
                              }
                            },
                            [
                              _c("i", { staticClass: "owl-phone" }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.hotelHotel.contact.phone))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.hotelHotelWebSiteUrl &&
                      _vm.hotelDisplaySetting.showLink
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "footer__contacts__way mb-16 lg:mb-0",
                              attrs: {
                                href: _vm.hotelHotelWebSiteUrl,
                                target: "_blank"
                              }
                            },
                            [
                              _c("i", { staticClass: "owl-earth" }),
                              _c("span", [_vm._v(_vm._s(_vm.hotelHotel.name))])
                            ]
                          )
                        : _vm._e(),
                      _vm.hotelHotel.contact.vat
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "footer__contacts__way my-16 ml-4 lg:mb-0"
                            },
                            [
                              _c("i", { staticClass: "owl-card-member" }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("vat") +
                                      "：" +
                                      _vm.hotelHotel.contact.vat
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]),
                _vm.isOwlTingDomain
                  ? _c(
                      "span",
                      { staticClass: "block text-center sm:text-right" },
                      [
                        _vm._v("\n        Powered by "),
                        _c(
                          "a",
                          {
                            staticClass: "text-blue font-bold no-underline",
                            attrs: {
                              href: "https://www.owlting.com/owlnest",
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("footerPowerBy")))]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _c("v-dialog", { attrs: { clickToClose: false } }),
      _c("modals-container"),
      _c("cookieNotification"),
      _vm.isSuper ? _c("devTool") : _vm._e(),
      _c("cookieNotification")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }