<template>
  <div
    :style='{top: pageIsHotelOrAddon === "Addon" ? addonStyle.top : hotelStyle.top}'
    class="order-total lg:sticky">
    <!-- desktop -->
    <aside class="order-total__desktop shadow-md border-1 border-grey-lightest rounded-sm hidden lg:block">
      <div class="order-total__desktop__head text-grey-darkest px-16 py-24 border-b-1 border-grey-lighter">
        <div class="order-total__desktop__head__row mb-16">
          <i class="owl-calendar mr-8 text-grey text-xl"></i>
          <span>{{ this['search/dateRange'].start | dayFormat }}</span>
          <span> - </span>
          <span>{{ this['search/dateRange'].end | dayFormat }}</span>
          <small class="ml-8 text-grey-dark">( {{ diffDays + 1 }} {{ $tc('day', diffDays + 1) }} {{ diffDays }} {{ $tc('night', diffDays) }} )</small>
        </div>
        <div class="order-total__desktop__head__row">
          <i class="owl-user-group mr-8 text-grey text-xl"></i>
          <span>{{ this['search/people'].adult }} {{ $tc('adult', this['search/people'].adult) }}</span>
          <span v-if="this['hotel/hotelReceptionConfig'].child.isReceive">, {{ this['search/people'].child }} {{ $tc('child', this['search/people'].child) }}</span>
          <span v-if="this['hotel/hotelReceptionConfig'].infant.isReceive">, {{ this['search/people'].infant }} {{ $tc('infant', this['search/people'].infant) }}</span>
        </div>
      </div>

      <div
        :style='{maxHeight: pageIsHotelOrAddon === "Addon" ? addonStyle.maxHeight : hotelStyle.maxHeight}'
        class="order-total__desktop__body">
        <SlideXLeftTransition group :duration="200">
          <OrderAddon v-for="addon in this['order/addons'].slice().reverse()" :addon="addon" :key="addon.id"></OrderAddon>
          <OrderPlan v-for="plan in this['order/plans'].slice().reverse()" :plan="plan" :key='plan.uid'></OrderPlan>
        </SlideXLeftTransition>
      </div>
      <template v-if="hasOrderItems">
        <div class="order-total__desktop__footer px-16 py-24 border-t-1 border-grey-lighter">
          <template v-if="hasExtraTaxFeeConfig">
            <div class="order-total__desktop__footer__row text-sm text-grey-darker">
              <label>
                {{ $t('extraFeesCharge') }}
                <i class="owl-status-circle-info" v-tippy="{ html: '#extra-fees-list', interactive : true, theme: 'light' }"></i>

                <div id="extra-fees-list" class="hidden" v-tippy-html>
                  <div v-for="fee in extraFees" :key="`fee-config-${fee.id}`" class="text-sm text-grey-darker flex">
                    <label>{{ fee.name }}: </label>
                    <span>{{ selectedCurrency }} {{ fee.price | currency }}</span>
                  </div>
                </div>
              </label>
              <span>
                {{ selectedCurrency }} <big class="text-blue font-bold">{{ totalFee | currency }}</big>
              </span>
            </div>
          </template>
          <div class="order-total__desktop__footer__row text-grey-darkest">
            <label class='text-grey-darkest'>
              {{ $t('total') }}
              <small v-if="!hasExtraTaxFeeConfig">({{ $t('room_price_has_tax_included') }})</small>
            </label>
            <span class='text-sm text-grey-darkest'>
              {{ selectedCurrency }} <big class='text-xl text-blue font-medium'>{{ this['order/totalPrice'] | currency }}</big>
            </span>
          </div>
          <!-- <template v-if="hasExtraTaxFeeConfig">
            <div v-for="fee in extraFees" :key="`fee-config-${fee.id}`" class="order-total__desktop__footer__row text-xs text-grey-dark">
              <label>{{ fee.name }}</label>
              <span>{{ selectedCurrency }} {{ fee.price | currency }}</span>
            </div>
          </template> -->
          <template v-if="this['order/ifDepositRate']">
            <div class="order-total__desktop__footer__row text-xs text-grey-dark">
              <label>{{ $t('depositTotal') }}</label>
              <span>{{ selectedCurrency }} {{ this['order/depositTotal'] | currency }}</span>
            </div>
            <div class="order-total__desktop__footer__row text-xs text-grey-dark">
              <label>{{ $t('remainTotal') }}</label>
              <span>{{ selectedCurrency }} {{ this['order/remainTotal'] | currency }}</span>
            </div>
          </template>
          <!-- <div class="order-total__desktop__footer__row" v-if="step === 1">
            <button class="btn btn-solid btn-lg btn-blue w-full" @click="nextStep">立即訂購</button>
          </div> -->
          <div class="order-total__desktop__footer__row">
            <button
              v-if='step !== 1'
              class='pre-step btn btn-lg text-grey flex-no-shrink mr-16'
              @click="preStep">{{ $t('pre') }}</button>
            <button class="btn btn-lg btn-solid btn-blue w-full" @click="nextStep">{{ $t('next') }}</button>
          </div>
        </div>
      </template>
    </aside>

    <!-- mobile -->
    <SlideYDownTransition :duration="150">
      <div v-if="hasOrderItems" class="order-total__mobile block lg:hidden">
        <div class="container flex items-end justify-between px-16 py-8">
          <div
            class="order-total__mobile__amount flex flex-col cursor-pointer w-full"
            @click='openOrdersModal'
            :class="{'isAdding': isAdding, 'isMinus': isMinus}">
            <label class='text-grey-dark text-sm mb-8 cursor-pointer'>
              <span>{{ $t('orderTotal') }}</span>
              <i class="owl-status-circle-info"></i>
              <!-- <span class="text-grey-dark text-xs my-4s">({{ $t('watchDetail') }})</span> -->
            </label>
            <span class='text-sm text-grey-darkest'>
              {{ selectedCurrency }} <big class='text-lg text-blue font-medium'>{{ this['order/totalPrice'] | currency }}</big>
            </span>
          </div>
          <!-- <div
            v-if="step === 1"
            class="order-total__mobile__btn-group flex-no-shrink">
            <button
              class="btn btn-solid btn-blue px-32"
              @click="nextStep">立即訂購</button>
          </div> -->
          <div
            class="order-total__mobile__btn-group flex flex-no-shrink">
            <button
              v-if="step !== 1"
              class='pre-step btn text-grey flex-no-shrink mr-16'
              @click="preStep">{{ $t('pre') }}</button>
            <button
              class="btn btn-solid btn-blue w-full px-32"
              @click="nextStep">{{ $t('next') }}</button>
          </div>
        </div>
      </div>
    </SlideYDownTransition>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import OrderPlan from '@/components/orderPlan'
import OrderAddon from '@/components/orderAddon'
import mobileOrders from '@/components/modals/mobileOrders'
import contentWithCover from '@/components/modals/contentWithCover'
import { SlideXLeftTransition, SlideYDownTransition } from 'vue2-transitions'

export default {
  name: 'OrderTotalAside',
  components: {
    OrderPlan,
    OrderAddon,
    SlideXLeftTransition,
    SlideYDownTransition
  },
  data () {
    return {
      isAdding: false,
      isMinus: false,
      hotelStyle: {
        top: '7.5rem',
        maxHeight: '330px'
      },
      addonStyle: {
        top: '1.5rem',
        maxHeight: '400px'
      }
    }
  },
  computed: {
    ...mapGetters({
      hasExtraTaxFeeConfig: 'hotel/hasExtraTaxFeeConfig',
      extraFees: 'order/extraFees',
      totalFee: 'order/totalFee'
    }),
    ...mapGetters([
      'isSuper',
      'selectedCurrency',
      'step',
      'hotelId',
      'hotel/hotel',
      'search/dateRange',
      'search/people',
      'addon/addons',
      'order/plans',
      'order/plansTotal',
      'order/addons',
      'order/ifDepositRate',
      'order/totalPrice',
      'order/depositTotal',
      'order/remainTotal',
      'order/maxPeopleInTotal',
      'hotel/hotelReceptionConfig',
      'hotel/hotelDisplaySetting',
      'hotel/isSkipAddon',
      'isFetchedAddon',
      'order/extraReceptionAdultsCount',
      'order/extraReceptionChildrenCount',
      'order/extraReceptionPriceTotal'
    ]),
    diffDays: function () {
      return this.$dayjs(this['search/dateRange'].end).diff(this.$dayjs(this['search/dateRange'].start), 'day')
    },
    orderCount: function () {
      return _.sumBy(this['order/plans'], plan => plan.qty) + _.sumBy(this['order/addons'], addon => _.sumBy(addon.items, item => item.qty))
    },
    hasPlansItems: function () {
      return !_.isEmpty(this['order/plans'])
    },
    hasOrderItems: function () {
      return !_.isEmpty(this['order/plans']) || !_.isEmpty(this['order/addons'])
    },
    pageIsHotelOrAddon: function () {
      return this.$route.name
    }
  },
  methods: {
    ...mapMutations([
      'order/CLEAR_ORDERS'
    ]),
    preStep: function () {
      this.$router.push({
        name: 'Hotel',
        params: {
          hotelId: this.hotelId
        }
      })
    },
    goAddon () {
      const skipFlag = this.isFetchedAddon ? this['addon/addons'].length === 0 : this['hotel/isSkipAddon']
      if (skipFlag) {
        this.goPayment()
      } else {
        this.$router.push({
          name: 'Addon',
          params: {
            hotelId: this.hotelId
          }
        })
      }
    },
    goPayment () {
      this.$router.push({
        name: 'Payment',
        params: {
          hotelId: this.hotelId
        }
      })
    },
    nextStep: function () {
      if (this.step === 1) {
        const totalPeople = this['search/people'].adult + this['search/people'].child
        if (totalPeople > this['order/maxPeopleInTotal']) {
          let template = ''
          const buttonList = [
            {
              title: this.$t('close')
            }
          ]

          if (this['hotel/hotelDisplaySetting'].isBlockWithNumberNotMatch) {
            template = `<div class="whitespace-pre-wrap"><div>${this.$t('peopleNotEnoughWarning[0]')} <span class="text-red font-bold">${this['order/maxPeopleInTotal']}</span> ${this.$t('peopleNotEnoughWarningBlock')}</div><small class="text-red">${this.$t('peopleNotEnoughWarning[2]')}<small></div>`
          } else { // 設定為不阻擋才提供繼續按鈕
            template = `<div class="whitespace-pre-wrap"><div>${this.$t('peopleNotEnoughWarning[0]')} <span class="text-red font-bold">${this['order/maxPeopleInTotal']}</span> ${this.$t('peopleNotEnoughWarning[1]')}</div><small class="text-red">${this.$t('peopleNotEnoughWarning[2]')}<small></div>`
            buttonList.push({
              title: this.$t('continued'),
              handler: () => {
                this.$modal.hide('warninig-modal')
                this.$router.push({
                  name: 'Addon',
                  params: {
                    hotelId: this.hotelId
                  }
                })
              }
            })
          }

          this.$modal.show(contentWithCover, {
            title: this.$t('remind'),
            type: 'warning',
            icon: 'owl-alert',
            isScroll: false,
            context: template,
            buttons: buttonList
          },
          {
            adaptive: true,
            name: 'warninig-modal',
            clickToClose: false,
            height: 'auto',
            width: '100%',
            maxWidth: 450,
            scrollable: true
          })
        } else {
          this.goAddon()
        }
      }
      if (this.step === 2) {
        this.pixelAddPaymentInfo()
        this.goPayment()
      }
    },
    openOrdersModal: function () {
      if (!_.isEmpty(this['order/plans']) || !_.isEmpty(this['order/addons'])) {
        this.$modal.show(mobileOrders, {
          text: 'test'
        }, {
          name: 'mobile-orders',
          height: '100%',
          width: '100%',
          scrollable: true
        })
      }
    },
    addAnimation: function () {
      setTimeout(() => {
        this.isAdding = true
      }, 150)
      setTimeout(() => {
        this.isAdding = false
      }, 450)
    },
    minusAnimation: function () {
      setTimeout(() => {
        this.isMinus = true
      }, 150)
      setTimeout(() => {
        this.isMinus = false
      }, 450)
    },
    pixelAddPaymentInfo () {
      try {
        let items = []
        let addons = []
        for (const plan of this['order/plans']) {
          items.push({
            plan_id: plan.planId,
            plan_title: plan.planTitle,
            room_id: plan.roomId,
            room_name: plan.roomName,
            qty: plan.qty
          })
        }
        for (const addon of this['order/addons']) {
          for (const item of addon.items) {
            addons.push({
              id: addon.id,
              name: addon.name,
              date: this.$dayjs(item.date).format('YYYY-MM-DD'),
              session: item.session,
              qty: item.qty
            })
          }
        }
        const products = [
          ...this['order/plans'].map(item => {
            return {
              id: `ROOM${item.roomId}/PLAN${item.planId}`,
              quantity: Number(item.qty)
            }
          }),
          ...this['order/addons'].map(addon => {
            return {
              id: addon.id,
              quantity: Number(addon.qty)
            }
          })
        ]

        const plansTotalPrice = _.sumBy(this['order/plans'], function (plan) {
          return Number(plan.avgPrice) * plan.qty
        })

        const addonsTotalPrice = _.sumBy(this['order/addons'], addon => {
          return _.sumBy(addon.items, item => {
            return Number(item.price) * item.qty
          })
        })
        window.fbq('track', 'AddPaymentInfo', {
          content_category: 'product',
          content_ids: products.map(product => product.id),
          contents: products,
          currency: this['hotel/hotel'].currency,
          value: plansTotalPrice + addonsTotalPrice
        })
      } catch (err) {
        console.debug('FB PIXEL PROCESS ERROR: ', err)
      }
    },
    showTotalPeopleNotification () {
      const template = `<div class="whitespace-pre-wrap"><div>${this.$t('peopleNotEnoughWarning[0]')} <span class="text-red font-bold">${this['order/maxPeopleInTotal']}</span> ${this.$t('peopleNotEnoughWarning[1]')}</div><small class="text-red">${this.$t('peopleNotEnoughWarning[2]')}<small></div>`
      const buttonList = [
        {
          title: this.$t('close')
        }
      ]
      if (!this['hotel/hotelDisplaySetting'].isBlockWithNumberNotMatch) { // 設定為不阻擋才提供繼續按鈕
        buttonList.push({
          title: this.$t('continued'),
          handler: () => {
            this.$modal.hide('warninig-modal')
            this.goAddon()
          }
        })
      }
      this.$modal.show(contentWithCover, {
        title: this.$t('remind'),
        type: 'warning',
        icon: 'owl-alert',
        isScroll: false,
        context: template,
        buttons: buttonList
      },
      {
        adaptive: true,
        name: 'warninig-modal',
        clickToClose: false,
        height: 'auto',
        width: '100%',
        maxWidth: 450,
        scrollable: true
      })
    }
  },
  watch: {
    hasPlansItems: {
      handler: function (val, oldVal) {
        if (!!oldVal && !val && this.$route.name === 'Addon') {
          this['order/CLEAR_ORDERS']()
          if (!this.isSuper) {
            this.$router.replace({ name: 'Hotel' })
          }
        }
      }
    },
    orderCount: {
      handler: function (val, oldVal) {
        this.isAdding = false
        this.isMinus = false
        if (val > oldVal && val > 1) {
          this.addAnimation()
        } else if (val < oldVal && val > 0) {
          this.minusAnimation()
        }
      }
    }
  }
}
</script>

<style lang='sass' scope>
.order-total
  z-index: 9
  // top: 120px
  &__desktop
    &__body
      @apply overflow-y-scroll
      -webkit-overflow-scrolling: touch
      // max-height: 400px
    &__footer
      &__row
        @apply flex justify-between
        &:not(:last-of-type)
          @apply mb-16

  &__mobile
    @apply fixed pin-b pin-l bg-white w-full z-10
    box-shadow: 0 -15px 30px -10px rgba(92, 101, 107, 0.15)
    transform-orign: center bottom
    &__btn-group
      .btn-solid
        width: 170px
  .isAdding
    animation: isAdding .3s ease
  .isMinus
    animation: isMinus .3s ease
@keyframes isAdding
  0%
    transform: scale(1)
  50%
    transform: scale(1.05)
  100%
    transform: scale(1)
@keyframes isMinus
  0%
    transform: scale(1)
  50%
    transform: scale(0.95)
  100%
    transform: scale(1)
</style>
