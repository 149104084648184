var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hotel-content" }, [
    _c(
      "div",
      { staticClass: "room-types", attrs: { id: "anchor-rooms" } },
      [
        this["hotel/isLoadingRooms"]
          ? _c("div", { staticClass: "loading-zone" }, [_c("loading")], 1)
          : _vm._e(),
        _vm._l(_vm.filterRooms, function(room) {
          return [
            !room.is_whole_building
              ? _c(
                  "RoomCard",
                  _vm._g(
                    { key: room.id, attrs: { room: room } },
                    _vm.$listeners
                  )
                )
              : _c(
                  "div",
                  {
                    key: "building-" + room.id,
                    staticClass: "book-building mb-40 md:mb-80",
                    attrs: { id: "anchor-building" }
                  },
                  [
                    _c(
                      "BuildingCard",
                      _vm._g(
                        { attrs: { building: _vm.building } },
                        _vm.$listeners
                      )
                    )
                  ],
                  1
                )
          ]
        })
      ],
      2
    ),
    !_vm.filterRooms.length
      ? _c(
          "div",
          {
            staticClass:
              "p-24 mb-32 flex flex-col items-center rounded-sm border-1 border-grey-lighter",
            attrs: { id: "no-room-available" }
          },
          [
            _c("img", {
              staticClass: "mb-32",
              attrs: {
                src: require("@/assets/img/hotel-img-grey.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "empty-notice flex items-center" }, [
              _c("i", {
                staticClass: "owl-status-triangle-warning text-orange text-2xl"
              }),
              _c(
                "p",
                { staticClass: "text-2xl font-medium text-grey-darkest" },
                [_vm._v(_vm._s(_vm.$t("noRoom")))]
              )
            ])
          ]
        )
      : _vm._e(),
    _c("div", { attrs: { id: "anchor-intro" } }, [
      _vm.hasHotelFeatures
        ? _c(
            "div",
            { staticClass: "hotel-intro mb-40 md:mb-80" },
            [
              _c(
                "h4",
                { staticClass: "text-grey-darker font-medium text-xl" },
                [_vm._v(_vm._s(_vm.$t("hotelFeatures")))]
              ),
              _vm._l(this["hotel/hotel"].hotel_features, function(value, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass:
                      "py-24 px-0 mb-24 border-b-1 border-grey-lighter"
                  },
                  [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "text-grey-darker font-semibold text-base mb-12"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("hotelFeatures_" + key)) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      [
                        _vm._l(value, function(feature) {
                          return [
                            !feature.is_charge
                              ? _c(
                                  "div",
                                  {
                                    key: feature.text,
                                    staticClass: "w-1/4 my-8"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-start pr-8" },
                                      [
                                        feature.icon_url
                                          ? _c("img", {
                                              staticClass: "feature__icon",
                                              attrs: { src: feature.icon_url }
                                            })
                                          : feature.name
                                          ? _c("i", {
                                              staticClass:
                                                "be-icon feature__icon",
                                              class: feature.name
                                            })
                                          : _vm._e(),
                                        _c(
                                          "h6",
                                          { staticClass: "text-sm ml-8" },
                                          [_vm._v(_vm._s(feature.text))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              }),
              this["hotel/hotel"].chargeFeatures.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "py-24 px-0 mb-24 border-b-1 border-grey-lighter"
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "text-orange-dark text-base font-semibold mb-12"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("hotelFeaturesExtraCharge")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        _vm._l(this["hotel/hotel"].chargeFeatures, function(
                          feature
                        ) {
                          return _c(
                            "div",
                            { key: feature.text, staticClass: "w-1/4 my-8" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-start pr-8" },
                                [
                                  feature.icon_url
                                    ? _c("img", {
                                        staticClass: "feature__icon-charge",
                                        attrs: { src: feature.icon_url }
                                      })
                                    : feature.name
                                    ? _c("i", {
                                        staticClass:
                                          "be-icon feature__icon-charge",
                                        class: feature.name
                                      })
                                    : _vm._e(),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-sm ml-8 text-orange-dark"
                                    },
                                    [_vm._v(_vm._s(feature.text))]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isDescription
        ? _c("div", { staticClass: "hotel-intro mb-40 md:mb-80" }, [
            _c("h4", { staticClass: "mb-16 text-grey-darkest" }, [
              _vm._v(_vm._s(_vm.$t("hotelInfo")))
            ]),
            _c(
              "p",
              {
                staticClass:
                  "leading-normal text-grey-darkest text-sm whitespace-pre-line word-break-word"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(this["hotel/hotel"].description) +
                    "\n      "
                )
              ]
            )
          ])
        : _vm._e(),
      this["hotel/hotel"].contact && _vm.showContactBlock
        ? _c("div", { staticClass: "hotel-contact mb-40 md:mb-80" }, [
            _c("h4", { staticClass: "mb-16 text-grey-darkest" }, [
              _vm._v(_vm._s(_vm.$t("contactInfo")))
            ]),
            this["hotel/hotel"].contact.phone &&
            this["hotel/hotelDisplaySetting"].showPhone
              ? _c(
                  "div",
                  {
                    staticClass:
                      "hotel-contact__row text-grey-darkest text-sm mb-16"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "tel:" + this["hotel/hotel"].contact.phone
                        }
                      },
                      [
                        _c("i", { staticClass: "owl-phone mr-8" }),
                        _c("span", [
                          _vm._v(_vm._s(this["hotel/hotel"].contact.phone))
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            this["hotel/hotel"].contact.fax
              ? _c(
                  "div",
                  {
                    staticClass:
                      "hotel-contact__row text-grey-darkest text-sm mb-16"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "tel:" + this["hotel/hotel"].contact.fax
                        }
                      },
                      [
                        _c("i", { staticClass: "owl-inbox mr-8" }),
                        _c("span", [
                          _vm._v(_vm._s(this["hotel/hotel"].contact.fax))
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            this["hotel/hotel"].contact.email &&
            this["hotel/hotelDisplaySetting"].showEmail
              ? _c(
                  "div",
                  {
                    staticClass:
                      "hotel-contact__row text-grey-darkest text-sm mb-16"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:" + this["hotel/hotel"].contact.email
                        }
                      },
                      [
                        _c("i", { staticClass: "owl-format-mail mr-8" }),
                        _c("span", [
                          _vm._v(_vm._s(this["hotel/hotel"].contact.email))
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            this["hotel/hotelWebSiteUrl"] &&
            this["hotel/hotelDisplaySetting"].showLink
              ? _c(
                  "div",
                  {
                    staticClass: "hotel-contact__row text-grey-darkest text-sm"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: this["hotel/hotelWebSiteUrl"],
                          target: "_blank"
                        }
                      },
                      [
                        _c("i", { staticClass: "owl-earth mr-8" }),
                        _c("span", [
                          _vm._v(
                            _vm._s(this["hotel/hotel"].name) +
                              " " +
                              _vm._s(_vm.$t("website"))
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      this["hotel/hotel"].custom_reference.length > 0
        ? _c(
            "div",
            { staticClass: "hotel-contact mb-40 md:mb-80" },
            [
              _c("h4", { staticClass: "mb-16 text-grey-darkest" }, [
                _vm._v(_vm._s(_vm.$t("customReference")))
              ]),
              _vm._l(this["hotel/hotel"].custom_reference, function(item) {
                return _c(
                  "div",
                  {
                    key: "custom_reference__" + item.url,
                    staticClass:
                      "hotel-contact__row text-grey-darkest text-sm mb-16"
                  },
                  [
                    _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                      _c("i", { staticClass: "owl-link-intact mr-8" }),
                      _vm._v("\n          " + _vm._s(item.title) + "\n        ")
                    ])
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "hotel-location mb-40 md:mb-80" }, [
        _c("h4", { staticClass: "mb-16 text-grey-darkest" }, [
          _vm._v(_vm._s(_vm.$t("location")))
        ]),
        _c(
          "div",
          { staticClass: "hotel-location__row text-grey-darkest text-sm mb-8" },
          [
            _c("i", { staticClass: "owl-location mr-8" }),
            _c("span", [_vm._v(_vm._s(this["hotel/hotel"].address))])
          ]
        ),
        _c("div", { staticClass: "hotel-location__map" }, [
          this["hotel/hotelLocatedBy"]
            ? _c("iframe", {
                staticStyle: { border: "0" },
                attrs: {
                  src:
                    "https://www.google.com/maps/embed/v1/place?q=" +
                    encodeURI(this["hotel/hotelLocatedBy"]) +
                    "&key=" +
                    _vm.googleMapKey,
                  width: "100%",
                  height: "320",
                  frameborder: "0",
                  allowfullscreen: ""
                }
              })
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }