var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "wrapper__pagination" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.currentPage) +
          "/" +
          _vm._s(_vm.totelPage) +
          "\n  "
      )
    ]),
    _c(
      "div",
      {
        staticClass: "wrapper__btn-block wrapper__btn-block--next",
        on: {
          click: function($event) {
            _vm.hasNext && _vm.scrollByClick("next")
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "my-auto wrapper__btn",
            class: { "wrapper__btn--disabled": !_vm.hasNext }
          },
          [_c("i", { staticClass: "owl-direction-right" })]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "wrapper__btn-block wrapper__btn-block--prev",
        on: {
          click: function($event) {
            _vm.hasPrev && _vm.scrollByClick("prev")
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "my-auto wrapper__btn",
            class: { "wrapper__btn--disabled": !_vm.hasPrev }
          },
          [_c("i", { staticClass: "owl-direction-left" })]
        )
      ]
    ),
    _c(
      "div",
      {
        ref: "swiperContainer",
        staticClass: "image-swiper",
        on: { scroll: _vm.handleScroll }
      },
      _vm._l(_vm.photos, function(image, index) {
        return _c("div", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: image + "?width=" + _vm.thumbnailWidth,
              expression: "`${image}?width=${thumbnailWidth}`",
              arg: "background-image"
            }
          ],
          key: image,
          staticClass: "image-swiper__item",
          on: {
            click: function($event) {
              return _vm.handleClick(index)
            }
          }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }