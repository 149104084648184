<template>
  <nav class="hotelNav">
    <div class="left">
      <router-link :to="{name: 'Hotel'}">
        <h1 class="py-8 text-2xl text-grey-darkest line-ellipsis">
          {{ this['hotel/hotel'].name }}
        </h1>
      </router-link>
    </div>
    <div class="right">
      <div class="nav-dropdown language">
        <div class="nav-dropdown__display">
          <i class="owl-earth"></i>
          <template v-if="!isMobile">
            {{ displayLanguage }}
          </template>
        </div>
        <div class="nav-dropdown__list">
          <li v-for="lang in langs" :class="{'actived': selectedLanguage === lang.code}" :key="lang.code" @click="onSelectLang(lang.code)">{{ lang.displayName }}</li>
        </div>
      </div>
      <div
        class="nav-dropdown currency"
        v-if="$route.name !== 'Complete'"
      >
        <div class="nav-dropdown__display">
          <i class="owl-symbol-money-usd"></i>
            <span v-show="this['hotel/hotel'].name">
              <template v-if="!isMobile">
                {{ selectedCurrency }}
              </template>
            </span>
        </div>
        <div class="nav-dropdown__list">
          <template v-for="currency in currencySelectable">
            <li class="text-sm" :class="{'actived': isSelectedCurrency(currency)}" :key="currency.code" @click="onSelectCurrency(currency.code)">{{ currency.code }}</li>
          </template>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { setupCalendar } from 'v-calendar'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'hotelNav',
  data () {
    return {
      langs: [
        {
          code: 'en',
          displayName: 'English'
        },
        {
          code: 'zh_TW',
          displayName: '繁體中文'
        },
        {
          code: 'ja',
          displayName: '日本語'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'selectedLanguage',
      'selectedCurrency',
      'currencySelectable',
      'hotel/hotel',
      'hotelId',
      'windowWidth'
    ]),
    displayLanguage: function () {
      const selected = _.snakeCase(this.selectedLanguage)
      const matched = _.find(this.langs, lang => _.snakeCase(lang.code) === selected)
      return matched.displayName
    },
    isMobile () {
      return this.windowWidth < 768
    }
  },
  methods: {
    ...mapActions([
      'hotel/fetchRooms',
      'addon/fetchExperiences'
    ]),
    ...mapMutations([
      'SET_LANGUAGE',
      'SET_CURRENCY'
    ]),
    isSelectedCurrency: function (currency) {
      return currency.code === this.selectedCurrency
    },
    onSelectLang: function (langCode) {
      this['SET_LANGUAGE'](langCode)
      setupCalendar({
        firstDayOfWeek: 2,
        locale: langCode
      })
      if (this.$route.name === 'Hotel' || this.$route.name === 'Addon' || this.$route.name === 'Payment') {
        this.$router.replace({
          name: 'Hotel',
          params: {
            hotelId: this.hotelId
          },
          query: {
            ...this.$route.query,
            lang: langCode
          }
        })
        window.location.reload()
      }
    },
    onSelectCurrency: function (currency) {
      this['SET_CURRENCY'](currency)
      this.setGtagCurrency(currency)
    },
    setGtagCurrency: function (currency) {
      this.$gtag.set({
        currency
      })
    }
  },
  mounted () {
    this.setGtagCurrency(this.selectedCurrency)
  }
}
</script>

<style lang="sass">
nav.hotelNav
  @apply shadow py-8 px-8 flex justify-between items-center items-start
  @screen md
    @apply px-64
  a
    @apply text-grey inline-block px-8 no-underline
  .left, .right
    @apply flex items-center w-auto
  .right
    @apply justify-end
    @screen md
      @apply w-auto
  .nav-dropdown
    @apply text-grey-darkest py-24 px-8
    @screen md
      @apply px-24
    // &__display
    //   i
    &__list
      right: 0
  .line-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
</style>
