<template>
  <div
    id='hotel-sub-nav'
    class='sticky shadow-lg mb-40 py-16'>
    <div class="container flex flex-col lg:flex-row">
      <div class="hotel-sub-nav__selections__desktop md:flex-row items-center text-grey-dark mb-24 lg:mb-0 mr-0 lg:mr-16">
        <div class="mb-16 md:mb-0 col-12 col-md-7">
          <roomDateSelector
            :classic="!showFilterDatePicker"
            :date-range="dateRange"
            :dates.sync="totalDays"
            :hotel-rooms-type="hotelRoomsType"
            :visible.sync="roomDateSelectorVisible"
            @submit="roomDateSelectorSubmitHandler"
          >
          </roomDateSelector>
        </div>
        <div class="flex-split col-auto d-none d-lg-flex"></div>
        <div class="customer-select mb-16 md:mb-0 col-12 col-md-auto">
          <popperMenu :visible.sync="customerSelectVisible" :class="{ 'active': customerSelectVisible }">
            <template slot="label">
              <label class="customer-select-label block mb-8"><i class="owl-user-group"></i> {{ $t('unit.people') }}</label>
              <div  class="customer-select-value">
                <template v-if="showPlaceHolder">
                  <span class="text-orange-dark">{{ $tc('customerNumberPlaceholder') }}</span>
                </template>
                <template v-else>
                  <span>{{ people.adult }} {{ $tc('adult', people.adult) }}</span>
                  <span v-if="this['hotel/hotelReceptionConfig'].child.isReceive && people.child">, {{ people.child }} {{ $tc('child', people.child) }}</span>
                  <span v-if="this['hotel/hotelReceptionConfig'].infant.isReceive && people.infant">, {{ people.infant }} {{ $tc('infant', people.infant) }}</span>
                </template>
              </div>
            </template>
            <template slot="dropdown">
              <div class="flex items-center py-4 px-8 mb-4">
                <div class="w-2/5 text-grey-dark flex flex-col">
                  <label class='text-grey-darkest mb-8'>{{ $tc('adult', people.adult) }}</label>
                  <small>{{ $t('aboveNYearsOld', { age: this['hotel/hotelReceptionConfig'].adult.minAge }) }}</small>
                </div>
                <vue-numeric-input
                  class="flex-grow custom-numeric-input"
                  v-model="people.adult"
                  :min="1"
                  :max="50"
                  :step="1"
                />
              </div>
              <div class="flex items-center py-4 px-8 mb-4" v-if="this['hotel/hotelReceptionConfig'].child.isReceive">
                <div class="w-2/5 text-grey-dark flex flex-col">
                  <label class='text-grey-darkest mb-8'>{{ $tc('child', people.child) }}</label>
                  <small>{{ this['hotel/hotelReceptionConfig'].child.minAge }}-{{ this['hotel/hotelReceptionConfig'].child.maxAge }} {{ $t('yearsOld') }}</small>
                </div>
                <vue-numeric-input
                  class="flex-grow custom-numeric-input"
                  v-model="people.child"
                  :min="0"
                  :max="10"
                  :step="1"
                />
              </div>
              <div class="flex items-center py-4 px-8" v-if="this['hotel/hotelReceptionConfig'].infant.isReceive">
                <div class="w-2/5 text-grey-dark flex flex-col">
                  <label class='text-grey-darkest mb-8'>{{ $tc('infant', people.infant) }}</label>
                  <!-- <small>{{ this['hotel/hotelReceptionConfig'].infant.minAge }}-{{ this['hotel/hotelReceptionConfig'].infant.maxAge }} {{ $t('underYearsOld') }}</small> -->
                  <small>
                    {{ $t(
                      'underNYearsOld',
                      { age: `${this['hotel/hotelReceptionConfig'].infant.minAge }-${ this['hotel/hotelReceptionConfig'].infant.maxAge}` }
                    )}}
                  </small>
                </div>
                <vue-numeric-input
                  class="flex-grow custom-numeric-input"
                  v-model="people.infant"
                  :min="0"
                  :max="10"
                  :step="1"
                />
              </div>

              <div class="toolbar">
                <div class="close-button">
                  <button class="btn btn-outline-grey" @click="customerSelectCloseHandler">
                    {{ $t('close') }}
                  </button>
                </div>
                <div class="submit-button">
                  <button class="btn btn-primary" @click="customerSelectCloseHandler">
                    {{ $t('confirm') }}
                  </button>
                </div>
              </div>
            </template>
          </popperMenu>
        </div>
      </div>
      <scrollactive
        :offset="120"
        :modifyUrl="false"
        :highlightFirstItem="true"
        class="hotel-sub-nav__selections__right flex items-center"
        @itemchanged="scrollactiveItemChange">
        <!-- scrollactive tag="div" -->
        <div class="hotel-sub-nav__anchors pr-0 lg:pl-12">
          <a
            v-if='hasRooms'
            href="#anchor-rooms" class="scrollactive-item" active-class="active">{{ $t('roomType') }}</a>
          <a href="#anchor-intro" class="scrollactive-item" active-class="active">{{ $t('hotelInfo') }}</a>
          <a
            v-if='hasBuilding'
            href="#anchor-building" class="building-anchor scrollactive-item" active-class="active">{{ $t('allBuilding') }}</a>
        </div>
      </scrollactive>
      <div
        v-show='windowWidth < 768'
        class="hotel-sub-nav__selections__mobile items-center">
        <div class="hotel-sub-nav__selections__mobile__date">
          <span>{{ this['search/dateRange'].start | dayFormat(dateFormatWithWidth) }}</span>
          <span> - </span>
          <span>{{ this['search/dateRange'].end | dayFormat(dateFormatWithWidth) }}</span>
        </div>
        <span v-if="showPlaceHolder" class="text-orange-dark ml-8">
          {{ $tc('customerNumberPlaceholder') }}
        </span>
        <span v-else class="ml-16">
          <span>{{ people.adult }} {{ $tc('adult', people.adult) }}</span>
          <span v-if="this['hotel/hotelReceptionConfig'].child.isReceive && people.child > 0">, {{ people.child }} {{ $tc('child', people.child) }}</span>
          <span v-if="this['hotel/hotelReceptionConfig'].infant.isReceive && people.infant > 0">, {{ people.infant }} {{ $tc('infant', people.infant) }}</span>
        </span>
      </div>
      <!-- <div>
        <multiSelect v-model="selected"
          :options="options"
          :allow-empty="false"
          :show-labels="false"
          placeholder="輸入地點">
          <template slot="noResult">
            <span class="text-grey font-normal text-sm">查無地點</span>
          </template>
          <template slot="placeholder">
            <i class="owl-search text-grey mr-4" />
            <span class="text-md">選擇地點</span>
          </template>
          <template slot="singleLabel" slot-scope="props">
            <i class="owl-search text-grey mr-4" />
            <span class="text-md">{{props.option}}</span>
          </template>
        </multiSelect>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import owlsight from 'owlsight/src/tailwind.js'
import roomDateSelector from './roomDateSelector'
import popperMenu from '@/components/popperMenu'

// import SmoothScroll from 'smooth-scroll'
import _ from 'lodash'

export default {
  name: 'hotel-sub-nav',
  components: {
    popperMenu,
    roomDateSelector
  },
  data () {
    return {
      contextOptions: {},
      fromPage: null,
      owlsight: owlsight,
      dateRange: {
        start: Date.now(),
        end: this.$dayjs().add(1, 'days')
      },
      people: {
        adult: Number(this.$route?.query?.adult) || 1,
        child: Number(this.$route?.query?.child) || 0,
        infant: Number(this.$route?.query?.infant) || 0
      },
      tempMaxReachMonth: this.$dayjs().add(12, 'month'),
      customerSelectVisible: false,
      dateRoomPickerVisible: false,
      totalDays: '',
      roomDateSelectorVisible: false,
      isMobile: false
    }
  },
  created () {
    const dateValidRule = /^\d{4}-\d{1,2}-\d{1,2}$/ // yyyy-mm-dd
    const todayByHotelTimeZone = this.$store.state.hotel.todayByHotelTimeZone
    const start = this.$route.query.start && dateValidRule.test(this.$route.query.start)
      ? this.$dayjs(this.$route.query.start).toDate()
      : this.$dayjs(todayByHotelTimeZone).toDate()
    const end = this.$route.query.end && dateValidRule.test(this.$route.query.end)
      ? this.$dayjs(this.$route.query.end).toDate()
      : this.$dayjs(start).add(1, 'day').toDate()

    this.dateRange = {
      start,
      end
    }
    this['search/SET_DATE'](this.dateRange)
    // this['hotel/fetchRooms']()
  },
  mounted: function () {
    this.$nextTick(() => {
      const selectionsDesktop = document.querySelector('.hotel-sub-nav__selections__desktop')
      const selectionsMobile = document.querySelector('.hotel-sub-nav__selections__mobile')
      const _self = this

      function mobileFixedHandler (e) {
        if (_self.windowWidth < 768) {
          selectionsDesktop.getBoundingClientRect().bottom <= 0
            ? selectionsMobile.style.display = 'flex'
            : selectionsMobile.style.display = 'none'
        }
      }

      window.addEventListener('scroll', mobileFixedHandler)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', mobileFixedHandler)
      })

      // onresize
      const $this = this
      window.addEventListener('resize', function () {
        $this.onresize()
      })
      this.onresize()
    })
    /* eslint-enable */
  },
  computed: {
    ...mapGetters([
      'windowWidth',
      'search/dateRange',
      'search/roomId',
      'isTouchDevice',
      'hotel/rooms',
      'hotel/roomsType',
      'hotel/hotelReceptionConfig',
      'selectedLanguage',
      'hotel/hotelDisplaySetting',
      'hotel/hotelInternalPermissions'
    ]),
    hotelRoomsType () {
      return (this['hotel/roomsType'] || [])
    },
    hotelRooms () {
      return this['hotel/rooms']
    },
    hasBuilding: function () {
      const building = _.find(this.hotelRooms, room => room.is_whole_building === true)
      return !!building
    },
    hasRooms: function () {
      if (!_.isEmpty(this.hotelRooms)) {
        const rooms = this.hotelRooms.map(room => !room.is_whole_building)
        return rooms.length > 0
      }
      return false
    },
    calendarDescription () {
      return `
        #hotel-sub-nav .c-weeks:after {
          display: block;
          padding: 0 8px;
          content: "* ${this.$t('fullyBookedDescription')}";
          font-size: 12px;
        }
      `
    },
    showPlaceHolder () {
      return this['hotel/hotelDisplaySetting'].isForceSelectCustomerNumber
    },
    dateFormatWithWidth () {
      return this.windowWidth < 768 ? 'MM/DD' : 'YYYY/MM/DD'
    },
    roomId () {
      return this['search/roomId']
    },
    showFilterDatePicker () {
      const res = this['hotel/hotelInternalPermissions'].be_filter_date_picker
      return typeof res !== 'undefined' ? res : false
    }
  },
  methods: {
    ...mapActions([
      'hotel/setLoadingRoom',
      'hotel/fetchRooms',
      'hotel/fetchRoomDisabledDates'
    ]),
    ...mapMutations([
      'search/SET_DATE',
      'search/SET_PEOPLE',
      'search/SET_ROOMID',
      'hotel/SET_DISABLED_DATES',
      'SET_IS_FETCHED_ADDON',
      'hotel/CANCEL_FORCE_CUSTOMER_NUMBER'
    ]),
    scrollactiveItemChange (event, currentItem, lastActiveItem) {
      if (currentItem.hash === '#anchor-building') {
        this.clearRoomSelected()
      }
    },
    async roomDateSelectorSubmitHandler (data) {
      const { roomId, dates } = data
      this['search/SET_ROOMID'](+roomId)
      this['search/SET_DATE'](dates)

      this.$router.replace({
        query: {
          ...this.$route.query,
          start: this.$dayjs(dates.start).format('YYYY-MM-DD'),
          end: this.$dayjs(dates.end).format('YYYY-MM-DD')
        }
      })
      await this['hotel/fetchRooms']()
      this.resetAddonFetchedFlag()

      this.clearRoomSelected()

      await this.$nextTick()
      const selectedRoom = this.hotelRooms.find(c => c.id === +roomId)
      if (selectedRoom) {
        const key = selectedRoom.is_whole_building
          ? '.building-block'
          : `.room-card[data-room-id="${roomId}"]`
        this.selectedRoomActiveHandler(key)
      }
    },
    selectedRoomActiveHandler (key) {
      const el = document.querySelector(key)
      if (el) {
        el.dataset.beforeTitle = this.$t('selectedRoomType') // 你所選擇的房型
        el.dataset.afterTitle = this.$t('recommendRoomType') // 旅宿也推薦以下房型
        this.scrollToSmooth(key)
        document.querySelector(key).classList.add('active')
        delete this.selectedRoomActiveHandler_count
      } else {
        if (!this.selectedRoomActiveHandler_count) {
          this.selectedRoomActiveHandler_count = 1
        }
        if (this.selectedRoomActiveHandler_count < 10) {
          setTimeout(() => {
            this.selectedRoomActiveHandler_count++
            this.selectedRoomActiveHandler(key)
          }, 300)
        }
      }
    },
    clearRoomSelected () {
      const els = document.querySelectorAll('.room-card[data-room-id]')
      if (els) {
        els.forEach(v => {
          v.dataset.beforeTitle = null
          v.dataset.afterTitle = null
          v.classList.remove('active')
        })
      }
    },
    scrollToSmooth (selector, dy = 100) {
      const target = document.querySelector(selector)
      if (target) {
        window.scrollTo({ top: window.pageYOffset + target.getBoundingClientRect().y - dy, behavior: 'smooth' })
      }
    },
    onresize () {
      this.isMobile = document.body.clientWidth < 576
      this.bodyOverflowHandler(this.customerSelectVisible)
    },
    reFetchData: _.debounce(function () {
      this['hotel/SET_DISABLED_DATES'](null)
      if (this.fromPage) {
        const start = this.$dayjs(`${this.fromPage.year}-${this.fromPage.month}-01`).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        const end = this.$dayjs(start).add(12, 'month').endOf('month').format('YYYY-MM-DD')
        this['hotel/fetchRoomDisabledDates']({
          start,
          end
        })
      } else {
        this['hotel/fetchRoomDisabledDates']()
      }
      this['hotel/fetchRooms']()
    }, 300, { 'maxWait': 1000 }),
    handleCustomerSelectorOpen () {
      if (this['hotel/hotelDisplaySetting'].isForceSelectCustomerNumber) {
        setTimeout(() => {
          this.handleRemovePlaceHolder()
        }, 50)
      }
    },
    handleRemovePlaceHolder () {
      this['hotel/CANCEL_FORCE_CUSTOMER_NUMBER']()
    },
    appendCalendarDescriptionStyle () {
      const head = document.querySelector('head')
      const styleTag = document.createElement('style')
      styleTag.setAttribute('type', 'text/css')
      if (styleTag.styleSheet) { // IE
        styleTag.styleSheet.cssText = this.calendarDescription
      } else {
        styleTag.appendChild(document.createTextNode(this.calendarDescription))
      }
      head.appendChild(styleTag)
    },
    resetAddonFetchedFlag () {
      this['SET_IS_FETCHED_ADDON'](false)
    },
    customerSelectCloseHandler () {
      this.customerSelectVisible = false
    },
    bodyOverflowHandler (value) {
      if (value && this.isMobile) {
        document.body.classList.add('overflow-y-hidden')
      } else {
        document.body.classList.remove('overflow-y-hidden')
      }
    },
    hotelMainClassHandler (value) {
      const hotelMain = document.querySelector('.hotel-main.main')
      if (hotelMain) {
        if (value) {
          hotelMain.classList.add('show')
        } else {
          hotelMain.classList.remove('show')
        }
      }
    }
  },
  watch: {
    customerSelectVisible: {
      handler (value) {
        this.bodyOverflowHandler(value)
        if (value) {
          this.handleCustomerSelectorOpen()
        }
        this.hotelMainClassHandler(value)
      },
      deep: true,
      immediate: true
    },
    roomDateSelectorVisible: {
      handler (value) {
        this.bodyOverflowHandler(value)
        if (value && !this.isMobile) {
          this.scrollToSmooth('.hotel-main.main')
        }
        this.hotelMainClassHandler(value)
      },
      deep: true,
      immediate: true
    },
    fromPage: {
      handler: function (val, oldVal) {
        if (val.month === this.tempMaxReachMonth.month() - 2) {
          // const newStart = this.$dayjs(`${val.year}-${val.month}-01`).add(1, 'month').startOf('month').format('YYYY-MM-DD')
          // const newEnd = this.$dayjs(newStart).add(2, 'month').endOf('month').format('YYYY-MM-DD')
          const start = this.$dayjs(this.tempMaxReachMonth).startOf('month').format('YYYY-MM-DD')
          const end = this.$dayjs(start).add(12, 'month').endOf('month').format('YYYY-MM-DD')
          this.tempMaxReachMonth = this.$dayjs(end)
          this['hotel/fetchRoomDisabledDates']({
            start,
            end
          })
        }
      },
      deep: true
    },
    people: {
      handler: function (val, oldVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            adult: val.adult,
            child: this['hotel/hotelReceptionConfig'].child.isReceive ? val.child : 0,
            infant: this['hotel/hotelReceptionConfig'].infant.isReceive ? val.infant : 0
          }
        })
        this['search/SET_PEOPLE'](val)
        this.resetAddonFetchedFlag()
        this.reFetchData()
      },
      deep: true,
      immediate: true
    },
    roomId: {
      handler () {
        this.reFetchData()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
#hotel-sub-nav {
  @import '@/stylesheets/bs_func.scss';
  @import '@/stylesheets/middle.scss';

  $color-primary: #078abc;
  $color-secondary: #a3abb2;
  $color-dark: #3c4145;

  .hotel-sub-nav__selections__desktop {
    @include row();
    z-index: 3;
  }

  .hotel-sub-nav__selections__right {
    min-width: 20%;
  }

  .flex-split {
    border-right: 1px solid #E9EDEF;
    margin: 0 1em;
    height: 100%;
  }

  .customer-select {
    .customer-select-label {
      i {
        color:  $color-secondary;
      }

      &.active {
        i {
          color: #3c4145;
        }
      }
    }

    .customer-select-value {
      cursor: pointer;
      padding-left: 0.25em;
      font-weight: 700;
      color: $color-dark;
      border-radius: 8px;

      &:hover {
        color: $color-primary;
      }
    }

    .dropdown {
      padding: 0.5em;

      > .toolbar {
        display: none;
      }
    }
  }

  .overflow-y-hidden {
    overflow-y: hidden !important;
  }

  @media (max-width: 576px) {
    .hotel-sub-nav__selections__desktop {
      > div {
        &:not(:last-child) {
          border-right: 0;
        }
      }
    }

    .flex-split {
      display: none;
    }

    .customer-select {
      .component-popper-menu.active {
        .dropdown,
        .customer-select-value {
          border-radius: 0;
        }
      }
    }

    .customer-select {
      .component-popper-menu {
        .customer-select-label {
          display: none;
        }

        .customer-select-value {
          background-color: #f8fafc;
          padding: 0.75em 1em 0.75em 0.75em;
          font-weight: 500;

          &:before {
            content: "\E8AD";
            font-family: owl;
            font-size: 1.5em;
            padding-right: 0.25em;
            color: $color-secondary;
          }
        }

        .dropdown {
          position: fixed;
          top: 38px;
          left: 0;
          width: 100vw !important;
          height: 100vh;
          overflow-y: auto;
          flex-direction: column;
          padding: 1em;

          > .toolbar {
            display: flex;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 0 10px #eee;
            padding: 0.5em 0.5em 1em;
            text-align: center;

            > div {
              @include middle();
              width: 100%;
              padding: 0 .5em;

              button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang='sass' scoped>
  #hotel-sub-nav
    @apply bg-white z-10
    top: -183px
    @screen md
      top: -1px
    .hotel-sub-nav
      &__selections
        &__desktop
          @apply w-full
          .form-group, .form-group input
            @apply w-full
            @screen xl
              width: 220px
        &__mobile
          @apply text-base text-grey-darkest pt-16 px-16 border-t-1 border-grey-lighter
          display: none
      &__anchors
        width: auto
        @apply w-full
        @apply  flex items-center justify-around
        @screen md
          @apply justify-center
        @screen lg
          @apply justify-start flex-no-shrink
        @screen xl
          width: 400px
        a
          @apply py-8 text-grey-dark
          text-decoration: none
          &:not(.building-anchor)
            @apply border-b-2 border-white ease
            &:not(:last-of-type)
              @apply mr-32
            &.is-active
              @apply border-b-2 border-grey
          &.building-anchor
            @apply text-orange
            &.is-active
              @apply border-b-2 border-orange
            @screen md
              @apply border-1 border-orange px-16 rounded ease text-orange
              &:hover, &.is-active
                @apply bg-orange
                @apply text-white
  .input-color-fix
    @apply text-grey-darker #{!important}
</style>

<style lang='sass'>
  .custom-numeric-input
    .numeric-input
      user-select: none
</style>
