<template>
  <div class="pswp" ref="photoSwipeElement" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter"></div>
          <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
          <button class="pswp__button pswp__button--share" title="Share"></button>
          <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
          <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>
        <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>
        <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

export default {
  name: 'photoSwipe',
  props: {
    photos: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      gallery: '',
      options: {
        index: 0,
        shareEl: false,
        closeOnScroll: false,
        loop: true,
        history: false
        // showHideOpacity: true
      },
      newPhotos: []
    }
  },
  methods: {
    checkPhotos: function () {
      for (const photo in this.photos) {
        const url = this.photos[photo]
        const img = new Image()
        img.src = url
        img.onload = () => {
          this.newPhotos.push({
            pid: photo,
            src: url,
            w: img.width,
            h: img.height
          })
          this.newPhotos.sort((a, b) => {
            return a.pid - b.pid
          })
        }
      }
    },
    open: function (info) {
      if (typeof info !== 'undefined') {
        const { index, x, y, w } = info
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
        this.options.index = index
        this.options.getThumbBoundsFn = () => {
          return {
            x: x,
            y: y + pageYScroll,
            w: w
          }
        }
      }
      if (this.newPhotos && this.newPhotos.length > 0) {
        this.gallery = new PhotoSwipe(this.$refs.photoSwipeElement, PhotoSwipeUIDefault, this.newPhotos, this.options)
        this.gallery.init()
        this.gallery.listen('destroy', () => {
          this.gallery = ''
        })
        this.gallery.listen('close', () => {
          setTimeout(() => {
            this.$emit('close')
          }, 500)
        })
      }
    }
  },
  watch: {
    photos: {
      handler: function (val, oldVal) {
        this.newPhotos = []
        this.checkPhotos()
      },
      immediate: true
    }
  }
}
</script>

<style>
.pswp--touch .pswp__button--arrow--left,
.pswp--touch .pswp__button--arrow--right {
  visibility: visible !important;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}
</style>
