var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-right text-orange-dark text-sm" }, [
    _vm.hasCtaRestricted || _vm.hasCtdRestricted
      ? _c("p", { staticClass: "mt-4" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("planNotApplicable", { item: _vm.getCtaCtdDescription })
              ) +
              "\n  "
          )
        ])
      : _vm._e(),
    _vm.hasMinLosRestricted
      ? _c("p", { staticClass: "mt-4" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("needMinStayNumber", { minlos: _vm.getMinlos() })) +
              "\n  "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }