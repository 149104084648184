<template>
  <main class="not-found">
    <div class="container text-center">
      <h1 class="mb-16">Sorry, Hotel Page Not Found</h1>
      <figure>
        <img :src="require('@/assets/img/default-room-brandless.jpg')" alt="hotelNotFound">
      </figure>
      <span v-if="isOwlTingDomain" class='block text-center text-sm'>
        Powered by <a href="https://www.owlting.com/owlnest" class="text-blue font-bold no-underline">OwlNest, OwlTing Travel Service</a>
      </span>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isOwlTingDomain: 'isOwlTingDomain'
    })
  }
}
</script>

<style lang="sass" scope>
.not-found
  .container
    margin-top: min(20vh, 200px)
    h1
      font-size: 1.5rem
</style>
