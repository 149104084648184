const chatHotels = [
  {
    hotelId: 1,
    hotelName: 'owlting',
    chatId: '104631965712037',
    link: 'https://m.me/2481172775258088'
  },
  {
    hotelId: 593,
    hotelName: 'OwlStay-夾腳拖的家花園',
    chatId: '138672199479559',
    link: 'https://m.me/138672199479559'
  },
  {
    hotelId: 6009,
    hotelName: '沐玥星空露營區',
    link: 'https://m.me/283887188134034'
  }
]

export default chatHotels
