<template>
  <div class='order-addon px-16 pt-24 pb-16 font-normal border-grey-lighter relative'>
    <div class="order-addon__row mb-16 justify-between">
      <label class='text-xs text-orange font-medium block'>{{ $t('addAddons') }}</label>
      <!-- <i class="owl-status-error remove-plan"></i> -->
    </div>
    <h5 class='text-grey-darkest mb-8 font-medium'>{{ addonName }}</h5>
    <div
      v-for='(item, index) in addon.items'
      :key="addon.id + '-' + index"
      class="order-addon__row flex justify-between">
      <label class='text-grey-darkest mr-8'>{{ $dayjs(item.date).format('YYYY-MM-DD') }}</label>
      <span v-show="!isGoods(item)" class='text-grey-dark mr-auto'>{{ parseTime(item.session) }}</span>
      <span class='mr-24 flex-1 text-right'>x {{ item.qty }} <small>{{ item.unit }}</small></span>
      <span>{{ selectedCurrency }} {{ rowTotal(item) }}</span>
      <span>
        <button class="removeAddonBtn" @click="onRemoveAddon(index)"><i class="owl-trash"></i></button>
      </span>
    </div>
    <div class="order-addon__row justify-between mt-16">
      <label class='text-grey-dark'>{{ $t('total') }}</label>
      <span>
        {{ selectedCurrency }}
        <big class='text-blue font-bold'>{{ addonTotal | numeral }}</big>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
export default {
  name: 'order-addon',
  props: {
    addon: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'selectedCurrency',
      'addon/addons'
    ]),
    addonName: function () {
      const item = _.find(this['addon/addons'], addon => {
        return addon.id === this.addon.id
      })
      return this.$options.filters.contentParser(item.name)
    },
    addonTotal: function () {
      return _.sumBy(this.addon.items, item => {
        const afterCurrencyConvert = this.$options.filters.currency(item.price_without_fee, { inputCurrency: item.currency, isBonus: true })
        const resultToNumber = this.$numeral(afterCurrencyConvert).format('0.00')
        return resultToNumber * item.qty
      })
    }
  },
  methods: {
    ...mapMutations([
      'order/MINUS_ADDONS'
    ]),
    isGoods: function (item) {
      return item.type === 'hotel-goods'
    },
    rowTotal: function (item) {
      const afterCurrencyConvert = this.$options.filters.currency(item.price_without_fee, { inputCurrency: item.currency, isBonus: true })
      const resultToNumber = this.$numeral(afterCurrencyConvert).value()
      return this.$numeral(resultToNumber * item.qty).format('0,0[.]00')
    },
    onRemoveAddon: function (index) {
      this['order/MINUS_ADDONS']({
        id: this.addon.id,
        index: index
      })
    },
    parseTime: function (time) {
      const formated = this.$dayjs().format('YYYY/MM/DD ') + time
      let timeParse = this.$dayjs(formated).format('HH:mm')
      if (!time) {
        timeParse = this.$t('unlimitedTime')
      }
      return timeParse
    }
  }
}
</script>

<style lang='sass' scoped>
.remove-plan
  @apply text-grey-light cursor-pointer

.order-addon
  &:not(:last-child)
    @apply border-b-1
  &__row
    @apply text-sm flex items-start mb-8
    label
      @apply mr-8 leading-tight
    span
      @apply flex-no-shrink leading-tight
.removeAddonBtn
  @apply ml-8 text-grey-dark outline-none
  i
    @apply text-grey-light ease
  &:hover i
    @apply text-blue
</style>
