<template>
  <div class="modal__mobile-orders">
    <div class="modal__mobile-orders__head p-16 border-b-1 border-grey-lighter">
      <h2 class='text-grey-darker font-medium'>{{ $t('orderDetail') }}</h2>
      <span class='text-grey-dark'>{{ this['hotel/hotel'].name }}</span>
    </div>
    <div class="modal__mobile-orders__body">
      <OrderPlan
        v-for='plan in this["order/plans"]'
        :key='plan.id'
        :plan='plan'></OrderPlan>
      <OrderAddon
        v-for="addon in this['order/addons']"
        :key="addon.id"
        :addon="addon"></OrderAddon>

      <div
        v-show='hasPlan'
        class="modal__mobile-orders__body__total p-16">
        <template v-if="hasExtraTaxFeeConfig">
          <div class="order-total__desktop__footer__row text-sm text-grey-darker">
            <label>
              {{ $t('extraFeesCharge') }}
              <i class="owl-status-circle-info" v-tippy="{ html: '#extra-fees-list-modal', interactive : true, theme: 'light' }"></i>

              <div id="extra-fees-list-modal" class="hidden" v-tippy-html>
                <div v-for="fee in extraFees" :key="`fee-config-${fee.id}`" class="text-sm text-grey-darker flex">
                  <label>{{ fee.name }}: </label>
                  <span>{{ selectedCurrency }} {{ fee.price | currency }}</span>
                </div>
              </div>
            </label>
            <span>
              {{ selectedCurrency }} <big class="text-blue font-bold">{{ totalFee | currency }}</big>
            </span>
          </div>
        </template>
        <div class="modal__mobile-orders__body__total__row text-grey-darkest">
          <label class='text-grey-darkest'>
            {{ $t('total') }}
            <small v-if="!hasExtraTaxFeeConfig">({{ $t('room_price_has_tax_included') }})</small>
          </label>
          <span class='text-sm text-grey-darkest'>
            {{ selectedCurrency }} <big class='text-xl text-blue font-medium'>{{ this['order/totalPrice'] | currency }}</big>
          </span>
        </div>
        <template v-if="this['order/ifDepositRate']">
          <div class="modal__mobile-orders__body__total__row text-xs text-grey-dark">
            <label>{{ $t('depositTotal') }}</label>
            <span>{{ selectedCurrency }} {{ this['order/depositTotal'] | currency }}</span>
          </div>
          <div class="modal__mobile-orders__body__total__row text-xs text-grey-dark">
            <label>{{ $t('remainTotal') }}</label>
            <span>{{ selectedCurrency }} {{ this['order/remainTotal'] | currency }}</span>
          </div>
        </template>
      </div>
    </div>
    <div
      @click='$emit("close")'
      class="modal__mobile-orders__footer flex items-center justify-between">
      <div class="modal__mobile-orders__footer__order-info">
        <div class="modal__mobile-orders__footer__order-info__date text-sm">
          <i class="owl-calendar mr-8 text-grey text-xl"></i>
          <span>{{ this['search/dateRange'].start | dayFormat }}</span>
          <span> - </span>
          <span>{{ this['search/dateRange'].end | dayFormat }}</span>
        </div>
        <div class="modal__mobile-orders__footer__order-info__participants text-sm">
          <i class="owl-user-group mr-8 text-grey text-xl"></i>
          <span>{{ this['search/people'].adult }} {{ $tc('adult', this['search/people'].adult) }}, {{ this['search/people'].child }} {{ $tc('child', this['search/people'].child) }}, {{ this['search/people'].infant }} {{ $tc('infant', this['search/people'].infant) }}</span>
        </div>
      </div>
      <button class='btn btn-solid btn-grey-lighter py-8 px-16'>{{ $t('close') }}</button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import OrderPlan from '@/components/orderPlan'
import OrderAddon from '@/components/orderAddon'
import { mapGetters } from 'vuex'

export default {
  name: 'modal-mobile-orders',
  components: {
    OrderPlan,
    OrderAddon
  },
  computed: {
    ...mapGetters({
      hasExtraTaxFeeConfig: 'hotel/hasExtraTaxFeeConfig',
      extraFees: 'order/extraFees',
      totalFee: 'order/totalFee'
    }),
    ...mapGetters([
      'selectedCurrency',
      'hotel/hotel',
      'order/plans',
      'order/plansTotal',
      'order/addons',
      'search/dateRange',
      'search/people',
      'order/ifDepositRate',
      'order/totalPrice',
      'order/depositTotal',
      'order/remainTotal'
    ]),
    hasPlan: function () {
      return !_.isEmpty(this['order/plans']) || !_.isEmpty(this['order/addons'])
    }
  },
  methods: {
  }
}
</script>

<style lang='sass' scoped>
.modal__mobile-orders
  &__body
    height: calc(100vh - 165px)
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    &__total__row
      @apply flex justify-between
      &:not(:last-of-type)
        @apply mb-16
  &__footer
    @apply p-16 fixed pin-b pin-l bg-white w-full z-20
    box-shadow: 0 -15px 30px -10px rgba(92, 101, 107, 0.15)
</style>
