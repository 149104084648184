import request from '@/utils/request'

export default {
  fetchHotelDetail(hotelId, cancelToken) {
    return request({
      cancelToken: cancelToken.token,
      url: `/hotels/${hotelId}`,
      method: 'get'
    }).catch(err => {
      console.debug('CATCH', err)
    })
  },
  fetchHotelRoomsType(hotelId) {
    return request({
      url: `/hotels/${hotelId}/rooms/index`,
      method: 'get'
    }).catch(err => {
      console.debug('CATCH', err)
    })
  },
  fetchHotelRooms(hotelId, start, end, people, cancelToken) {
    return request({
      cancelToken: cancelToken.token,
      url: `/hotels/${hotelId}/rooms`,
      method: 'get',
      params: {
        during_date: `${start},${end}`,
        ...people
      }
    }).catch(err => {
      console.debug('CATCH', err)
    })
  },
  fetchRoomDisabledDates({ hotelId, start, end, people, cancelToken, roomId }) {
    return request({
      cancelToken: cancelToken.token,
      url: `/hotels/${hotelId}/disabled_calendar_dates`,
      method: 'get',
      params: {
        during_date: `${start},${end}`,
        room_id: roomId,
        ...people
      }
    })
  },
  fetchPaymentWays(hotelId, startDate, endDate, cancelToken) {
    return request({
      cancelToken: cancelToken.token,
      url: `/hotels/${hotelId}/payment_way`,
      method: 'get',
      params: {
        during_date: `${startDate},${endDate}`
      }
    })
  },
  fetchSonyPaymentPublicKey(uuid) {
    return request({
      url: `/hotels/${uuid}/sony_payment_pk`,
      method: 'post',
      auth: {
        username: uuid,
        password: 'Y9i1WFUJxM'
      }
    })
  },
  getHotelAnalytics(hotelUuid, cancelToken) {
    return request({
      cancelToken: cancelToken.token,
      url: `/hotels/${hotelUuid}/settings/analytics`,
      method: 'get'
    }).catch(err => {
      console.debug('CATCH', err)
    })
  }
}
