import axios from 'axios'
import ADDONS from '@/api/addons'
// initial state
const state = {
  isLoadingAddons: true,
  addons: [],
  cancelAddonsAPI: false
}

// getters
const getters = {
  addons: (state, getters, rootState) => {
    return state.addons
  },
  isLoadingAddons: (state, getters, rootState) => {
    return state.isLoadingAddons
  }
}

// actions
const actions = {
  fetchExperiences ({ commit, state, rootState }) {
    if (state.cancelAddonsAPI) {
      state.cancelAddonsAPI.cancel('Cancel addons fetching.')
    }
    commit('IS_LOADING_ADDON', true)
    const start = this._vm.$dayjs(rootState.search.dateRange.start).format('YYYY-MM-DD')
    const end = this._vm.$dayjs(rootState.search.dateRange.end).format('YYYY-MM-DD')
    const people = rootState.search.people
    return ADDONS.fetchExperiences(decodeURI(rootState.hotelId), start, end, people, state.cancelAddonsAPI)
  }
}

// mutations
const mutations = {
  IS_LOADING_ADDON (state, payload) {
    state.cancelAddonsAPI = axios.CancelToken.source()
    state.isLoadingAddons = payload
  },
  SET_ADDON (state, payload) {
    state.addons = payload
    //! 如果是體驗過來的行程且單位為 group => 把庫存都調整為 1 (忽略 max 值)
    for (const addon of state.addons) {
      if (!addon.is_hotel_self_addon && addon.unit_type === 'group') {
        for (const stock of addon.stocks) {
          for (const sessionStock of stock.sessions) {
            sessionStock.stock = 1
          }
        }
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
