<template>
  <div class="py-24 px-0 lg:px-16 border-b-1 border-grey-lighter">
    <div class='order-room-plan relative'>
      <div class="order-room-plan__head flex flex-row items-start mb-16">
        <div v-if="showFlexiblePrice" class="flexible-type os-tag os-tag-full os-tag-solid text-xs flex-no-shrink" :class="flexibleTagColor">{{ plan.flexible_title }}</div>
        <h5
          class='font-medium text-grey-darker mr-8'
          :class="{ 'cursor-pointer': isNeedCollapse}"
          @click="isNeedCollapse && toggleCollapse()"
        >
          <i
            v-if="isNeedCollapse"
            class="owl-caret-right"
            :class="{'rotate': !isCollapse}"
          >
          </i>
          {{ plan.title | contentParser }}
        </h5>
      </div>
      <div class="order-room-plan__body flex flex-col md:flex-row" :class="{'isCollapse': isCollapse && isNeedCollapse}">
        <div
          class="order-room-plan__content text-sm text-grey-darker leading-normal w-full md:w-3/5"
        >
          <p
            ref="plan-intro"
            class="whitespace-pre-wrap mb-32"
            :class="{'cursor-pointer': isCollapse && isNeedCollapse}"
            v-html="plan.intro"
            @click="isCollapse && toggleCollapse()"
          >
          </p>
          <label
            v-if="isCancelPolicy"
            @click="openCancelPolicy"
            class='text-grey-dark cursor-pointer'
          >
            <span>{{ $t('cancelPolicy') }}</span>
            <i class="owl-status-circle-info"></i>
          </label>
        </div>
      </div>
      <div class="card-bottom-block flex flex-col md:flex-row justify-end w-full md:w-auto">
        <div class="order-room-plan__amount w-full flex flex-col justify-between md:justify-end items-end">
          <div v-if="showFlexiblePrice && showPriceCross" class="mb-4 mr-4 flex flex-wrap">
            <div class="price--cross font-bold text-xl">{{ plan.avg_price_without_fee | currency }}</div>
            <small class="invisible"> / {{ $tc('night', 1) }}</small>
          </div>
          <div class="order-room-plan__amount__row mb-8 w-full flex justify-end md:justify-end items-center">
            <div class="per-price text-grey-dark">
              <small>{{ $t('about') }} </small>
              <small>{{ selectedCurrency }} </small>
              <span v-if="showFlexiblePrice" class="text-blue font-bold" :class="{ 'flexiblePriceActive': showPriceCross }">{{ priceFilterDecimals(plan.avg_price_without_fee) | currency }}</span>
              <span v-else class="text-blue font-bold">{{ priceFilterDecimals(plan.avg_price_without_fee) | currency }}</span>
              <small> / {{ $tc('night', 1) }}</small>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <slot name="buildingButton" v-if="isBuilding"></slot>
            <template v-else>
              <div
                class="flex-auto numberInputWidth"
                :class="{'cursor-not-allowed': hasRestrictedReasons || disabledInputByCustomerNumber}"
                @click="disabledInputByCustomerNumber && handleShowCustomerSelector()"
              >
                <vue-numeric-input v-model="number" :min="0" :max="remainStock" :step="1" :disabled="hasRestrictedReasons || disabledInputByCustomerNumber"/>
              </div>
              <div class="text-lg text-grey-dark ml-8 font-bold capitalize">{{ room.unit }}</div>
            </template>
          </div>
          <RestrictTip v-if="hasRestrictedReasons" :restrictedReasons="plan.restricted_reasons" />
          <div v-if="disabledInputByCustomerNumber" class="text-right text-orange-dark text-sm">{{ $t('blockWithCustomerNumber') }}</div>
        </div>
      </div>
    </div>
    <div class="toggle-block">
      <div class="flex">
        <Toggle
          class="ml-auto"
          v-if="showExtraReceptionSetting"
          :isChecked.sync="hasExtraReception"
          :inactiveLabel="$t('extraReception.applyExtraReception')"
        />
      </div>
      <template v-if="showExtraReceptionSetting && hasExtraReception">
        <ExtraRectptionForm
          v-for="(item, index) in number"
          :key="`extraRectptionForm_${index}`"
          :index="index + 1"
          :extraReceptionData.sync="extraReceptionData[index]"
          :adultsOption="adultsOption"
          :childrenOption="childrenOption"
          @update="updateReceptionData"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import contentWithCover from '@/components/modals/contentWithCover'
import RestrictTip from './restrictTip'
import Toggle from '@/components/toggle'
import ExtraRectptionForm from '@/components/extraRectptionForm.vue'
import _ from 'lodash'
import * as currencyConvert from '@/utils/currencyConvert'

export default {
  name: 'order-room-plan',
  props: {
    addedStock: {
      type: Number
    },
    plan: {
      type: Object
    },
    room: {
      required: true
    },
    stock: {
      type: Array
    },
    isBuilding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      number: 0,
      isCollapse: true,
      isNeedCollapse: true,
      hasExtraReception: false,
      extraReceptionData: []
    }
  },
  components: {
    RestrictTip,
    Toggle,
    ExtraRectptionForm
  },
  computed: {
    ...mapGetters([
      'order/plans',
      'selectedCurrency',
      'search/nights',
      'hotelCurrency',
      'hotel/hotelDisplaySetting',
      'hotel/hotel',
      'order/extraReceptionHotelLimitConfig',
      'order/extraReceptionPriceConfig',
      'order/extraReceptionAdultsRemaining',
      'order/extraReceptionChildrenRemaining'
    ]),
    showFlexiblePrice: function () {
      return this.plan.avg_flexible_price
    },
    showPriceCross: function () {
      return this.plan.avg_flexible_price < this.plan.avg_price_without_fee
    },
    flexibleTagColor: function () {
      switch (this.plan.flexible_type) {
        // early_bird 早鳥
        case 'early_bird':
          return 'os-tag-green'
        // inventory 庫存
        case 'inventory':
          return 'os-tag-red'
        // low_season 淡季
        case 'low_season':
          return 'os-tag-green'
        // peak_sesson 旺季
        case 'peak_sesson':
          return 'os-tag-grey'
        default:
          return 'os-tag-grey'
      }
    },
    isCancelPolicy: function () {
      return !_.isEmpty(this.plan.cancel_policy.content)
    },
    isRoomInOrder: function () {
      const isExist = _.find(this['order/plans'], plan => plan.roomId === this.room.id)
      return isExist
    },
    isPlanInOrder: function () {
      const isExist = _.find(this['order/plans'], plan => plan.uid === this.room.id + '-' + this.plan.id)
      return isExist
    },
    remainStock: function () {
      const sortArray = _.orderBy(this.stock, ['stock'])
      return _.minBy(sortArray, 'count').count - this.addedStock + this.number
    },
    hasRestrictedReasons: function () {
      return this.plan.restricted_reasons && this.plan.restricted_reasons.length > 0
    },
    disabledInputByCustomerNumber () {
      return this['hotel/hotelDisplaySetting'].isForceSelectCustomerNumber
    },
    introHeight () {
      return this.$refs['plan-intro'].clientHeight
    },
    isUseOwltingCancelPolicy () {
      return this['hotel/hotel']?.enable_owlting_cancel_policy
    },
    cancelPolicyContent () {
      return this.isUseOwltingCancelPolicy
        ? this.$t('owlTingCancelPolicy')
        : this.$options.filters.contentParser(this.plan.cancel_policy.content)
    },
    showExtraReceptionSetting () {
      return this.room?.receptions?.enabled && this.number > 0
    },
    adultsOption () {
      if (this.room?.receptions?.adult?.limit) {
        return [...Array(this.room.receptions.adult.limit + 1).keys()].map(i => {
          return {
            name: this.getExtraReceptionOptionName(i, 'adults'),
            value: i,
            $isDisabled: this['order/extraReceptionHotelLimitConfig']?.enabled.adults ? i > this['order/extraReceptionAdultsRemaining'] : false
          }
        })
      }
      return [
        {
          name: this.$t('extraReception.unrequired'),
          value: 0
        }
      ]
    },
    childrenOption () {
      if (this.room?.receptions?.child?.limit) {
        return [...Array(this.room.receptions.child.limit + 1).keys()].map(i => {
          return {
            name: this.getExtraReceptionOptionName(i, 'children'),
            value: i,
            $isDisabled: this['order/extraReceptionHotelLimitConfig']?.enabled.children ? i > this['order/extraReceptionChildrenRemaining'] : false
          }
        })
      }
      return [
        {
          name: this.$t('extraReception.unrequired'),
          value: 0
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'parseLang',
      'order/planCountChange',
      'order/updateReceptionData'
    ]),
    openCancelPolicy: function () {
      if (this.isCancelPolicy) {
        this.$modal.show(contentWithCover, {
          isFeatures: false,
          // img: _.isEmpty(this.room.images) ? '' : this.room.images[0],
          context: this.cancelPolicyContent
        },
        {
          adaptive: true,
          name: 'features-modal',
          height: 'auto',
          width: '100%',
          maxWidth: 450,
          scrollable: true
        })
      }
    },
    priceFilterDecimals: function (price) {
      const noDecimals = ['TWD', 'JPY']
      if (!noDecimals.includes(this.hotelCurrency)) return price

      const priceHasDecimal = price.toString().includes('.')
      return priceHasDecimal
        ? Math.round(price)
        : price
    },
    handleShowCustomerSelector () {
      this.$emit('showCustomerSelector')
    },
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    updateReceptionData (payload) {
      const updateData = {
        index: payload.index,
        type: payload.type,
        value: payload.value,
        planId: this.plan.id,
        roomId: this.room.id
      }
      this['order/updateReceptionData'](updateData)
    },
    getExtraReceptionOptionName (personCount, type) {
      if (personCount === 0) {
        // 不加人
        return this.$t('extraReception.unrequired')
      }
      // + 1人 (約 TWD 310 / 晚)
      return `+ ${personCount}${this.$t('person')} (${this.$t('about')} ${this['hotelCurrency']} ${currencyConvert.convert(this.priceFilterDecimals(this['order/extraReceptionPriceConfig'][type]) * personCount)} / ${this.$t('night')})`
    }
  },
  watch: {
    isPlanInOrder: {
      handler: function (val, oldVal) {
        if (!!oldVal && typeof val === 'undefined') {
          this.number = 0
        }
      }
    },
    number: {
      handler: function (val, oldVal) {
        const payload = {
          val: val,
          oldVal: oldVal,
          room: this.room,
          plan: this.plan
        }
        this.$emit('stockChange', this.plan.id, this.number)
        this['order/planCountChange'](payload)

        if (val > oldVal) {
          this.extraReceptionData.push({
            adults: 0,
            children: 0
          })
        } else {
          this.extraReceptionData.pop()
        }
      }
    }
  },
  mounted () {
    this.isNeedCollapse = this.introHeight > 60
    this.isCollapse = this['hotel/hotelDisplaySetting']?.isPlanCollapse || false
  }
}
</script>

<style lang='sass' scoped>
.numberInputWidth
  min-width: 150px
  @screen md
    max-width: 150px
.flexible-type
  @apply uppercase font-bold
  @apply ml-0 cursor-default #{!important}
.flexiblePriceActive
  @apply text-orange-dark font-bold text-xl
.card-bottom-block
  @apply absolute
  bottom: -6rem
  right: 0
  @screen sm
    bottom: 0
.order-room-plan__body
  margin-bottom: 7rem
  max-height: 1000rem
  transition: max-height 0.1s ease-out
  @screen md
    @apply mb-0
  &::after
    @apply block w-full absolute pointer-events-none
    content: ''
    bottom: 0
    height: 4.5rem
    background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%)
    opacity: 0
    transition: opacity 0.25s ease-out
.isCollapse
  @apply overflow-hidden relative
  max-height: 4.5rem
  &::after
    opacity: 1
.owl-caret-right
  @apply inline-block
  transform: rotate(0deg)
  transition: transform 0.25s ease-out
.rotate
  transform: rotate(90deg)
.toggle-block
  margin-top: 8px
</style>

<style lang='sass'>
.cursor-not-allowed
  .vue-numeric-input
    .btn
      background-color: red
      cursor: not-allowed !important
</style>
