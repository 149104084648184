import _ from 'lodash'
import dayjs from 'dayjs'
import ORDER from '@/api/order'
import { event } from 'vue-gtag'
import * as currencyConvert from '@/utils/currencyConvert'
import numeral from 'numeral'

// import USER from '@/api/user'

// initial state
const state = {
  plans: [],
  addons: [],
  customer: {},
  stripeCard: {},
  applePayToken: {},
  paymentResponse: {},
  refundForm: {},
  couponCode: '',
  previewResult: {},
  customerNumber: {},
  sonyPaymentToken: '',
  extraReceptionHotelLimitConfig: {
    limit: {
      children: 0,
      adults: 0
    },
    enabled: {
      children: false,
      adults: false
    }
  },
  extraReceptionPriceConfig: {
    children: 0,
    adults: 0
  },
  extraReceptionUsed: {
    adult: 0,
    child: 0
  }
}

// getters
const getters = {
  plans: (state, getters, rootState) => {
    return state.plans
  },
  addons: (state, getters, rootState) => {
    return state.addons
  },
  plansTotal: (state, getters, rootState, rootGetter) => {
    const hasPlans = !_.isEmpty(state.plans)
    const nights = rootGetter['search/nights']
    if (hasPlans) {
      const total = _.sumBy(state.plans, function (plan) {
        return plan.avgPrice * plan.qty
      })
      return total * nights
    } else {
      return 0
    }
  },
  plansTotalWithoutFee: (state, getters, rootState, rootGetter) => {
    const hasPlans = !_.isEmpty(state.plans)
    const nights = rootGetter['search/nights']
    if (hasPlans) {
      const total = _.sumBy(state.plans, function (plan) {
        return plan.avgPriceWithoutFee * plan.qty
      })
      return total * nights
    } else {
      return 0
    }
  },
  addonsTotal: (state, getters, rootState, rootGetters) => {
    const hasAddons = !_.isEmpty(state.addons)
    if (hasAddons) {
      const total = _.sumBy(state.addons, addon => {
        return _.sumBy(addon.items, item => {
          // return currencyConvert.convert(Number(item.price), { inputCurrency: item.currency, isBonus: true }) * item.qty
          const priceFormat = currencyConvert.convert(Number(item.price), { inputCurrency: item.currency, outputCurrency: rootGetters.hotelCurrency, isBonus: true })
          return ((Number(numeral(priceFormat).format('0[.]00')) * 100) / 100) * item.qty
        })
      })
      return total
    } else {
      return 0
    }
  },
  addonsTotalWithoutFee: (state, getters, rootState, rootGetters) => {
    const hasAddons = !_.isEmpty(state.addons)
    if (hasAddons) {
      const total = _.sumBy(state.addons, addon => {
        return _.sumBy(addon.items, item => {
          // return currencyConvert.convert(Number(item.price), { inputCurrency: item.currency, isBonus: true }) * item.qty
          const priceFormat = currencyConvert.convert(Number(item.price_without_fee), { inputCurrency: item.currency, outputCurrency: rootGetters.hotelCurrency, isBonus: true })
          return ((Number(numeral(priceFormat).format('0[.]0')) * 100) / 100) * item.qty
        })
      })
      return total
    } else {
      return 0
    }
  },
  ifDepositRate: (state, getters, rootState) => {
    return rootState.hotel.hotel.deposit_rate > -1
  },
  hotelDepositRate: (state, getters, rootState) => {
    if (getters.ifDepositRate) {
      return rootState.hotel.hotel.deposit_rate * 100
    } else {
      return 0
    }
  },
  totalPrice: (state, getters, rootState) => {
    return getters.plansTotal + getters.addonsTotal + getters.extraReceptionPriceTotal
  },
  totalFee: (state, getters, rootState) => {
    return getters.extraFees.reduce((total, fee) => {
      return total + fee.price
    }, 0)
  },
  depositTotal: (state, getters, rootState) => {
    // 不含後端計算結果，僅顯示於側邊價錢價算
    // 加人視做加購，訂金收全額
    return getters.plansTotal * rootState.hotel.hotel.deposit_rate + getters.addonsTotal + getters.extraReceptionPriceTotal
  },
  remainTotal: (state, getters, rootState) => {
    return getters.totalPrice - getters.depositTotal
  },
  paymentResponse: (state) => {
    return state.paymentResponse
  },
  orderSerial: (state) => {
    return state.paymentResponse.order_serial
  },
  maxPeopleInTotal: (state) => {
    const peopleMap = state.plans.map(plan => plan.maxPeople * plan.qty)
    return peopleMap.reduce((a, b) => a + b, 0)
  },
  applePayToken: (state) => state.applePayToken,
  refundForm: (state) => state.refundForm,
  hotelCurrency: (state, getters, rootState, rootGetters) => {
    return rootGetters.hotelCurrency
  },
  couponCode: (state) => state.couponCode,
  extraFees: (state) => {
    const planFeesConfig = state.plans.map(plan => {
      return plan.datePriceItems.map(item => {
        return { ...item, qty: plan.qty, type: 'plan' }
      })
    })
    const addonFeeConfig = state.addons.map(addon => {
      return addon.items.map(item => {
        return { fee_configs: item.feeConfig, qty: item.qty, type: 'addon' }
      })
    })
    const flatPlanFeesConfig = [].concat.apply([], planFeesConfig)
    const flatAddonFeesConfig = [].concat.apply([], addonFeeConfig)
    const flatFeesConfig = [...flatPlanFeesConfig, ...flatAddonFeesConfig]
    return flatFeesConfig.reduce((sum, item) => {
      item.fee_configs.forEach((feeConfig, index) => {
        const feeItem = sum.find(fee => fee.name === feeConfig.name)
        const additionalTotal = feeConfig.addition_total * item.qty
        if (feeItem) {
          feeItem.price += additionalTotal
        } else {
          sum.push({
            id: `${item.type}_${index}`,
            type: item.type,
            name: feeConfig.name,
            price: additionalTotal
          })
        }
      })
      return sum
    }, [])
  },
  extraReceptionHotelLimitConfig: (state) => state.extraReceptionHotelLimitConfig,
  extraReceptionPriceConfig: (state) => state.extraReceptionPriceConfig,
  extraReceptionPriceTotal: (state, getters, rootState, rootGetter) => {
    const planChildrenPriceTotal = getters.extraReceptionChildrenCount * getters.extraReceptionPriceConfig.children * rootGetter['search/nights']
    const planAdultsPriceTotal = getters.extraReceptionAdultsCount * getters.extraReceptionPriceConfig.adults * rootGetter['search/nights']
    return planChildrenPriceTotal + planAdultsPriceTotal
  },
  extraReceptionAdultsCount: (state) => state.plans.reduce((pv, cv) => {
    return pv + cv.extraReception.reduce((optionPv, optionCv) => {
      return optionPv + optionCv.adults
    }, 0)
  }, 0),
  extraReceptionChildrenCount: (state) => state.plans.reduce((pv, cv) => {
    return pv + cv.extraReception.reduce((optionPv, optionCv) => {
      return optionPv + optionCv.children
    }, 0)
  }, 0),
  extraReceptionAdultsRemaining: (state, getters) => {
    return getters.extraReceptionHotelLimitConfig.limit.adults - state.extraReceptionUsed.adult - getters.extraReceptionAdultsCount
  },
  extraReceptionChildrenRemaining: (state, getters) => {
    return getters.extraReceptionHotelLimitConfig.limit.children - state.extraReceptionUsed.child - getters.extraReceptionChildrenCount
  }
}

// actions
const actions = {
  createOrder ({ commit, getters, state, rootState, rootGetters }) {
    let items = []
    let addons = []
    let total = ''
    for (const plan of state.plans) {
      items.push({
        plan_id: plan.planId,
        plan_title: plan.planTitle,
        room_id: plan.roomId,
        room_name: plan.roomName,
        qty: plan.qty,
        extra_persons: plan.extraReception.filter(item => item.adults > 0 || item.children > 0)
      })
    }
    for (const addon of state.addons) {
      for (const item of addon.items) {
        addons.push({
          id: addon.id,
          name: addon.name,
          date: dayjs(item.date).format('YYYY-MM-DD'),
          session: item.session,
          qty: item.qty
        })
      }
    }
    const guest = {
      adult: Number(rootState.search.people.adult) || 1,
      children: Number(rootState.search.people.child) || 0,
      infant: Number(rootState.search.people.infant) || 0
    }
    if (getters.ifDepositRate) {
      total = state.previewResult.deposit_total
    } else {
      total = state.previewResult.new_total
    }
    const data = {
      during_start_at: dayjs(rootState.search.dateRange.start).format('YYYY-MM-DD'),
      during_end_at: dayjs(rootState.search.dateRange.end).format('YYYY-MM-DD'),
      items: items,
      addons: addons,
      total: total,
      coupon_code: state.couponCode,
      payment_type: state.customer.paymentWay,
      currency: rootState.selectedCurrency,
      order_requirements: state.customer.specific,
      language: rootState.selectedLanguage,
      guest: guest,
      customer: {
        firstname: state.customer.firstName,
        lastname: state.customer.lastName,
        country: state.customer.country,
        email: state.customer.email,
        phone: state.customer.phone,
        wechat_id: state.customer.wechatId,
        line_id: state.customer.lineId
      },
      card: {
        number: state.stripeCard.number,
        year: state.stripeCard.year,
        month: state.stripeCard.month,
        cvc: state.stripeCard.cvc
      },
      stripe_public_key: rootGetters['hotel/stripeKey']
    }

    if (state.sonyPaymentToken) {
      data.sony_payment_token = state.sonyPaymentToken
    }
    /* eslint-disable */
    if (data.payment_type === 'applepay') {
      data.payment_token = JSON.stringify(state.applePayToken.paymentData)
    }
    /* eslint-enable */

    // fbq: AddPaymentInfo
    const products = [
      ...items.map(item => {
        return {
          id: `ROOM${item.room_id}/PLAN${item.plan_id}`,
          quantity: Number(item.qty)
        }
      }),
      ...addons.map(addon => {
        return {
          id: addon.id,
          quantity: Number(addon.qty)
        }
      })
    ]

    const plansTotalPrice = _.sumBy(state.plans, function (plan) {
      return Number(plan.avgPrice) * plan.qty
    })

    const addonsTotalPrice = _.sumBy(state.addons, addon => {
      return _.sumBy(addon.items, item => {
        return Number(item.price) * item.qty
      })
    })

    window.fbq('track', 'Purchase', {
      content_ids: products.map(product => product.id),
      content_name: 'OwlNest Booking Complete',
      content_type: 'product_group',
      contents: products,
      currency: rootGetters.hotelCurrency,
      value: plansTotalPrice + addonsTotalPrice
    })

    return ORDER.submitOrder(rootState.hotel.hotel.hotel_uuid, data)
  },
  couponPreview ({ commit, getters, state, rootState, rootGetters }, couponCode) {
    let items = []
    let addons = []
    for (const plan of state.plans) {
      items.push({
        plan_id: plan.planId,
        plan_title: plan.planTitle,
        room_id: plan.roomId,
        room_name: plan.roomName,
        qty: plan.qty,
        extra_persons: plan.extraReception.filter(item => item.adults > 0 || item.children > 0)
      })
    }
    for (const addon of state.addons) {
      for (const item of addon.items) {
        addons.push({
          id: addon.id,
          name: addon.name,
          date: dayjs(item.date).format('YYYY-MM-DD'),
          session: item.session,
          qty: item.qty
        })
      }
    }
    const data = {
      during_start_at: dayjs(rootState.search.dateRange.start).format('YYYY-MM-DD'),
      during_end_at: dayjs(rootState.search.dateRange.end).format('YYYY-MM-DD'),
      coupon_code: couponCode,
      currency: rootState.selectedCurrency,
      guest: {
        adult: rootState.search.people.adult,
        children: rootState.search.people.child,
        infant: rootState.search.people.infant
      },
      items: items,
      addons: addons,
      is_flatten_coupon_valid: true
    }

    return ORDER.couponPreview(rootState.hotelId, data)
  },
  planCountChange ({ commit, getters }, payload) {
    const { val, oldVal } = payload
    // ADD
    if (val > oldVal) {
      commit('ADD_PLANS', { ...payload, hotelCurrency: getters.hotelCurrency })
    }
    // MINUS
    if (val < oldVal) {
      commit('MINUS_PLANS', payload)
    }
  },
  getCompletedOrder ({ commit, getters, state, rootState }, payload) {
    const hotelId = rootState.hotelId || payload.hotelId
    const orderSerial = state.paymentResponse.order_serial || payload.orderSerial

    return ORDER.getCompletedOrder(hotelId, orderSerial)
  },
  addBuilding ({ commit, getters, state }, payload) {
    if (!_.isEmpty(state.plans)) {
      commit('CLEAR_ORDERS')
      commit('ADD_BUILDING', { ...payload, hotelCurrency: getters.hotelCurrency })
    } else {
      commit('ADD_BUILDING', { ...payload, hotelCurrency: getters.hotelCurrency })
    }
  },
  checkQRcodePaymentStatus ({ commit, getters, state, rootState }) {
    return ORDER.checkQRcodePaymentStatus(state.paymentResponse.order_serial)
  },
  fetchBankList () {
    return ORDER.fetchBankList()
  },
  fetchSubBankList ({ commit }, payload) {
    return ORDER.fetchSubBankList(payload)
  },
  getCaptcha ({ commit, getters, state, rootState }) {
    return ORDER.getCaptcha()
  },
  updateReceptionData ({ commit, state }, payload) {
    const { index, planId, roomId } = payload
    const uid = `${roomId}-${planId}`
    const hasReceptionData = state.plans.find(plan => plan.uid === uid)?.extraReception[index]
    if (hasReceptionData) {
      commit('UPDATE_RECEPTION_DATA', payload)
    }
  }
}

// mutations
const mutations = {
  CLEAR_ORDERS (state) {
    state.plans = []
    state.addons = []
  },
  ADD_BUILDING (state, buildingData) {
    const plan = buildingData.building.plans.find(plan => plan.id === buildingData.planId)
    const planFee = plan.items.reduce((acc, item) => acc + item.fee, 0)
    const data = {
      isBuilding: true,
      uid: buildingData.building.id + '-' + plan.id,
      roomId: buildingData.building.id,
      planId: plan.id,
      roomName: buildingData.building.name,
      planTitle: plan.title,
      avgPrice: plan.avg_price,
      avgPriceWithoutFee: plan.avg_price_without_fee,
      qty: 1,
      cancelPolicy: plan.cancel_policy,
      maxPeople: buildingData.building.max_people,
      datePriceItems: plan.items,
      planFee,
      extraReception: [{
        adults: 0,
        children: 0
      }]
    }
    state.plans.push(data)

    // gatg custom event
    event('user-click', {
      'event_category': 'click',
      'event_label': 'add-building',
      'value': buildingData.building.id
    })
    // gtag enhanced ecommerce
    event('add_to_cart', {
      'items': [
        {
          'id': data.uid,
          'name': `${data.roomName}_${data.planTitle}`,
          'category': 'building',
          'quantity': data.qty,
          'price': data.avgPrice
        }
      ]
    })

    // fbq
    window.fbq('track', 'AddToCart', {
      content_type: 'product',
      content_ids: [buildingData.building.id],
      content_name: `OwlNest - ${buildingData.building.name}`,
      value: Number(plan.avg_price),
      currency: buildingData.hotelCurrency
    })
  },
  REMOVE_BUILDING (state, building) {
    const index = _.findIndex(state.plans, plan => plan === plan.isBuilding)
    state.plans.splice(index, 1)
    // gatg custom event
    event('user-click', {
      'event_category': 'click',
      'event_label': 'remove-building',
      'value': building.uid
    })
    // gtag enhanced ecommerce
    event('remove_from_cart', {
      'items': [
        {
          'id': building.uid,
          'name': `${building.roomName}`,
          'category': 'building',
          'quantity': 1
        }
      ]
    })
  },
  ADD_PLANS (state, { val, oldVal, room, plan, hotelCurrency }) {
    const hasRoom = state.plans[_.findIndex(state.plans, { 'uid': room.id + '-' + plan.id })] !== undefined
    const planFee = plan.items.reduce((acc, item) => acc + item.fee, 0)
    const data = {
      uid: room.id + '-' + plan.id,
      roomId: room.id,
      planId: plan.id,
      roomName: room.name,
      planTitle: plan.title,
      avgPrice: plan.avg_flexible_price ? plan.avg_flexible_price : plan.avg_price,
      avgPriceWithoutFee: plan.avg_price_without_fee,
      qty: val,
      unit: room.unit,
      cancelPolicy: plan.cancel_policy,
      maxPeople: room.max_people,
      datePriceItems: plan.items,
      planFee,
      extraReception: [{
        adults: 0,
        children: 0
      }]
    }
    if (_.find(state.plans, plan => plan.isBuilding)) {
      state.plans = []
      state.addons = []
    }
    if (hasRoom) {
      const updateRoom = state.plans[_.findIndex(state.plans, { 'uid': room.id + '-' + plan.id })]
      updateRoom.qty = val
      updateRoom.extraReception.push({
        adults: 0,
        children: 0
      })
    } else {
      state.plans.push(data)
    }
    // gatg custom event
    event('user-click', {
      'event_category': 'click',
      'event_label': 'add-plan',
      'value': room.id
    })
    // gtag enhanced ecommerce
    event('add_to_cart', {
      'items': [
        {
          'id': data.uid,
          'name': `${data.roomName}_${data.planTitle}`,
          'category': 'room',
          'quantity': data.qty,
          'price': data.avgPriceWithoutFee
        }
      ]
    })

    // fbq
    window.fbq('track', 'AddToCart', {
      content_type: 'product',
      content_ids: [`ROOM${room.id}/PLAN${plan.id}`],
      content_name: `OwlNest - ${room.name}/${plan.title}`,
      value: Number(data.avgPrice),
      currency: hotelCurrency
    })
  },
  MINUS_PLANS (state, { val, oldVal, room, plan }) {
    if (val === 0) {
      const index = _.findIndex(state.plans, { 'uid': room.id + '-' + plan.id })
      if (index > -1) {
        state.plans.splice(index, 1)
      }
    } else {
      const updateRoom = state.plans[_.findIndex(state.plans, { 'uid': room.id + '-' + plan.id })]
      updateRoom.qty = val
      updateRoom.extraReception.pop()
    }
    // gatg custom event
    event('user-click', {
      'event_category': 'click',
      'event_label': 'minus-plan',
      'value': room.id
    })
    // gtag enhanced ecommerce
    event('remove_from_cart', {
      'items': [
        {
          'id': `${room.id} - ${plan.id}`,
          'name': `${room.name}_${plan.title}`,
          'category': 'room',
          'quantity': val,
          'price': plan.avg_price_without_fee
        }
      ]
    })
    window.fbq('track', 'ViewContent', {
      content_type: 'minus-plan',
      content_ids: room.id
    })
  },
  REMOVE_PLAN (state, uid) {
    const index = _.findIndex(state.plans, plan => plan.uid === uid)
    state.plans.splice(index, 1)
  },
  ADD_ADDONS (state, payload) {
    const addonIndex = _.findIndex(state.addons, addon => addon.id === payload.id)
    const hasAddonAlreadyInCart = addonIndex > -1
    const addonObj = {
      name: payload.name,
      date: payload.date,
      session: payload.session,
      qty: payload.qty,
      unit: payload.unit,
      currency: payload.currency,
      price: payload.price,
      price_without_fee: payload.price_without_fee,
      cancelPolicy: payload.cancel_policy,
      type: payload.type,
      feeConfig: payload.feeConfig
    }
    if (hasAddonAlreadyInCart) {
      state.addons[addonIndex].items.push(addonObj)
    } else {
      state.addons.push({
        id: payload.id,
        items: [addonObj]
      })
    }
    // gatg custom event
    event('user-click', {
      'event_category': 'click',
      'event_label': 'add-addon',
      'value': payload.id
    })
    // gtag enhanced ecommerce
    event('add_to_cart', {
      'items': [
        {
          'id': payload.id,
          'name': payload.name,
          'category': 'addon',
          'quantity': payload.qty,
          'price': payload.price_without_fee
        }
      ]
    })
    window.fbq('track', 'AddToCart', {
      content_type: 'product',
      content_ids: [payload.id],
      content_name: `OwlNest - ${payload.name}`,
      value: Number(payload.price),
      currency: payload.currency
    })
  },
  MINUS_ADDONS (state, payload) {
    const { id, index } = payload
    const addonIndex = _.findIndex(state.addons, addon => addon.id === id)
    state.addons[addonIndex].items.splice(index, 1)
    if (_.isEmpty(state.addons[addonIndex].items)) {
      state.addons.splice(addonIndex, 1)
    }
    // gatg custom event
    event('user-click', {
      'event_category': 'click',
      'event_label': 'minus-addon',
      'value': payload.id
    })
    // gtag enhanced ecommerce
    event('remove_from_cart', {
      'items': [
        {
          'id': payload.id,
          'name': payload.name,
          'category': 'addon',
          'quantity': payload.qty,
          'price': payload.price_without_fee
        }
      ]
    })
    window.fbq('track', 'ViewContent', {
      content_type: 'minus-addon',
      content_ids: [payload.id]
    })
  },
  SET_CUSTOMER (state, payload) {
    _.forEach(payload, function (value, key) {
      state.customer[key] = value
    })
  },
  SET_CUSTOMER_NUMBER (state, payload) {
    _.forEach(payload, function (value, key) {
      state.customerNumber[key] = value
    })
  },
  SET_STRIPE_CARD (state, payload) {
    _.forEach(payload, function (value, key) {
      if (key === 'expiryDate') {
        state.stripeCard['expiryDate'] = value
        state.stripeCard['year'] = '20' + value.slice(2, 4)
        state.stripeCard['month'] = value.slice(0, 2)
      } else {
        state.stripeCard[key] = value
      }
    })
  },
  SET_APPLE_PAY_TOKEN (state, payload) {
    state.applePayToken = payload
  },
  SET_PAYMENT_RESPONSE (state, payload) {
    state.paymentResponse = payload
  },
  SET_REFUND_FORM (state, payload) {
    state.refundForm = payload
  },
  SET_PREVIEW_RESULT (state, payload) {
    state.couponCode = payload.code
    state.previewResult = payload.result
  },
  SET_SONY_PAYMENT_TOKEN (state, payload) {
    state.sonyPaymentToken = payload
  },
  UPDATE_RECEPTION_DATA (state, payload) {
    const { index, planId, roomId, type, value } = payload
    const uid = `${roomId}-${planId}`
    const target = state.plans.find(plan => plan.uid === uid)?.extraReception[index]
    if (target) {
      target[type] = value
    }
  },
  SET_EXTRA_RECEPTION_PRICE_CONFIG (state, payload) {
    state.extraReceptionPriceConfig = {
      children: +payload.child.avg_price,
      adults: +payload.adult.avg_price
    }
  },
  SET_EXTRA_RECEPTION_HOTEL_LIMIT_CONFIG (state, payload) {
    state.extraReceptionHotelLimitConfig = {
      limit: {
        children: +payload?.child?.limit?.values || 0,
        adults: +payload?.adults?.limit?.values || 0
      },
      enabled: {
        children: payload?.child?.limit?.enabled,
        adults: payload?.adults?.limit?.enabled
      }
    }
  },
  SET_HOTEL_EXTRA_RECEPTION_USED (state, payload) {
    state.extraReceptionUsed = {
      adult: +payload?.adults,
      child: +payload?.children
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
