<template>
  <div class="wrapper">
    <div class="wrapper__pagination">
      {{ currentPage }}/{{ totelPage }}
    </div>
    <div class="wrapper__btn-block wrapper__btn-block--next" @click="hasNext && scrollByClick('next')">
      <div class="my-auto wrapper__btn" :class="{ 'wrapper__btn--disabled': !hasNext }">
        <i class="owl-direction-right"></i>
      </div>
    </div>
    <div class="wrapper__btn-block wrapper__btn-block--prev" @click="hasPrev && scrollByClick('prev')">
      <div class="my-auto wrapper__btn" :class="{ 'wrapper__btn--disabled': !hasPrev }">
        <i class="owl-direction-left"></i>
      </div>
    </div>
    <div class='image-swiper' @scroll="handleScroll" ref="swiperContainer">
      <div
        v-for="(image, index) in photos"
        class="image-swiper__item"
        v-lazy:background-image="`${image}?width=${thumbnailWidth}`"
        :key="image"
        @click="handleClick(index)"
      >
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'image-swiper',
  props: {
    photos: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters([
      'windowWidth'
    ]),
    totelPage () {
      return this.photos.length
    },
    swiperContainerWidth () {
      return this.$refs['swiperContainer'].offsetWidth
    },
    swiperTotalWidth () {
      return this.$refs['swiperContainer'].scrollWidth
    },
    hasNext () {
      return this.currentPage < this.totelPage
    },
    hasPrev () {
      return this.currentPage > 1
    },
    thumbnailWidth () {
      return this.windowWidth <= 440 ? 400 : 600
    }
  },
  methods: {
    handleScroll: _.debounce(function (e) {
      this.currentPage = Math.ceil(e.target.scrollLeft / this.swiperContainerWidth) + 1
    }, 50),
    handleClick (index) {
      this.$emit('showPhoto', { index })
    },
    scrollByClick (type) {
      const newScrollWidth = type === 'next' ? this.swiperContainerWidth : -this.swiperContainerWidth
      this.$refs['swiperContainer'].scrollBy({
        top: 0,
        left: newScrollWidth,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang='sass' scoped>
.wrapper
  @apply relative
  &__pagination
    @apply absolute text-white bg-grey-darker opacity-50 rounded-sm text-sm
    right: 4px
    bottom: 4px
    padding: 2px 4px
  &__btn-block
    @apply absolute h-full flex flex-col items-center
    width: 40px
    &--next
      right: 0
    &--left
      right: 0
  &__btn
    @apply text-grey-darker bg-white rounded-full opacity-50
    height: 30px
    width: 30px
    padding: 4px
    &--disabled
      @apply opacity-25
.image-swiper
  @apply w-full mx-auto flex overflow-x-auto
  scroll-snap-type: x mandatory
  scroll-snap-stop: always
  &::-webkit-scrollbar
    display: none
  &__item
    @apply w-full bg-cover bg-center bg-no-repeat
    height:254px
    flex-shrink: 0
    scroll-snap-align: start
</style>
