var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sticky shadow-lg mb-40 py-16",
      attrs: { id: "hotel-sub-nav" }
    },
    [
      _c(
        "div",
        { staticClass: "container flex flex-col lg:flex-row" },
        [
          _c(
            "div",
            {
              staticClass:
                "hotel-sub-nav__selections__desktop md:flex-row items-center text-grey-dark mb-24 lg:mb-0 mr-0 lg:mr-16"
            },
            [
              _c(
                "div",
                { staticClass: "mb-16 md:mb-0 col-12 col-md-7" },
                [
                  _c("roomDateSelector", {
                    attrs: {
                      classic: !_vm.showFilterDatePicker,
                      "date-range": _vm.dateRange,
                      dates: _vm.totalDays,
                      "hotel-rooms-type": _vm.hotelRoomsType,
                      visible: _vm.roomDateSelectorVisible
                    },
                    on: {
                      "update:dates": function($event) {
                        _vm.totalDays = $event
                      },
                      "update:visible": function($event) {
                        _vm.roomDateSelectorVisible = $event
                      },
                      submit: _vm.roomDateSelectorSubmitHandler
                    }
                  })
                ],
                1
              ),
              _c("div", {
                staticClass: "flex-split col-auto d-none d-lg-flex"
              }),
              _c(
                "div",
                {
                  staticClass:
                    "customer-select mb-16 md:mb-0 col-12 col-md-auto"
                },
                [
                  _c(
                    "popperMenu",
                    {
                      class: { active: _vm.customerSelectVisible },
                      attrs: { visible: _vm.customerSelectVisible },
                      on: {
                        "update:visible": function($event) {
                          _vm.customerSelectVisible = $event
                        }
                      }
                    },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "label",
                          { staticClass: "customer-select-label block mb-8" },
                          [
                            _c("i", { staticClass: "owl-user-group" }),
                            _vm._v(" " + _vm._s(_vm.$t("unit.people")))
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "customer-select-value" },
                          [
                            _vm.showPlaceHolder
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "text-orange-dark" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tc("customerNumberPlaceholder")
                                        )
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.people.adult) +
                                        " " +
                                        _vm._s(
                                          _vm.$tc("adult", _vm.people.adult)
                                        )
                                    )
                                  ]),
                                  this["hotel/hotelReceptionConfig"].child
                                    .isReceive && _vm.people.child
                                    ? _c("span", [
                                        _vm._v(
                                          ", " +
                                            _vm._s(_vm.people.child) +
                                            " " +
                                            _vm._s(
                                              _vm.$tc("child", _vm.people.child)
                                            )
                                        )
                                      ])
                                    : _vm._e(),
                                  this["hotel/hotelReceptionConfig"].infant
                                    .isReceive && _vm.people.infant
                                    ? _c("span", [
                                        _vm._v(
                                          ", " +
                                            _vm._s(_vm.people.infant) +
                                            " " +
                                            _vm._s(
                                              _vm.$tc(
                                                "infant",
                                                _vm.people.infant
                                              )
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                          ],
                          2
                        )
                      ]),
                      _c("template", { slot: "dropdown" }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center py-4 px-8 mb-4" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-2/5 text-grey-dark flex flex-col"
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "text-grey-darkest mb-8" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$tc("adult", _vm.people.adult))
                                    )
                                  ]
                                ),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("aboveNYearsOld", {
                                        age: this["hotel/hotelReceptionConfig"]
                                          .adult.minAge
                                      })
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c("vue-numeric-input", {
                              staticClass: "flex-grow custom-numeric-input",
                              attrs: { min: 1, max: 50, step: 1 },
                              model: {
                                value: _vm.people.adult,
                                callback: function($$v) {
                                  _vm.$set(_vm.people, "adult", $$v)
                                },
                                expression: "people.adult"
                              }
                            })
                          ],
                          1
                        ),
                        this["hotel/hotelReceptionConfig"].child.isReceive
                          ? _c(
                              "div",
                              {
                                staticClass: "flex items-center py-4 px-8 mb-4"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-2/5 text-grey-dark flex flex-col"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "text-grey-darkest mb-8" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc("child", _vm.people.child)
                                          )
                                        )
                                      ]
                                    ),
                                    _c("small", [
                                      _vm._v(
                                        _vm._s(
                                          this["hotel/hotelReceptionConfig"]
                                            .child.minAge
                                        ) +
                                          "-" +
                                          _vm._s(
                                            this["hotel/hotelReceptionConfig"]
                                              .child.maxAge
                                          ) +
                                          " " +
                                          _vm._s(_vm.$t("yearsOld"))
                                      )
                                    ])
                                  ]
                                ),
                                _c("vue-numeric-input", {
                                  staticClass: "flex-grow custom-numeric-input",
                                  attrs: { min: 0, max: 10, step: 1 },
                                  model: {
                                    value: _vm.people.child,
                                    callback: function($$v) {
                                      _vm.$set(_vm.people, "child", $$v)
                                    },
                                    expression: "people.child"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        this["hotel/hotelReceptionConfig"].infant.isReceive
                          ? _c(
                              "div",
                              { staticClass: "flex items-center py-4 px-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-2/5 text-grey-dark flex flex-col"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "text-grey-darkest mb-8" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc("infant", _vm.people.infant)
                                          )
                                        )
                                      ]
                                    ),
                                    _c("small", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("underNYearsOld", {
                                              age:
                                                this[
                                                  "hotel/hotelReceptionConfig"
                                                ].infant.minAge +
                                                "-" +
                                                this[
                                                  "hotel/hotelReceptionConfig"
                                                ].infant.maxAge
                                            })
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _c("vue-numeric-input", {
                                  staticClass: "flex-grow custom-numeric-input",
                                  attrs: { min: 0, max: 10, step: 1 },
                                  model: {
                                    value: _vm.people.infant,
                                    callback: function($$v) {
                                      _vm.$set(_vm.people, "infant", $$v)
                                    },
                                    expression: "people.infant"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "toolbar" }, [
                          _c("div", { staticClass: "close-button" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-grey",
                                on: { click: _vm.customerSelectCloseHandler }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("close")) +
                                    "\n                "
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "submit-button" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: { click: _vm.customerSelectCloseHandler }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("confirm")) +
                                    "\n                "
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "scrollactive",
            {
              staticClass: "hotel-sub-nav__selections__right flex items-center",
              attrs: {
                offset: 120,
                modifyUrl: false,
                highlightFirstItem: true
              },
              on: { itemchanged: _vm.scrollactiveItemChange }
            },
            [
              _c(
                "div",
                { staticClass: "hotel-sub-nav__anchors pr-0 lg:pl-12" },
                [
                  _vm.hasRooms
                    ? _c(
                        "a",
                        {
                          staticClass: "scrollactive-item",
                          attrs: {
                            href: "#anchor-rooms",
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("roomType")))]
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "scrollactive-item",
                      attrs: { href: "#anchor-intro", "active-class": "active" }
                    },
                    [_vm._v(_vm._s(_vm.$t("hotelInfo")))]
                  ),
                  _vm.hasBuilding
                    ? _c(
                        "a",
                        {
                          staticClass: "building-anchor scrollactive-item",
                          attrs: {
                            href: "#anchor-building",
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("allBuilding")))]
                      )
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.windowWidth < 768,
                  expression: "windowWidth < 768"
                }
              ],
              staticClass: "hotel-sub-nav__selections__mobile items-center"
            },
            [
              _c(
                "div",
                { staticClass: "hotel-sub-nav__selections__mobile__date" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("dayFormat")(
                          this["search/dateRange"].start,
                          _vm.dateFormatWithWidth
                        )
                      )
                    )
                  ]),
                  _c("span", [_vm._v(" - ")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("dayFormat")(
                          this["search/dateRange"].end,
                          _vm.dateFormatWithWidth
                        )
                      )
                    )
                  ])
                ]
              ),
              _vm.showPlaceHolder
                ? _c("span", { staticClass: "text-orange-dark ml-8" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tc("customerNumberPlaceholder")) +
                        "\n      "
                    )
                  ])
                : _c("span", { staticClass: "ml-16" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.people.adult) +
                          " " +
                          _vm._s(_vm.$tc("adult", _vm.people.adult))
                      )
                    ]),
                    this["hotel/hotelReceptionConfig"].child.isReceive &&
                    _vm.people.child > 0
                      ? _c("span", [
                          _vm._v(
                            ", " +
                              _vm._s(_vm.people.child) +
                              " " +
                              _vm._s(_vm.$tc("child", _vm.people.child))
                          )
                        ])
                      : _vm._e(),
                    this["hotel/hotelReceptionConfig"].infant.isReceive &&
                    _vm.people.infant > 0
                      ? _c("span", [
                          _vm._v(
                            ", " +
                              _vm._s(_vm.people.infant) +
                              " " +
                              _vm._s(_vm.$tc("infant", _vm.people.infant))
                          )
                        ])
                      : _vm._e()
                  ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }