var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "building-block border-b-1 border-grey-lightest" },
    [
      _c("h4", { staticClass: "mb-16 text-grey-darker" }, [
        _c(
          "span",
          {
            staticClass:
              "os-tag os-tag-outline os-tag-orange rounded-full text-sm"
          },
          [_vm._v(_vm._s(_vm.$t("allBuilding")))]
        ),
        _vm._v("\n    " + _vm._s(_vm.building.name) + "\n  ")
      ]),
      _c(
        "div",
        {
          staticClass:
            "building-card rounded-lg overflow-hidden md:shadow-lg md:border-1 md:border-grey-lightest"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "building-card__brief border-b-1 border-grey-lighter flex flex-col md:flex-row flex-wrap"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "building-card__brief__gallery w-full md:w-1/2 lg:w-full xl:w-1/2 relative"
                },
                [
                  _vm.isMobile && _vm.isImage
                    ? [
                        _c("imageSwiper", {
                          attrs: { photos: _vm.building.images },
                          on: { showPhoto: _vm.openPhotoSwipe }
                        })
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full h-full bg-cover bg-center bg-grey-lightest building-card__brief__gallery__cover",
                            style: _vm.roomCover,
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openPhotoSwipe()
                              }
                            }
                          },
                          [
                            _vm.isImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "building-card__brief__gallery__cover__mask"
                                  },
                                  [_c("i", { staticClass: "owl-search" })]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                  _vm.isImage
                    ? _c("photoSwipe", {
                        ref: "photoSwipe",
                        attrs: { photos: _vm.building.images },
                        on: { close: _vm.photoSwipeCloseHandler }
                      })
                    : _vm._e()
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "building-card__brief__content w-full md:w-1/2 lg:w-full xl:w-1/2 md:px-24 py-16"
                },
                [
                  _c(
                    "div",
                    { staticClass: "building-card__brief__content__row flex" },
                    [
                      _vm.building.max_people
                        ? _c("div", { staticClass: "content-group mr-24" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("peopleLimit")))
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.building.max_people) +
                                  " " +
                                  _vm._s(
                                    _vm.$tc("person", _vm.building.max_people)
                                  )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.building.area
                        ? _c("div", { staticClass: "content-group" }, [
                            _c("label", [_vm._v(_vm._s(_vm.$t("area")))]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.building.area) +
                                  " " +
                                  _vm._s(_vm.building.area_unit)
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "building-card__brief__content__row" },
                    [
                      _vm.isFeatures
                        ? _c("div", { staticClass: "content-group" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tc("features", _vm.top4features.length)
                                )
                              )
                            ]),
                            _c(
                              "ul",
                              {
                                staticClass: "feature-list flex flex-wrap mb-8"
                              },
                              _vm._l(_vm.top4features, function(
                                feature,
                                index
                              ) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "mt-4 mr-8" },
                                  [
                                    _c("img", {
                                      staticClass: "room-card__feature-icon",
                                      attrs: { src: feature.icon_url }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "align-text-top" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(feature.text) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm.isFeatureMoreThan4
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "more-features text-sm cursor-pointer",
                                    on: { click: _vm.showMoreFeatures }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "owl-status-add mr-4"
                                    }),
                                    _c("span", [_vm._v(_vm._s(_vm.$t("more")))])
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          ),
          _c("div", { staticClass: "building-card__content" }, [
            _c(
              "div",
              { staticClass: "building-card__content md:px-16 lg:px-24" },
              _vm._l(_vm.building.plans, function(plan) {
                return _c(
                  "RoomPlan",
                  _vm._g(
                    {
                      key: plan.id,
                      attrs: {
                        plan: plan,
                        room: _vm.building,
                        stock: _vm.building.stock_count,
                        isBuilding: true
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "buildingButton",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-ghost btn-blue w-full",
                                    class: {
                                      "no-event":
                                        _vm.disabledInputByCustomerNumber
                                    },
                                    attrs: {
                                      disabled:
                                        _vm.isInOrder ||
                                        _vm.hasRestrictedReasons(plan) ||
                                        _vm.disabledInputByCustomerNumber
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addBuilding(plan.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("orderBuilding")) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    _vm.$listeners
                  )
                )
              }),
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }