
<template>
  <div class="component-message-button">
    <div v-if="$slots.tooltip" :class="['tooltip', { 'active': tooltipShow }]">
      <div class="content">
        <slot name="tooltip"></slot>
      </div>
      <div class="arrow"></div>
    </div>
    <div :class="['message-buttons', { active: this.visible }]">
      <a
        v-for="(v, i) in data"
        :key="i" class="chat-button"
        :style="{
          background: v.background,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: v.color || '#fff'
        }"
        @click="hrefClickHandler(v.link)"
        >
        <!-- :href="v.link"
        target="_blank" -->
        <i v-if="v.icon" :class="v.icon"></i>
      </a>
    </div>
    <div :class="['main-button', { active: this.visible }]" @click="toggleButtonsClickHandler"></div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  name: 'messageButton',
  props: {
    data: {
      type: Array,
      default () {
        return [
          // {
          //   icon: 'fa-regular fa-comment-dots',
          //   link: 'https://www.google.com',
          //   backgroundColor: '#07b551'
          //   background: ../assets/LINE_APP_Android.png
          // }
        ]
      }
    }
  },
  computed: {
    // ...mapState(['lang']),
    isEn () {
      return this.lang === 'en'
    }
  },
  watch: {},
  data () {
    return {
      visible: false,
      dicts: {},
      dict: {},
      dragInfo: {
        startX: 0,
        startY: 0,
        area: null,
        dragButton: null,
        dragTarget: null,
        info: null,
        showTop: true,
        isMove: false,
        useTouch: !!navigator.maxTouchPoints,
        t0: '',
        t1: ''
      },
      tooltipShow: false
    }
  },
  created () {
    // this.dict = this.dicts[this.lang] || this.dicts.en
  },
  mounted () {
    window.onresize = this.dragInit
    this.dragInit()

    setTimeout(() => {
      this.tooltipShow = true
    }, 100)
    setTimeout(() => {
      this.tooltipShow = false
    }, 10000)
  },
  methods: {
    toggleButtonsClickHandler (e) {
      e.preventDefault()

      if (this.dragInfo.isMove) return
      if (this.debounceSetTimeout) {
        clearTimeout(this.debounceSetTimeout)
      }
      this.debounceSetTimeout = setTimeout(() => {
        this.visible = !this.visible
        delete this.debounceSetTimeout
      }, 100)
    },
    hrefClickHandler (link) {
      if (this.dragInfo.isMove) return
      if (this.debounceSetTimeout) {
        clearTimeout(this.debounceSetTimeout)
      }
      this.debounceSetTimeout = setTimeout(() => {
        window.open(link, '_blank')
        delete this.debounceSetTimeout
      }, 100)
    },
    // drag/drop
    dragInit () {
      this.dragInfo.dragButton = document.querySelector('.component-message-button .main-button')
      this.dragInfo.dragTarget = document.querySelector('.component-message-button')
      this.dragInfo.dragTarget.addEventListener('mousedown', this.dragStart)
      this.dragInfo.dragTarget.addEventListener('touchstart', this.dragStart)

      const { dragButton } = this.dragInfo

      const target = dragButton.getBoundingClientRect()

      if (this.dragInfo.useTouch) {
        this.dragInfo.area = {
          left: 8,
          right: window.innerWidth - target.width - 8,
          top: 8,
          bottom: window.innerHeight - target.height - 40
        }
      } else {
        this.dragInfo.area = {
          left: 8,
          right: window.innerWidth - target.width - 24,
          top: 8,
          bottom: window.innerHeight - target.height - 16
        }
      }
    },
    dragStart (e) {
      e.preventDefault()
      this.dragInfo.isMove = true
      this.dragInfo.t0 = new Date().getTime()

      this.dragInfo.end = false
      this.tooltipShow = false

      const { dragTarget } = this.dragInfo

      const touch = e.touches && e.touches[0]
      // this.dragInfo.useTouch = !!touch
      const clientX = e.clientX || touch.clientX
      const clientY = e.clientY || touch.clientY

      // 記錄點擊相對被點擊物件的座標
      this.dragInfo.startX = clientX - dragTarget.offsetLeft
      this.dragInfo.startY = clientY - dragTarget.offsetTop
      document.addEventListener('mousemove', this.move)
      document.addEventListener('mouseup', this.stop)
      document.addEventListener('touchmove', this.move)
      document.addEventListener('touchend', this.stop)
    },
    move (e) {
      // 計算出拖曳物件最左上角座標
      const { area, dragTarget, dragButton } = this.dragInfo
      const touch = e.touches && e.touches[0]

      const clientX = e.clientX || touch.clientX
      const clientY = e.clientY || touch.clientY

      let x = clientX - this.dragInfo.startX
      let y = clientY - this.dragInfo.startY

      x = Math.max(Math.min(x, area.right), area.left)
      y = Math.max(Math.min(y, area.bottom), area.top)

      dragTarget.style.left = x + 'px'
      dragTarget.style.top = y + 'px'

      let target = dragTarget.getBoundingClientRect()
      const dvy = target.height + 16
      const vy = this.visible ? window.innerHeight - dvy : 99999
      y = Math.min(y, vy)
      dragTarget.style.top = y + 'px'

      target = dragButton.getBoundingClientRect()
      const dy = window.innerHeight - y - (target.height + 32) + 8
      dragTarget.style.bottom = dy + 'px'

      const showTop = y > dy
      this.dragInfo.showTop = showTop
      this.dragInfo.info = {
        top: y,
        bottom: dy
      }

      if (this.dragInfo.showTop && !this.visible) {
        dragTarget.style.top = ''
      } else {
        dragTarget.style.bottom = ''
      }
    },
    stop (e) {
      document.removeEventListener('mousemove', this.move)
      document.removeEventListener('mouseup', this.stop)
      document.removeEventListener('touchmove', this.move)
      document.removeEventListener('touchend', this.stop)

      this.dragInfo.t1 = new Date().getTime()
      if ((this.dragInfo.t1 - this.dragInfo.t0) < 200) {
        this.dragInfo.isMove = false
        e.target.click(e)
      }

      setTimeout(() => {
        this.dragInfo.isMove = false
        this.dragInfo.t0 = ''
        this.dragInfo.t1 = ''
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.component-message-button {
  @import '@/assets/style/middle.scss';
  @import '@/assets/style/trans.scss';

  position: fixed;
  right: 0.5em;
  bottom: 1em;
  z-index: 1000;
  display: inline-table;

  .chat-button {
    @include middle();
    @include trans();
    width: 2.2em;
    height: 2.2em;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.8em;

    // &:hover {
    //   box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    // }
  }

  .main-button {
    @include trans(0.1s);
    @extend .chat-button;
    border-radius: 50%;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    background: #a2abb2 url(../assets/img/msg.png) no-repeat center/80%;
    color: #fff;
    z-index: 2;

    &:hover {
      // background-color: #fff;
      box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    }

    &.active, {
      background: #a2abb2 url(../assets/img/xmark.png) no-repeat center/80%;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    &:active {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }

    &:focus {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .message-buttons {
    @include trans(.5s);
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: contents;
    z-index: 1;
    border-radius: 50%;

    > .chat-button {
      pointer-events: none;
      opacity: 0;
      margin-bottom: -2.2em;
      color: #000;
    }

    &.active {
      > .chat-button {
        pointer-events: all;
        opacity: 1;
        margin-bottom: 0.5em;
      }
    }
  }

  // tooltip
  .tooltip {
    @include trans(0.5s);
    opacity: 0;
    $color: #000;
    position: absolute;
    z-index: 10;

    &.active {
      opacity: 1;
    }

    .arrow,
    .arrow::before,
    .arrow::after {
      position: absolute;
      width: 0.75em;
      height: 0.75em;
      background: inherit;
    }

    .content {
      background-color: $color;
      opacity: 0.8;
      position: relative;
      padding: 0.25em 0.5em;
      border-radius: 4px;
      color: #fff;
      z-index: 2;
      font-size: 0.875em;
      white-space: nowrap;
    }

    .arrow {
      z-index: -1;
      visibility: hidden;
      background-color: $color;
      opacity: 0.7;
      right: 1.5em;

      &:after, &:before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
      }

      &:after {
        background-color: #ccc;
        left: -2px;
      }

      &:before {
        z-index: 2;
      }
    }

    // &[data-popper-placement^='top'] {
      top: -1.75em;
      right: 0.25em;
      .arrow {
        bottom: -.25em;
      }
    // }
  }
}
</style>
