<template>
<div class="extra-rectption-form">
  <div class="w-1/4 mr-auto flex items-center">
    <img
      src="https://static.owlting.com/booking/icon/on_feature_100.svg"
      class="mr-4"
    />
    <p>{{ `${$t('extraReception.room')}${index}`}}</p>
  </div>
  <div class="sm:w-1/3 sm:ml-12 mt-16">
    <label class="pl-4">{{ $t('extraReception.adult') }}</label>
    <multiSelect
      class="mt-8 reception-select"
      :value="mapAdultsOption"
      track-by='value'
      :options='adultsOption'
      :show-labels="false"
      :close-on-select="true"
      :allow-empty="false"
      :placeholder="$t('extraReception.selectorPlaceholder')"
      @select="handleSelectAdults"
    >
      <template slot="option" slot-scope="{ option }">
        {{ option.name }}
      </template>
    </multiSelect>
  </div>
  <div class="sm:w-1/3 sm:ml-12 mt-16">
    <label class="pl-4">{{ $t('extraReception.child') }}</label>
    <multiSelect
      class="mt-8 reception-select"
      :value="mapChildrenOption"
      track-by='value'
      :options='childrenOption'
      :show-labels="false"
      :close-on-select="true"
      :allow-empty="false"
      :placeholder="$t('extraReception.selectorPlaceholder')"
      @select="handleSelectChildren"
    >
      <template slot="option" slot-scope="{ option }">
        {{ option.name }}
      </template>
    </multiSelect>
  </div>
</div>
</template>

<script>
export default {
  name: 'extraReceptionForm',
  props: {
    index: {
      type: Number,
      default: 1
    },
    extraReceptionData: {
      type: Object,
      default: () => {
        return {
          adults: 0,
          children: 0
        }
      }
    },
    adultsOption: {
      type: Array,
      default: () => {
        return []
      }
    },
    childrenOption: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    handleSelectAdults ({ value }) {
      this.$emit('update:extra-reception-data', {
        ...this.extraReceptionData,
        adults: value
      })
      this.$emit('update', {
        index: this.index - 1,
        type: 'adults',
        value
      })
    },
    handleSelectChildren ({ value }) {
      this.$emit('update:extra-reception-data', {
        ...this.extraReceptionData,
        children: value
      })
      this.$emit('update', {
        index: this.index - 1,
        type: 'children',
        value
      })
    }
  },
  computed: {
    mapAdultsOption () {
      return this.adultsOption.find(option => option.value === this.extraReceptionData.adults)?.name
    },
    mapChildrenOption () {
      return this.childrenOption.find(option => option.value === this.extraReceptionData.children)?.name
    }
  }
}
</script>

<style lang="sass" scoped>
.extra-rectption-form
  @apply block mt-24
  @screen sm
    @apply flex items-center mt-4
</style>

<style lang="sass">
.reception-select
  .multiselect__single
    @apply overflow-hidden
    white-space: nowrap
    text-overflow: ellipsis
  .multiselect__content-wrapper
    width: fit-content
    min-width: 100%
</style>
