var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "order-plan px-16 pt-24 pb-16 font-normal border-grey-lighter relative"
    },
    [
      _c("div", { staticClass: "order-plan__row mb-8 items-center" }, [
        _c("h5", { staticClass: "text-grey-darkest font-medium" }, [
          _vm.plan.isBuilding
            ? _c(
                "span",
                {
                  staticClass:
                    "os-tag os-tag-outline os-tag-orange rounded-full text-sm"
                },
                [_vm._v(_vm._s(_vm.$t("allBuilding")))]
              )
            : _vm._e(),
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("contentParser")(_vm.plan.roomName)) +
              "\n    "
          )
        ]),
        _c("i", {
          staticClass: "owl-status-error remove-plan",
          on: { click: _vm.removePlan }
        })
      ]),
      _c("div", { staticClass: "order-plan__row" }, [
        _c("label", { staticClass: "text-grey-dark" }, [
          _vm._v(_vm._s(_vm._f("contentParser")(_vm.plan.planTitle)))
        ]),
        _c("span", [
          _vm._v(
            "x " +
              _vm._s(
                _vm.$t("orderUnit", {
                  stock: _vm.plan.qty,
                  unit: _vm.plan.unit
                })
              )
          )
        ])
      ]),
      _c("div", { staticClass: "order-plan__row" }, [
        _c("label", [
          _vm._v(
            _vm._s(_vm.selectedCurrency) +
              " " +
              _vm._s(_vm._f("currency")(_vm.plan.avgPriceWithoutFee))
          )
        ]),
        _c("span", [
          _vm._v(
            "x " +
              _vm._s(this["search/nights"]) +
              " " +
              _vm._s(_vm.$tc("night", this["search/nights"]))
          )
        ])
      ]),
      _vm.extraReceptionTotalCount > 0
        ? _c("div", { staticClass: "order-plan__row" }, [
            _c("label", [
              _vm._v(
                _vm._s(_vm.selectedCurrency) +
                  " " +
                  _vm._s(_vm._f("currency")(_vm.extraReceptionTotalPrice))
              )
            ]),
            _c("span", [
              _vm._v(
                _vm._s("+ " + _vm.extraReceptionTotalCount + _vm.$t("person"))
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "order-plan__row" }, [
        _c("label", { staticClass: "text-grey-dark" }, [
          _vm._v(_vm._s(_vm.$t("total")))
        ]),
        _c(
          "span",
          [
            _vm._v("\n      " + _vm._s(_vm.selectedCurrency) + "\n      "),
            _c("big", { staticClass: "text-blue font-bold" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.total)))
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }