import request from '@/utils/request'

export default {
  locateCustomerIP () {
    return request({
      url: `/about`,
      method: 'get'
    }).catch(err => {
      console.debug('CATCH', err)
    })
  }
}
