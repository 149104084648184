import axios from 'axios'
import _ from 'lodash'
import HOTELS from '@/api/hotels'
import { event } from 'vue-gtag'
import dayjs from 'dayjs'
import $cookies from 'js-cookie'
// import gaList from '@/assets/gaList'

// initial state
const state = {
  roomsType: [],
  isLoadingHotel: false,
  isLoadingRooms: true,
  isLoadingDisabledDates: true,
  isLoadingPaymentWays: true,
  receptionConfig: {
    adult: {
      isReceive: true,
      maxAge: 99,
      minAge: 12
    },
    child: {
      isReceive: true,
      maxAge: 11,
      minAge: 2
    },
    infant: {
      isReceive: true,
      maxAge: 1,
      minAge: 0
    }
  },
  hotel: {
    cover_images: {
      hotels: [],
      rooms: [
        {
          images: ['']
        }
      ]
    }
  },
  rooms: [],
  disabledDates: {
    start: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    end: dayjs().add(1, 'year').format('YYYY-MM-DD')
  },
  cancelHotelAPI: false,
  cancelRoomsAPI: false,
  cancelRoomsByDateAPI: false,
  cancelDisableDatesAPI: false,
  cancelPaymentWaysAPI: false,
  isCouponActive: false,
  todayByHotelTimeZone: dayjs().format('YYYY-MM-DD'),
  stripeKey: '',
  sonyPaymentPublicKey: '',
  paymentWays: [],
  displaySetting: {
    showPhone: true,
    showEmail: true,
    showLink: true,
    isBlockWithNumberNotMatch: false,
    isForceSelectCustomerNumber: false,
    isPlanCollapse: false,
    planSortingByPrice: true,
    planSortingByPriceAsc: true
  },
  isSkipAddon: false,
  internalPermissions: {},
  hotelTrackSetting: {}
}

// getters
const getters = {
  roomsType: (state, getters, rootState) => {
    return state.roomsType
  },
  hotel: (state, getters, rootState) => {
    return state.hotel
  },
  hotelTimeZone: (state) => {
    return state.hotel.timezone
  },
  rooms: (state, getters, rootState) => {
    return state.rooms
  },
  disabledDates: (state, getters, rootState) => {
    return state.disabledDates
  },
  isLoadingRooms: (state, getters, rootState) => {
    return state.isLoadingRooms
  },
  hotelLocatedBy: (state) => {
    const locatedWaysPriority = ['address_location', 'address', 'name']
    const mapLocatedBy = locatedWaysPriority.find(way => !_.isEmpty(state.hotel[way]))
    return state.hotel[mapLocatedBy] || ''
  },
  isLoadingDisabledDates: (state) => {
    return state.isLoadingDisabledDates
  },
  hotelOriginCurrency: (state) => {
    return state.hotel.currency
  },
  hotelReceptionConfig: (state) => {
    return state.receptionConfig
  },
  hotelDisplaySetting: (state) => {
    return state.displaySetting
  },
  hotelInternalPermissions(state) {
    return state.internalPermissions
  },
  hotelDepositRate: (state) => {
    return state.hotel.deposit_rate * 100
  },
  hotelWebSiteUrl: (state) => {
    const url = state.hotel.website
    const protocolRule = /^(http|https):\/\//

    if (!url) return ''

    return protocolRule.test(url)
      ? url
      : `https://${url}`
  },
  isCouponActive: (state) => {
    return state.isCouponActive
  },
  isEnabledRoomCalendar: (state) => {
    return state.hotel.is_enabled_room_calendar
  },
  hotelTrackSetting: (state) => {
    return state.hotelTrackSetting
  },
  hasExtraTaxFeeConfig: (state) => {
    return state.hotel.fees?.some(fee => fee.addition_rate > 0)
  },
  stripeKey: (state) => {
    return state.hotel.stripe_public_key
  },
  paymentWays: (state) => {
    return state.paymentWays
  },
  isSkipAddon: (state) => {
    return state.isSkipAddon
  },
  sonyPaymentPublicKey: (state) => {
    return state.sonyPaymentPublicKey
  }
}

// actions
const actions = {
  fetchHotelRoomsType({ commit, state, rootState }, payload) {
    const hotelId = payload || rootState.hotelId
    return HOTELS.fetchHotelRoomsType(decodeURI(hotelId)).then(res => {
      if (res.data?.status === 0) {
        commit('SET_HOTEL_ROOM_TYPE', res.data.data.rooms)
      }
    })
  },
  fetchHotel({ commit, state, rootState }, payload) {
    commit('IS_LOADING_HOTEL', true)

    const hotelId = payload || rootState.hotelId
    return HOTELS.fetchHotelDetail(decodeURI(hotelId), state.cancelHotelAPI).then(res => {
      // 無旅館 code: 10006
      // 無 BE code: 80021
      // 未付款 80025
      if (res.data?.status === 0) {
        const hotelData = res.data?.data

        commit('SET_HOTEL_DETAIL', { ...hotelData })
        commit('SET_HOTEL_ID', hotelData.hotel_uuid, { root: true })
        commit('SET_HOTEL_ALIAS', hotelData.alias_en, { root: true })
        commit('SET_HOTEL_CUSTOM_DOMAIN', hotelData.custom_domain, { root: true })
        commit('SET_HOTEL_COUPON_STATUS', hotelData.is_enable_coupons)
        commit('SET_TODAY_BY_HOTEL_TIME_ZONE')
        commit('SET_STRIPE_KEY', hotelData.stripe_public_key)

        commit('IS_LOADING_HOTEL', false)

        const isSkipAddon = hotelData.addons_count === 0 // 旅館active addon數量為0視為可跳過加購頁面
        commit('SET_IS_SKIP_ADDON', isSkipAddon)

        if (hotelData.rules?.receiving) {
          commit('SET_HOTEL_RECEIVE_LIMIT', hotelData.rules.receiving)
        }
        if (hotelData.setting?.official) {
          commit('SET_HOTEL_DISPLAY_SETTING', hotelData.setting.official)
        }
        if (hotelData.setting?.receptions) { // 加人設定
          this.commit('order/SET_EXTRA_RECEPTION_HOTEL_LIMIT_CONFIG', hotelData.setting.receptions)
        }
        if (hotelData.internal_permissions) {
          commit('SET_HOTEL_INTERNAL_PERMISSION', hotelData.internal_permissions)
        }

        const switchHotel = !localStorage.getItem('owlHotel-last-launch-hotel-uuid') || localStorage.getItem('owlHotel-last-launch-hotel-uuid') !== rootState.hotelId
        const hasSetDisplayCurrency = hotelData.display_currency !== hotelData.currency

        if (_.isEmpty(rootState.selectedCurrency) || _.isEmpty(rootState.currencyExist) || switchHotel) {
          if (hasSetDisplayCurrency) {
            // 如果 hotel 設定不同的 currency，則幫他切換成預設幣別
            commit('SET_CURRENCY', hotelData.display_currency, { root: true })
          } else {
            commit('SET_CURRENCY', hotelData.currency, { root: true })
          }
        }
        if (switchHotel) {
          localStorage.setItem('owlHotel-last-launch-hotel-uuid', rootState.hotelId)
        }

        // gatg custom event
        event('fetch-hotel', {
          'event_category': 'landing',
          'event_label': 'landing',
          'value': hotelData.name + ' - ' + rootState.hotelId
        })
        window.fbq('track', 'ViewContent', {
          content_type: 'hotel',
          contents: [
            {
              id: rootState.hotelId,
              hotelName: hotelData.name
            }
          ]
        })
        //

        return Promise.resolve(hotelData)
      } else {
        return Promise.reject(res.data)
      }
    })
  },
  setLoadingRoom({ commit }, value) {
    commit('IS_LOADING_ROOM', value)
  },
  fetchRooms({ commit, state, rootState }) {
    if (state.cancelRoomsAPI) {
      state.cancelRoomsAPI.cancel('Cancel rooms fetching.')
    }
    commit('IS_LOADING_ROOM', true)
    this.commit('order/CLEAR_ORDERS')
    const start = this._vm.$dayjs(rootState.search.dateRange.start).format('YYYY-MM-DD')
    const end = this._vm.$dayjs(rootState.search.dateRange.end).format('YYYY-MM-DD')
    const people = rootState.search.people
    return HOTELS.fetchHotelRooms(decodeURI(rootState.hotelId), start, end, people, state.cancelRoomsAPI).then(res => {
      if (res && res.data) {
        commit('SET_HOTEL_ROOMS', res.data.data)
        commit('IS_LOADING_ROOM', false)
        if (res?.data?.data[0]?.receptions_used) {
          this.commit('order/SET_HOTEL_EXTRA_RECEPTION_USED', res.data.data[0].receptions_used)
        }
        if (res?.data?.data[0]?.receptions) {
          this.commit('order/SET_EXTRA_RECEPTION_PRICE_CONFIG', res.data.data[0].receptions)
        }
      }
    })
  },
  fetchRoomsByDate({ commit, state, rootState }, options = {}) {
    if (state.cancelRoomsByDateAPI) {
      state.cancelRoomsByDateAPI.cancel('Cancel rooms by date fetching.')
    }
    commit('IS_LOADING_ROOM', true)
    commit('IS_LOADING_ROOM_BY_DATE', true)

    const start = this._vm.$dayjs(options.start).format('YYYY-MM-DD')
    const end = this._vm.$dayjs(options.end).format('YYYY-MM-DD')
    const people = rootState.search.people
    return HOTELS.fetchHotelRooms(decodeURI(rootState.hotelId), start, end, people, state.cancelRoomsByDateAPI).then(res => {
      commit('IS_LOADING_ROOM', false)
      commit('IS_LOADING_ROOM_BY_DATE', false)

      return res && res.data
        ? res.data.data
        : []
    })
  },
  fetchRoomDisabledDates({ commit, state, rootState }, options = {}) {
    if (state.cancelDisableDatesAPI) {
      state.cancelDisableDatesAPI.cancel('Cancel disable dates fetching.')
    }

    const {
      isPreview = false,
      roomId = rootState.search.roomId,
      people = rootState.search.people,
      //
      start = this._vm.$dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end = this._vm.$dayjs().add(12, 'month').endOf('month').format('YYYY-MM-DD')
    } = options
    commit('IS_LOADING_DISABLED_DATES', true)

    return HOTELS.fetchRoomDisabledDates({
      hotelId: decodeURI(rootState.hotelId),
      start,
      end,
      cancelToken: state.cancelDisableDatesAPI,
      roomId,
      ...roomId ? {} : { people }
    }).then(res => {
      if (res && res.data) {
        let result = null
        if (!isPreview) {
          result = _.chain(state.disabledDates)
            .concat(res.data.data)
            .filter(day => {
              return this._vm.$dayjs(day).isSameOrAfter(this._vm.$dayjs(), 'day')
            })
            .uniq()
            .value()
          commit('SET_DISABLED_DATES', result)
        } else {
          result = _.chain(res.data.data)
            .filter(day => {
              return this._vm.$dayjs(day).isSameOrAfter(this._vm.$dayjs(), 'day')
            })
            .uniq()
            .value()
        }
        commit('IS_LOADING_DISABLED_DATES', false)
        return result
      }
    })
  },
  fetchHotelPaymentWays({ commit, state, rootState }) {
    const startDate = this._vm.$dayjs(rootState.search.dateRange.start).format('YYYY-MM-DD')
    const endDate = this._vm.$dayjs(rootState.search.dateRange.end).format('YYYY-MM-DD')

    if (state.cancelPaymentWaysAPI) state.cancelPaymentWaysAPI.cancel('Cancel Payment Ways fetching.')

    commit('IS_LOADING_PAYMENT_WAYS', true)
    return HOTELS.fetchPaymentWays(decodeURI(rootState.hotelId), startDate, endDate, state.cancelPaymentWaysAPI)
      .then(res => {
        if (res && res.data) {
          const { payment_ways: paymentWays } = res.data.data || {}
          commit('SET_PAYMENT_WAYS', paymentWays || {})
          commit('IS_LOADING_PAYMENT_WAYS', false)
        }
      })
  },
  fetchSonyPaymentPublicKey({ commit, rootState }) {
    const uuid = rootState?.hotel?.hotel?.hotel_uuid
    if (uuid) {
      HOTELS.fetchSonyPaymentPublicKey(uuid).then(({ data }) => {
        if (data.status === 0 && data.data?.token) {
          commit('SET_SONY_PAYMENT_PUBLIC_KEY', data.data.token)
        }
      })
    }
  },
  async getGaTagConfig({ commit, state, rootState }, payload) {
    const hotelUuid = state.hotel.hotel_uuid
    const oneHour = 1 / 24 // gtag init
    const googleHotelSource = 'met' // met for coming from google hotel #4406
    const {
      VUE_APP_OWL_GA_CONVERSION_ID,
      VUE_APP_OWL_GA_ID,
      VUE_APP_OWL_GA_AW_ID
    } = process.env

    let gtagConfig = {
      config: { id: VUE_APP_OWL_GA_ID },
      includes: [{ id: VUE_APP_OWL_GA_AW_ID }]
    }

    const { data } = await HOTELS.getHotelAnalytics(decodeURI(hotelUuid), state.cancelHotelAPI) || {}

    if (data?.status === 0 && data?.data) {
      state.hotelTrackSetting = {
        ...data.data,
        conversion_ids: [VUE_APP_OWL_GA_CONVERSION_ID, ...data.data.conversion_ids || []]
      }

      const sourceFromQuery = new URL(window.location.href).searchParams.get('source')
      const sourceFromCookies = $cookies.get('source')
      const vectorConversionId = state.hotelTrackSetting.vector_conversion_id
      const gaIds = state.hotelTrackSetting.ga_ids

      if (gaIds) gtagConfig.includes = [...gtagConfig.includes, ...gaIds.map((id) => ({ id }))]

      if (vectorConversionId && (sourceFromQuery === googleHotelSource || sourceFromCookies === googleHotelSource)) {
        gtagConfig.includes.push({
          id: `AW-${vectorConversionId}`,
          params: {
            send_page_view: false
          }
        })

        // set source flag
        $cookies.set('source', googleHotelSource, { expires: oneHour })
      }
    }
    return gtagConfig
  }
}
// mutations
const mutations = {
  SET_HOTEL_ROOM_TYPE(state, payload) {
    state.roomsType = payload
  },
  IS_LOADING_HOTEL(state, payload) {
    state.cancelHotelAPI = axios.CancelToken.source()
    state.isLoadingHotel = payload
  },
  IS_LOADING_ROOM(state, payload) {
    state.cancelRoomsAPI = axios.CancelToken.source()

    if (payload) {
      state.isLoadingRooms = payload
    } else {
      if (window.setTimeout_IS_LOADING_ROOM) {
        clearTimeout(window.setTimeout_IS_LOADING_ROOM)
      }
      window.setTimeout_IS_LOADING_ROOM = setTimeout(() => {
        state.isLoadingRooms = payload
        delete window.setTimeout_IS_LOADING_ROOM
      }, 500)
    }
  },
  IS_LOADING_ROOM_BY_DATE(state, payload) {
    state.cancelRoomsByDateAPI = axios.CancelToken.source()
  },
  IS_LOADING_DISABLED_DATES(state, payload) {
    state.cancelDisableDatesAPI = axios.CancelToken.source()
    state.isLoadingDisabledDates = payload
  },
  IS_LOADING_PAYMENT_WAYS(state, payload) {
    state.cancelPaymentWaysAPI = axios.CancelToken.source()
    state.isLoadingPaymentWays = payload
  },
  SET_HOTEL_DETAIL(state, payload) {
    payload.chargeFeatures = []
    for (const key in payload.hotel_features) {
      payload.hotel_features[key].forEach(feature => {
        if (feature.is_charge) {
          payload.chargeFeatures.push(feature)
        }
      })
    }
    state.hotel = payload
  },
  SET_HOTEL_RECEIVE_LIMIT(state, payload) {
    if (payload.adult !== null) {
      state.receptionConfig.adult.minAge = payload.adult.min
    }
    if (payload.child !== null) {
      state.receptionConfig.child.maxAge = payload.child.max
      state.receptionConfig.child.minAge = payload.child.min
    }
    if (payload.infant !== null) {
      state.receptionConfig.infant.maxAge = payload.infant.max
      state.receptionConfig.infant.minAge = payload.infant.min
    }
    state.receptionConfig.child.isReceive = payload.child !== null
    state.receptionConfig.infant.isReceive = payload.infant !== null
  },
  SET_HOTEL_DISPLAY_SETTING(state, payload) {
    state.displaySetting.showPhone = payload.showPhone
    state.displaySetting.showEmail = payload.showEmail
    state.displaySetting.showLink = payload.showLink
    state.displaySetting.isBlockWithNumberNotMatch = payload.isBlockWithNumberNotMatch
    state.displaySetting.isForceSelectCustomerNumber = payload.isForceSelectCustomerNumber
    state.displaySetting.isPlanCollapse = payload.isPlanCollapse

    // 尚未設定時，預設兩項應為true
    state.displaySetting.planSortingByPrice = typeof payload.planSortingByPrice === 'undefined' ? true : payload.planSortingByPrice
    state.displaySetting.planSortingByPriceAsc = typeof payload.planSortingByPriceAsc === 'undefined' ? true : payload.planSortingByPriceAsc
  },
  SET_HOTEL_INTERNAL_PERMISSION(state, payload) {
    state.internalPermissions = payload || {}
  },
  SET_HOTEL_ROOMS(state, payload) {
    if (!payload) return

    const isPlanSortByPrice = state.displaySetting.planSortingByPrice
    const isPlanSortingByPriceAsc = state.displaySetting.planSortingByPriceAsc

    // 依價格由低到高排序
    if (isPlanSortByPrice && isPlanSortingByPriceAsc) {
      state.rooms = payload.map(room => {
        room.plans = room.plans.sort((a, b) => {
          if (b.avg_price_without_fee === a.avg_price_without_fee) {
            if (b.sort === a.sort) {
              return a.id - b.id
            }
            return b.sort - a.sort
          }
          return a.avg_price_without_fee - b.avg_price_without_fee
        })
        return room
      })
      return
    }

    // 依價格由高到低排序
    if (isPlanSortByPrice) {
      state.rooms = payload.map(room => {
        room.plans = room.plans.sort((a, b) => {
          if (b.avg_price_without_fee === a.avg_price_without_fee) {
            if (b.sort === a.sort) {
              return a.id - b.id
            }
            return b.sort - a.sort
          }
          return b.avg_price_without_fee - a.avg_price_without_fee
        })
        return room
      })
      return
    }

    // 按專案排序
    state.rooms = payload.map(room => {
      room.plans = room.plans.sort((a, b) => {
        if (b.sort === a.sort) {
          return 0
        }
        return b.sort - a.sort
      })
      return room
    })
  },
  SET_DISABLED_DATES(state, payload) {
    state.disabledDates = payload
  },
  SET_HOTEL_COUPON_STATUS(state, payload) {
    state.isCouponActive = payload
  },
  SET_TODAY_BY_HOTEL_TIME_ZONE(state) {
    try {
      state.todayByHotelTimeZone = new Date().toLocaleString('zh-tw', { timeZone: state.hotel.timezone }).split(' ')[0]
    } catch (err) {
      console.debug('time zone error')
    }
  },
  SET_STRIPE_KEY(state, payload) {
    state.stripeKey = payload
  },
  SET_PAYMENT_WAYS(state, payload) {
    const paymentWays = Object.entries(payload)
      .reduce((acc, [paymentName, isActive]) => {
        acc.push({
          type: paymentName,
          active: isActive
        })
        return acc
      }, [])
    state.paymentWays = paymentWays
  },
  SET_IS_SKIP_ADDON(state, payload) {
    state.isSkipAddon = payload
  },
  CANCEL_FORCE_CUSTOMER_NUMBER() {
    state.displaySetting.isForceSelectCustomerNumber = false
  },
  SET_SONY_PAYMENT_PUBLIC_KEY(state, payload) {
    state.sonyPaymentPublicKey = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
