import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import search from './modules/search'
import hotel from './modules/hotel'
import addon from './modules/addon'
import order from './modules/order'
import i18n from '@/i18n'
import UTILS from '@/api/utils'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  modules: {
    search,
    hotel,
    addon,
    order
  },
  plugins: [
    createPersistedState({
      key: 'owlHotel',
      reducer(state) {
        return {
          selectedLanguage: state.selectedLanguage,
          selectedCurrency: state.selectedCurrency
        }
      }
    })
  ],
  state: {
    hotelId: null,
    hotelAlias: '',
    hotelCustomDomain: '',
    step: 1,
    selectedCurrency: '',
    selectedLanguage: '',
    windowWidth: 0,
    currencyExist: {},
    currencySelectable: {},
    isSuper: false,
    hasAgreeCookie: false,
    customerCountry: '',
    isCoverLoading: false,
    isOwlTingDomain: true,
    isFetchedAddon: false
  },
  getters: {
    hotelId: (state) => {
      return state.hotelId
    },
    hotelCurrency: (state, getters) => {
      return getters['hotel/hotel'].currency
    },
    selectedLanguage: (state, getters, rootState) => {
      return state.selectedLanguage
    },
    selectedCurrency: (state, getters, rootState) => {
      if (_.isEmpty(state.currencySelectable)) {
        return getters['hotel/hotel'].currency
      }
      return state.selectedCurrency
    },
    step: (state, getters, rootState) => {
      return state.step
    },
    windowWidth: (state) => {
      return state.windowWidth
    },
    isTouchDevice: () => {
      return (
        !!(typeof window !== 'undefined' &&
          ('ontouchstart' in window ||
            (window.DocumentTouch &&
              typeof document !== 'undefined' &&
              document instanceof window.DocumentTouch))) ||
        !!(typeof navigator !== 'undefined' &&
          (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
      )
    },
    currencyExist: (state) => {
      return state.currencyExist
    },
    currencySelectable: (state, getters) => {
      const includeHotelCurrency = _.find(state.currencySelectable, currency => currency.code === getters['hotel/hotel'].currency)
      if (!includeHotelCurrency) {
        const hotelCurrency = _.find(state.currencyExist, currency => currency.code === getters['hotel/hotel'].currency)
        if (hotelCurrency) {
          return [...state.currencySelectable, hotelCurrency]
        }
      }
      return state.currencySelectable
    },
    isSuper: (state) => state.isSuper,
    hasAgreeCookie: (state) => state.hasAgreeCookie,
    isCoverLoading: (state) => state.isCoverLoading,
    isOwlTingDomain: (state) => state.isOwlTingDomain,
    isFetchedAddon: (state) => state.isFetchedAddon
  },
  actions: {
    locateCustomerIP({ commit }) {
      return UTILS.locateCustomerIP()
        .then(res => {
          if (res?.data?.status === 0) {
            commit('SET_CUSTOMER_COUNTRY', res?.data?.data?.country?.toLowerCase())
          }
        })
    }
  },
  mutations: {
    SET_HOTEL_ID(state, payload) {
      state.hotelId = payload
    },
    SET_HOTEL_ALIAS(state, payload) {
      state.hotelAlias = payload
    },
    SET_HOTEL_CUSTOM_DOMAIN(state, payload) {
      state.hotelCustomDomain = payload
    },
    SET_STEP(state, payload) {
      state.step = payload
    },
    SET_LANGUAGE(state, payload) {
      i18n.locale = state.selectedLanguage = payload
    },
    SET_CURRENCY(state, payload) {
      state.selectedCurrency = payload
    },
    SET_CURRENCY_CONVERSION(state, payload) {
      state.currencyExist = payload
    },
    SET_CURRENCY_SELECTABLE(state, payload) {
      state.currencySelectable = payload
    },
    WINDOW_RESIZE(state, payload) {
      state.windowWidth = payload
    },
    SET_SUPER(state, payload) {
      state.isSuper = payload
    },
    SET_COOKIE(state) {
      state.hasAgreeCookie = true
    },
    SET_CUSTOMER_COUNTRY(state, payload) {
      state.customerCountry = payload
    },
    SET_COVER_LOADING(state, payload) {
      state.isCoverLoading = payload
    },
    SET_IS_OWLTING_DOMAIN(state, payload) {
      state.isOwlTingDomain = payload
    },
    SET_IS_FETCHED_ADDON(state, payload) {
      state.isFetchedAddon = payload
    }
  }
})
