import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../../subModules/owlnest_i18n/src/pms/be/en.json'
import zhTW from '../../subModules/owlnest_i18n/src/pms/be/zh_tw.json'
import ja from '../../subModules/owlnest_i18n/src/pms/be/ja.json'

Vue.use(VueI18n)

export const messages = {
  en,
  zh_TW: zhTW,
  ja
}

const i18n = new VueI18n({
  messages,
  fallbackLocale: 'zh_TW',
  silentFallbackWarn: true
})

export default i18n
