<template>
  <div class='building-block border-b-1 border-grey-lightest'>
    <h4 class='mb-16 text-grey-darker'>
      <span class="os-tag os-tag-outline os-tag-orange rounded-full text-sm">{{ $t('allBuilding') }}</span>
      {{ building.name }}
    </h4>
    <div class="building-card rounded-lg overflow-hidden md:shadow-lg md:border-1 md:border-grey-lightest">
      <div class="building-card__brief border-b-1 border-grey-lighter flex flex-col md:flex-row flex-wrap">
        <div class="building-card__brief__gallery w-full md:w-1/2 lg:w-full xl:w-1/2 relative">
          <template v-if="isMobile && isImage">
            <imageSwiper :photos="building.images" @showPhoto="openPhotoSwipe" />
          </template>
          <template v-else>
            <div
              class="w-full h-full bg-cover bg-center bg-grey-lightest building-card__brief__gallery__cover"
              :style="roomCover"
              @click.stop="openPhotoSwipe()">
              <div class="building-card__brief__gallery__cover__mask" v-if="isImage">
                <i class="owl-search"></i>
              </div>
            </div>
          </template>
          <!-- <swiper :images="building.images" :options="{width: 1920, height: 1080}" class="w-full" @clickImage="openPhotoSwipe"/> -->
          <photoSwipe v-if="isImage" ref="photoSwipe" :photos="building.images" @close="photoSwipeCloseHandler"></photoSwipe>
        </div>
        <div class="building-card__brief__content w-full md:w-1/2 lg:w-full xl:w-1/2 md:px-24 py-16">
          <!-- <div class="building-card__brief__content__row" v-if="building.building_total_rooms > 0">
            <div class="content-group">
              <label>{{ $t('buildingTotalRooms') }}</label>
              <span>{{ building.building_total_rooms }} {{ $tc('room', building.building_total_rooms) }}</span>
            </div>
          </div> -->
          <div class="building-card__brief__content__row flex">
            <div class="content-group mr-24" v-if="building.max_people">
              <label>{{ $t('peopleLimit') }}</label>
            <span>{{ building.max_people }} {{ $tc('person', building.max_people) }}</span>
            </div>
            <div class="content-group" v-if="building.area">
              <label>{{ $t('area') }}</label>
              <span>{{ building.area }} {{ building.area_unit }}</span>
            </div>
          </div>

          <div class="building-card__brief__content__row">
            <div class="content-group" v-if="isFeatures">
              <label>{{ $tc('features', top4features.length) }}</label>
              <ul class='feature-list flex flex-wrap mb-8'>
                <li
                  v-for="(feature, index) in top4features"
                  :key="index"
                  class="mt-4 mr-8">
                  <img
                    :src="feature.icon_url"
                    class="room-card__feature-icon">
                  <span class="align-text-top">
                    {{ feature.text }}
                  </span>
                </li>
              </ul>
              <a
                v-if='isFeatureMoreThan4'
                class='more-features text-sm cursor-pointer'
                @click='showMoreFeatures'>
                <i class="owl-status-add mr-4"></i>
                <span>{{ $t('more') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="building-card__content">
        <div class='building-card__content md:px-16 lg:px-24'>
          <RoomPlan
            v-for="plan in building.plans"
            :key="plan.id"
            :plan="plan"
            :room="building"
            :stock="building.stock_count"
            :isBuilding="true"
            v-on="$listeners"
          >
            <template #buildingButton>
              <button
                class="btn btn-ghost btn-blue w-full"
                :class="{ 'no-event': disabledInputByCustomerNumber}"
                @click="addBuilding(plan.id)"
                :disabled="isInOrder || hasRestrictedReasons(plan) || disabledInputByCustomerNumber"
              >
                {{ $t('orderBuilding') }}
              </button>
            </template>
          </RoomPlan>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import roomDefaultCover from '@/assets/img/hotel-img-room.jpg'
import photoSwipe from '@/components/photoSwipe'
import contentWithCover from '@/components/modals/contentWithCover'
import imageSwiper from '@/components/imageSwiper'
import RoomPlan from '@/views/Hotel/components/roomPlan'

export default {
  name: 'building-card',
  components: {
    photoSwipe,
    imageSwiper,
    RoomPlan
  },
  props: {
    building: {
      type: Object
    }
  },
  data () {
    return {
      roomDefaultCover: roomDefaultCover
    }
  },
  computed: {
    ...mapGetters([
      'hotelId',
      'selectedCurrency',
      'order/plans',
      'hotel/hotelDisplaySetting',
      'windowWidth',
      'hotel/hotel'
    ]),
    isInOrder: function () {
      return _.find(this['order/plans'], plan => plan.isBuilding)
    },
    plans: function () {
      return this.building.plans
    },
    isImage: function () {
      return this.building.images && this.building.images.length > 0
    },
    roomCover: function () {
      return {
        backgroundImage: this.isImage ? `url('${this.building.images[0]}')` : `url('${this.roomDefaultCover}')`
      }
    },
    isFeatures: function () {
      return !_.isEmpty(this.building.features)
    },
    top4features: function () {
      return this.building.features.slice(0, 4)
    },
    isFeatureMoreThan4: function () {
      return this.building.features.length > 4
    },
    disabledInputByCustomerNumber () {
      return this['hotel/hotelDisplaySetting'].isForceSelectCustomerNumber
    },
    isMobile () {
      return this.windowWidth < 768
    },
    isUseOwltingCancelPolicy () {
      return this['hotel/hotel']?.enable_owlting_cancel_policy
    }
  },
  methods: {
    ...mapActions([
      'order/addBuilding'
    ]),
    addBuilding: function (planId) {
      const data = {
        planId,
        building: this.building
      }
      this['order/addBuilding'](data)
    },
    openPhotoSwipe: function (info) {
      if (this.isImage) {
        this.$refs.photoSwipe.open(info)
        this.hotelCoverShowHandler(true)
      }
    },
    openCancelPolicy: function (plan) {
      /* eslint-disable */
      this.$modal.show(contentWithCover, {
        isFeatures: false,
        context: this.getCancelPolicyContent(plan.cancel_policy.content)
      },
      {
        adaptive: true,
        name: 'features-modal',
        height: 'auto',
        width: '100%',
        maxWidth: 450,
        scrollable: true
      })
      /* eslint-enable */
    },
    showMoreFeatures: function () {
      this.$modal.show(contentWithCover, {
        isFeatures: true,
        features: this.building.features
      },
      {
        adaptive: true,
        name: 'features-modal',
        height: 'auto',
        width: '100%',
        maxWidth: 450,
        scrollable: true
      })
    },
    hasRestrictedReasons: function (plan) {
      return plan.restricted_reasons && plan.restricted_reasons.length > 0
    },
    handleShowCustomerSelector () {
      this.$emit('showCustomerSelector')
    },
    getCancelPolicyContent (cancelPolicy) {
      return this.isUseOwltingCancelPolicy
        ? this.$t('owlTingCancelPolicy')
        : this.$options.filters.contentParser(cancelPolicy)
    },
    photoSwipeCloseHandler () {
      this.hotelCoverShowHandler(false)
    },
    hotelCoverShowHandler (value) {
      const hotelMain = document.querySelector('.hotel-main.main')
      const hotelRooms = document.querySelector('.hotel-main.main .hotel-rooms')
      if (hotelMain && hotelRooms) {
        if (value) {
          hotelMain.classList.remove('show')
          hotelRooms.classList.add('show')
        } else {
          hotelRooms.classList.remove('show')
        }
      }
    }
  }
}
</script>

<style lang='sass' scoped>
@import "@/stylesheets/_function.sass"

.building-card
  &__brief
    @apply relative
    &__gallery
      // width: 420px
      @apply flex-no-shrink
      // @screen md
      //   width: 50%
      // @screen lg
      //   @apply w-full
      // @screen xl
      //   width: 50%
      &__cover
        padding-top: imageRatio(400, 270)
        &:hover .room-card__brief__gallery__cover__mask
          @apply opacity-100
          i
            @apply opacity-100
        &__mask
          @apply absolute w-full h-full flex items-center justify-center opacity-0 cursor-pointer
          top: 0
          left: 0
          transition: opacity 0.3s ease-out
          background: radial-gradient(ellipse at center, rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.55) 100%)
          i
            @apply text-white text-6xl opacity-0
            transition: opacity 0.3s ease-out
    &__content__row
      @apply text-sm
      .content-group
        @apply mb-24
        label
          @apply text-grey-dark block mb-4
        span
          @apply text-grey-darker

      .feature-list
        padding-left: 0rem
        list-style-type: none
        @apply w-full
        li
          @apply mb-4 text-grey-darker
          .room-card__feature-icon
            height: 18px
            width: 18px

      .more-features
        span, i
          @apply text-blue

  &__content
    &__plan
      &:not(:last-of-type)
        @apply border-b-1 border-grey-lighter
    &__amount__row
      cursor: not-allowed
  .no-event
    pointer-events: none
</style>
