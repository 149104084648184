<template>
  <div class='modal__content-width-cover rounded-lg'>
    <div
      v-if="isCoverImage"
      class="modal__content-width-cover__cover"
      :style='{backgroundImage: `url(${img})`}'></div>
    <div class="modal__content-width-cover__content p-24">
      <!-- for features list -->
      <div
        v-if='isFeatures'
        class="modal__content-width-cover__content__features">
        <h5 class='text-grey-darkest mb-16'>{{ $tc('features', features.length) }}</h5>
        <ul class='mb-40'>
          <li
            v-for='(feature, index) in features'
            :key='index'
            class='mb-16 text-sm text-grey-darkest w-1/2'>
            <img
              v-if="feature.icon_url"
              :src="feature.icon_url"
              class="modal__feature-icon">
            <i
              v-else-if="feature.name"
              class="be-icon"
              :class="feature.name" />
            <span class="align-text-top">
             {{ feature.text }}
            </span>
          </li>
        </ul>
      </div>

      <!-- default template for cancel policy -->
      <div
        v-else
        class="modal__content-width-cover__content__cancel-policy">
        <h5
          class='modal__content-width-cover__content__title text-grey-darkest mb-16'
          :style="titleStyle"
        >
          <i
            v-if="icon" class="modal__content-width-cover__content__title__iconStyle"
            :class="iconStyle"
          />
          {{ title }}
        </h5>
        <p class='text-sm text-grey-darker leading-normal' :class="isHtml ? 'whitespace-no-wrap' : 'whitespace-pre-wrap'" v-html="context"></p>
      </div>

    </div>
    <div class="modal__content-width-cover__footer flex w-full">
      <template v-if="isCustomButtons">
        <div v-for="(btn, index) in buttons" :key="index" @click="methodHandler(btn)" class="flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer">
          {{ btn.title }}
        </div>
      </template>
      <div
        v-else
        class="flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer"
        @click="$emit('close')">
        {{ $t('close') }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import i18n from '@/i18n'
export default {
  name: 'modal-content-width-cover',
  props: {
    title: {
      type: String,
      default: () => i18n.t('cancelPolicy')
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    isFeatures: {
      type: Boolean,
      default: false
    },
    features: {
      type: Array,
      default: () => []
    },
    context: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    isScroll: {
      type: Boolean,
      default: true
    },
    buttons: {
      type: Array,
      default: () => []
    },
    titleStyle: {
      type: String
    }
  },
  computed: {
    isCoverImage: function () {
      return !_.isEmpty(this.img)
    },
    isHtml: function () {
      return /<[a-z][\s\S]*>/i.test(this.context)
    },
    iconStyle: function () {
      const styles = [
        !_.isEmpty(this.icon) ? this.icon : '',
        !_.isEmpty(this.type) ? this.type : ''
      ]
      return styles
    },
    isCustomButtons: function () {
      return !_.isEmpty(this.buttons)
    }
  },
  methods: {
    methodHandler: function (btn) {
      if (btn.handler) {
        return btn.handler()
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang='sass' scoped>
.modal__content-width-cover
  // width: 100%
  // max-width: 450px
  &__cover
    height: 170px
    @apply bg-grey-lightest bg-center bg-no-repeat bg-cover
  &__content
    max-height: 500px
    -webkit-overflow-scrolling: touch
    overflow-y: auto
    ul
      @apply pl-0
      list-style-type: none
      .modal__feature-icon
        height: 18px
        width: 18px
    &__title
      @apply flex flex-wrap items-center
      &__iconStyle
        @apply mr-4 text-2xl inline-block
        &.success
          @apply text-green
        &.warning
          @apply text-orange
        &.error
          @apply text-red
</style>
