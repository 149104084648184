<template>
  <div class="component-popper-menu" v-click-outside="clickOutsideHandler">
    <div class="label" @click="clickHandler" :class="{ 'active': visibleValue }">
      <slot name="label"></slot>
    </div>
    <div class="dropdown" v-if="visibleValue">
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'

export default {
  name: 'popperMenu',
  props: {
    visible: Boolean,
    clickOutsideClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visibleValue: false
    }
  },
  watch: {
    visible: {
      handler (value) {
        if (this.visibleValue !== value) {
          this.visibleValue = value
        }
      },
      immediate: true,
      deep: true
    },
    visibleValue: {
      handler (value) {
        if (this.visible !== value) {
          this.$emit('update:visible', value)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
  },
  methods: {
    clickHandler () {
      this.openHandler()
    },
    openHandler () {
      this.visibleValue = true
      this.$emit('visible-change', this.visibleValue)
    },
    closeHandler () {
      this.visibleValue = false
      this.$emit('visible-change', this.visibleValue)
    },
    clickOutsideHandler () {
      if (this.clickOutsideClose) {
        this.closeHandler()
      }
    }
  },
  directive: {
    clickOutside
  }
}
</script>

<style lang="scss" scoped>
.component-popper-menu {
  position: relative;

  .dropdown {
    position: absolute;
    width: 100%;
    width: max-content;
    max-width: 700px;
    min-width: 280px;
    left: 0;
    background-color: #fff;
    border: 1px solid #e6eaed;;
    border-radius: 0.375rem;
    box-shadow: 0 5px 15px -5px rgba(92, 101, 107, .15);
    z-index: 10;
    margin-top: 0.75rem;
    padding: 1px;

    &:before {
      content: "";
      position: absolute;
      top: -6px;
      left: 16px;
      width: 12px;
      height: 12px;
      border: 0 solid #d1d7dd;
      border-color: #e6eaed;
      border-top-width: 1px;
      border-right-width: 1px;
      background-color: #fff;
      box-shadow: 0 5px 15px -5px rgba(92, 101, 107, .15);
      z-index: 10;
      transform: rotate(-45deg);
    }
  }

  @media (max-width: 576px) {
    $width: 100vw;

    .label.active {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      box-shadow: none;
      z-index: 100;
    }

    .dropdown {
      &:before {
        display: none;
      }

    }
  }
}
</style>
